import { Typography } from '@mui/material';
import styled from 'styled-components';

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
`;

const InfoLabel = styled(Typography)`
  font-style: normal;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  width: 100px;
  color: ${(props) => props.color};
`;

const InfoContent = styled.div`
  font-size: 13px;
  line-height: 15px;
  word-wrap: break-word;
  overflow: hidden;
  width: Calc(100% - 115px);
  color: ${(props) => props.color};
`;

export {
  InfoWrapper,
  InfoLabel,
  InfoContent,
};
