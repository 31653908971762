import React, { useContext, useEffect, useState } from 'react';
import format from 'string-template';

import { Card } from '../../../../../../../components/v2/Card';
import InputLabel from '../../../../../../../components/v2/InputLabel';
import ErrorLabel from '../../../../../../../components/v2/ErrorLabel';
import TextField from '../../../../../../../components/v2/TextField';
import LoadingOverlay from '../../../../../../components/LoadingOverlay';
import ExternalCoachContext from '../../../../../../context/ExternalCoachContext';
import useToast from '../../../../../../hooks/useToast';
import useComponentMounted from '../../../../../../../hooks/useComponentMounted';
import CoachHistory, { HistoryEventType } from '../../../../../../Model/CoachHistory';
import LoggedInUserContext from '../../../../../../../context/LoggedInUserContext';
import texts from './texts.json';
import {
  StyledButton,
  StyledSaveIcon,
  StyledSelect,
  HintLabel,
} from './styles';

// These are the only options available for the base fee.
const BaseFeeOptions = [{
  value: 25,
  label: 25,
}, {
  value: 30,
  label: 30,
}];

const InternalConfigs = () => {
  const { externalCoachDoc } = useContext(ExternalCoachContext);
  const [postPaymentFormLink, setPostPaymentFormLink] = useState(externalCoachDoc.postPaymentFormLink || '');
  const [paymentFormLinkError, setPaymentFormLinkError] = useState('');
  const [paymentFormLinkHint, setPaymentFormLinkHint] = useState('');
  const [baseFee, setBaseFee] = useState(externalCoachDoc.baseFee || '');
  const [isSaving, setIsSaving] = useState(false);
  const isComponentMountedRef = useComponentMounted();

  const {
    userDoc: {
      name: authUserName,
    },
    userId: authUserId,
  } = useContext(LoggedInUserContext);

  const { showToast } = useToast();

  // reset link check-up on value change
  useEffect(() => {
    setPaymentFormLinkError('');
    setPaymentFormLinkHint('');
  }, [postPaymentFormLink]);

  const onSave = async () => {
    if (postPaymentFormLink) {
      // Check if postpayment link is not an edit link, for example: https://paperform.co/edit/[id]
      const reEditLink = /(https:\/\/)?(paperform.co)?(\/edit\/)([a-z0-9]*)/;
      const matches = postPaymentFormLink.match(reEditLink);
      setPaymentFormLinkError(null);

      // If this is a link to an edit page, return error and hint.
      if (matches && matches.length > 1) {
        setPaymentFormLinkError(texts.editLinkError);
        // Expected to have a paperform.co link to a form, for example: https://[id].paperform.co/
        setPaymentFormLinkHint(`${matches[1]}${matches[4]}.${matches[2]}/`);
        return;
      }
    }
    setIsSaving(true);

    // Add coachHistory document if it is the first time the PPF link is being set
    if (!externalCoachDoc.postPaymentFormLink) {
      await CoachHistory.addCoachHistoryItem({
        type: HistoryEventType.POST_PAYMENT_FORM_LINKED,
        coachId: externalCoachDoc.id,
        actionedBy: authUserId,
        actionedByUserName: authUserName,
      });
    }

    await externalCoachDoc.set({
      ...(postPaymentFormLink && { postPaymentFormLink }),
      ...(baseFee && { baseFee: Number(baseFee) }),
    }, { merge: true });
    showToast(texts.success, { success: true });
    if (isComponentMountedRef.current) {
      setIsSaving(false);
    }
  };

  return (
    <Card>
      <InputLabel>{texts.fieldNames.ppLink}</InputLabel>
      {!!paymentFormLinkError && <ErrorLabel>{paymentFormLinkError}</ErrorLabel>}
      {!!paymentFormLinkHint && (
        <HintLabel onClick={() => setPostPaymentFormLink(paymentFormLinkHint)}>
          {format(texts.editLinkHint, paymentFormLinkHint)}
        </HintLabel>
      )}
      <TextField
        onChange={(e) => setPostPaymentFormLink(e.target.value)}
        value={postPaymentFormLink}
        type="url"
        placeholder="https://"
      />
      <InputLabel>{texts.fieldNames.baseFee}</InputLabel>
      <StyledSelect
        onChange={(e) => setBaseFee(e.target.value)}
        value={baseFee}
        renderValue={(value) => value && `${value} %`}
        options={BaseFeeOptions}
      />
      <StyledButton onClick={onSave}>
        <StyledSaveIcon />
        {texts.save}
      </StyledButton>
      <LoadingOverlay isLoading={isSaving} />
    </Card>
  );
};

export default InternalConfigs;
