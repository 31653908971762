import { Avatar } from '@mui/material';
import styled, { css } from 'styled-components';
import {
  MailOutline as EmailIcon,
  PhoneInTalk as PhoneIcon,
  PersonPinCircle as LocationIcon,
  AccessTime as TimeZoneIcon,
} from '@mui/icons-material';

import OnCallActions from '../../../../../components/OnCallActions';
import LoadingPage from '../../../../../components/LoadingPage';
import { ReactComponent as MealPlanIcon } from '../../../../../assets/icons/v2/modal-meal-plate.svg';
import { ReactComponent as MobileIcon } from '../../../../../assets/icons/v2/mobile-blue.svg';
import { HeaderRow, TitleContainer, SubTitle } from '../../../../../components/v2/Header';
import Button from '../../../../../components/Button';
import colors from '../../../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
`;

const StyledLoadingPage = styled(LoadingPage)`
  height: 30vh;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 24px 15px 20px;
  flex: 1;
`;

const ImageContainer = styled.div`
  display: flex;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Name = styled.div`
  margin: 7px 25px;
  font-weight: 500;
  font-size: 22px;
  align-self: center;
  text-align: center;
  color: ${colors.shades.secondary8};
`;

const StyledHeaderRow = styled(HeaderRow)`
  margin: 12px 20px;
  margin-top: 16px;
  align-items: center;
`;

const StyledTitleContainer = styled(TitleContainer)`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
`;

const StyledSubTitle = styled(SubTitle)`
  margin-right: 8px;
  font-size: 14px;
  font-weight: 400;
`;

const StyledAvatar = styled(Avatar)`
  display: flex;
  width: 70px;
  height: 70px;
  min-height: 40px;
  min-width: 40px;
  align-self: center;
`;

const StyledEmailIcon = styled(EmailIcon)`
  height: 16px;
  width: 16px;
`;

const smallIconStyles = css`
  height: 16px;
  width: 16px;
`;

const mediumIconStyles = css`
  height: 25px;
  width: 25px;
`;

const StyledPhoneIcon = styled(PhoneIcon)`
  ${smallIconStyles};
`;

const StyledTimeZoneIcon = styled(TimeZoneIcon)`
  ${smallIconStyles};
`;

const StyledLocationIcon = styled(LocationIcon)`
  ${smallIconStyles};
`;

const StyledMealPlanIcon = styled(MealPlanIcon)`
  ${mediumIconStyles};
`;

const StyledMobileIcon = styled(MobileIcon)`
  ${mediumIconStyles};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: 8px 10px 15px 0;
  justify-content: space-between;
`;

const ActivateUserModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 50px 0;
`;

const StyledOnCallActions = styled(OnCallActions)`
  padding: 0 30px 10px 0;
  margin-top: 15px;
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: column;
  padding: 5px 33px;
  background-color: transparent;
  color: ${colors.base.beta};
  text-transform: capitalize;
  width: 50px;
  margin-left: 2.5px;
  font-size: 11px;
  font-weight: 500;
  border-radius: 8px; 
  cursor: pointer;
  white-space: nowrap;
  gap: 0.5px;

  &:hover {
    background-color: ${colors.shades.gamma1};
  }
`;

export {
  Container,
  InfoContainer,
  InfoWrapper,
  Name,
  StyledAvatar,
  ImageContainer,
  StyledEmailIcon,
  StyledPhoneIcon,
  StyledLocationIcon,
  ButtonContainer,
  StyledTimeZoneIcon,
  ActivateUserModalContent,
  StyledButton,
  StyledMealPlanIcon,
  StyledMobileIcon,
  StyledLoadingPage,
  StyledHeaderRow,
  StyledTitleContainer,
  StyledSubTitle,
  StyledOnCallActions,
};
