import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import Accordion from '../../../../../components/Accordion';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import Lead from '../../../../Model/Lead';
import User from '../../../../../Model/User';
import PostPaymentFormAnswer from '../../../../Model/PostPaymentFormAnswer';
import LoadingPage from '../../../../../components/LoadingPage';
import EmptyView from '../EmptyView';

import {
  Container,
} from './styles';
import texts from './texts.json';
import FormResponseContainer from './FormResponseContainer';

const AccordionId = {
  LEAD_FORM_ACCORDION: 'leadFormAccordion',
  POST_PAYMENT_ACCORDION: 'postPaymentAccordion',
};

const ClientInfo = ({ userDoc }) => {
  const [leadDoc, setLeadDoc] = useState(null);
  const [postPaymentFormDoc, setPostPaymentFormDoc] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const isComponentMountedRef = useComponentMounted();

  // Allow only one accordion to be open at a time
  const handleExpandForm = (accordion) => (event, isExpanded) => {
    setExpanded(isExpanded ? accordion : false);
  };

  useEffect(() => {
    const init = async () => {
      const {
        id: userId,
        email,
      } = userDoc;
      const userLeadDoc = await Lead.getLeadByEmail(email);
      const userPostPaymentFormDoc = await PostPaymentFormAnswer.getFormDataByUser(userId);

      if (isComponentMountedRef.current) {
        if (userLeadDoc) {
          setLeadDoc(userLeadDoc);
        }
        if (userPostPaymentFormDoc) {
          setPostPaymentFormDoc(userPostPaymentFormDoc);
        }
        setIsReady(true);
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    isComponentMountedRef,
    isReady,
    userDoc,
  ]);

  if (!isReady) {
    return <LoadingPage />;
  }

  if (!leadDoc && !postPaymentFormDoc) {
    return <EmptyView text={texts.emptyText} />;
  }

  return (
    <Container>
      {!!leadDoc && (
        <Accordion
          key={AccordionId.LEAD_FORM_ACCORDION}
          title={texts.LeadFormQuestions}
          expanded={expanded === AccordionId.LEAD_FORM_ACCORDION}
          onChange={handleExpandForm(AccordionId.LEAD_FORM_ACCORDION)}
        >
          <FormResponseContainer formDoc={leadDoc} />
        </Accordion>
      )}
      {!!postPaymentFormDoc && (
        <Accordion
          key={AccordionId.POST_PAYMENT_ACCORDION}
          title={texts.PostPaymentFormQuestions}
          expanded={expanded === AccordionId.POST_PAYMENT_ACCORDION}
          onChange={handleExpandForm(AccordionId.POST_PAYMENT_ACCORDION)}
        >
          <FormResponseContainer formDoc={postPaymentFormDoc} />
        </Accordion>
      )}
    </Container>
  );
};

ClientInfo.propTypes = {
  userDoc: PropTypes.instanceOf(User),
};

ClientInfo.defaultProps = {
  userDoc: null,
};

export default compose(
  observer,
)(ClientInfo);
