import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  gap: 20px;
`;

export const TextBlock = styled.div`
  font-size: 14px;
`;

export const BtnContainer = styled.div`
  width: fit-content;
`;
