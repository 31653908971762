import ActivityList from './ActivityList';
import PendingList from './PendingList';
import {
  HistoryIcon,
  CoachIcon,
  OffboardingIcon,
} from './styles';
import CoachOffboarding from './CoachOffboarding';
import texts from './texts.json';

const tabs = {
  HISTORY: 'HISTORY',
  ONBOARDING: 'ONBOARDING',
  OFFBOARDING: 'OFFBOARDING',
};

const tabsConfig = {
  [tabs.HISTORY]: {
    label: texts.tabLabels[tabs.HISTORY],
    icon: HistoryIcon,
    component: ActivityList,
  },
  [tabs.ONBOARDING]: {
    label: texts.tabLabels[tabs.ONBOARDING],
    icon: CoachIcon,
    component: PendingList,
  },
  [tabs.OFFBOARDING]: {
    label: texts.tabLabels[tabs.OFFBOARDING],
    icon: OffboardingIcon,
    component: CoachOffboarding,
  },
};

export {
  tabs,
  tabsConfig,
};
