import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import InputLabel from '../../../../../../components/v2/InputLabel';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import useToast from '../../../../../hooks/useToast';
import CoachHistory, { HistoryEventType } from '../../../../../Model/CoachHistory';
import LoggedInUserContext from '../../../../../../context/LoggedInUserContext';

import { getPendingEvents } from './utils';
import {
  StyledLoadingPage,
  Container,
  SubContainer,
  StyledCheckbox,
  ContentContainer,
  ContentText,
} from './styles';
import texts from './texts';

const PendingList = ({
  onboardingDoc,
  history,
  isReady,
}) => {
  const {
    userDoc: {
      name: authUserName,
    },
    userId: authUserId,
  } = useContext(LoggedInUserContext);

  const [completionEvent, setCompletionEvent] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();

  const {
    manualEvents,
    automaticEvents,
  } = useMemo(() => getPendingEvents(history), [history]);

  const onboardingEvent = history.find((event) => event.type === HistoryEventType.WHITE_LISTED);

  const handleCheckItem = useCallback((event) => {
    // If the coach is not signed up, do not allow to complete manual events.
    if (!onboardingDoc.coach) {
      showToast(texts.notSignedUp, { error: true });
      return;
    }
    setCompletionEvent(event);
  }, [
    onboardingDoc.coach,
    showToast,
  ]);

  const handleConfirm = useCallback(async () => {
    setIsProcessing(true);
    if (completionEvent) {
      await CoachHistory.addCoachHistoryItem({
        type: completionEvent,
        coachId: onboardingDoc.coach,
        actionedBy: authUserId,
        actionedByUserName: authUserName,
      });
    } else {
      showToast(texts.completionFailed, { error: true });
    }
    if (isComponentMountedRef.current) {
      setCompletionEvent(null);
      setIsProcessing(false);
    }
  }, [
    showToast,
    isComponentMountedRef,
    authUserId,
    authUserName,
    onboardingDoc.coach,
    completionEvent,
  ]);

  if (!isReady) {
    return <StyledLoadingPage />;
  }

  /* If the coach is inelligible for pending items or doesn't have any pending items, show a message.
   * We consider the coach inelligible if the onboarding event is not recorded
   */
  if (!onboardingEvent || (!manualEvents.length && !automaticEvents.length)) {
    return (
      <Container>
        {texts.noPending}
      </Container>
    );
  }

  return (
    <Container>
      {!!manualEvents.length && (
        <SubContainer>
          <InputLabel>{texts.manualChecklist}</InputLabel>
          {manualEvents.map((event) => (
            <StyledCheckbox
              key={event}
              isChecked={false}
              onChange={() => handleCheckItem(event)}
              description={texts.eventText[event]}
            />
          ))}
        </SubContainer>
      )}
      {!!automaticEvents.length && (
        <SubContainer>
          <InputLabel>{texts.automaticChecklist}</InputLabel>
          <ContentContainer>
            {automaticEvents.map((event) => (
              <ContentText key={event}>{`- ${texts.eventText[event]}`}</ContentText>
            ))}
          </ContentContainer>
        </SubContainer>
      )}
      <ConfirmDialog
        isOpen={!!completionEvent}
        onConfirm={handleConfirm}
        onCancel={() => setCompletionEvent(null)}
        dialogTexts={{
          title: texts.modalTitle,
          content: texts.eventText[completionEvent],
        }}
      />
      <LoadingOverlay isLoading={isProcessing} />
    </Container>
  );
};

PendingList.propTypes = {
  onboardingDoc: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
  isReady: PropTypes.bool.isRequired,
};

export default PendingList;
