import format from 'string-template';
import moment from 'moment';
import { SECONDS_PER_MINUTE, TimeDurationFormat } from '../utils/time';

/**
 * Enum for the different types of activities.
 */
const ActivityTypes = {
  REST: 'REST',
  TIMED: 'TIMED',
  REPETITIONS: 'REPETITIONS',
  CIRCUIT: 'CIRCUIT',
};

/**
 * Enum for the different types of activity goals.
 */
const ActivityGoalType = {
  REPETITIONS: 'repetitions',
  DURATION: 'duration',
};

/**
 * Text format for the goal when the activity is of type repetitions.
 */
const GOAL_FORMAT_REPETITIONS = '{goalValue} Reps';

/**
 * Formats the goal value based on the activity goal type.
 * @param {number} goalValue
 * @param {ActivityGoalType} activityGoalType
 * @returns {string}
 */
const formatGoal = (goalValue, activityGoalType) => {
  if (activityGoalType === ActivityGoalType.DURATION) {
    return moment.duration(goalValue, 'seconds')
      .format(TimeDurationFormat.SHORT_FORMAT, {
        trim: 'both',
        stopTrim: goalValue > SECONDS_PER_MINUTE ? 'm' : 's',
      });
  }
  return format(GOAL_FORMAT_REPETITIONS, {
    goalValue,
  });
};

/**
 * Class representing an activity.
 * @class Activity
 */
class Activity {
  /**
   * Constructs a new activity out of a JSON activity definition.
   * @param {Object} jsonActivity The activity in JSON format.
   */
  constructor(jsonActivity) {
    const {
      exerciseId,
      type,
      name,
      note,
      description,
      video,
      videoUrl,
      videoPreviewUrl,
      videoPreviewThumbnail,
      videoPreviewHighResThumbnail,
      restTime,
      restTimeDisplayUnit,
      side,
      repetitions,
      duration,
      durationDisplayUnit,
      tags,
    } = jsonActivity;

    // Required fields
    this.exerciseId = exerciseId;
    this.type = type;
    /*
      Name is required for all activities except for CIRCUIT activities.
      For CIRCUIT activities we do still want to be able to save correctly to
      firebase and that's why we need to initialize with an empty string,
      otherwise firebase does not consider undefined as a valid value.
    */
    this.name = name || '';
    if (this.type === ActivityTypes.REPETITIONS) {
      this.repetitions = repetitions || '';
    }

    if (this.type === ActivityTypes.TIMED || this.type === ActivityTypes.REST) {
      this.duration = duration || '';
      this.durationDisplayUnit = durationDisplayUnit;
    }

    this.tags = tags || [];

    this.note = note || '';

    this.description = description || '';

    this.restTime = restTime || 0;
    this.restTimeDisplayUnit = restTimeDisplayUnit;

    if (this.type !== ActivityTypes.CIRCUIT) {
      this.video = video || {};
      this.videoUrl = videoUrl || '';
      this.videoPreviewUrl = videoPreviewUrl || videoUrl || '';
      this.videoPreviewThumbnail = videoPreviewThumbnail || '';
      this.videoPreviewHighResThumbnail = videoPreviewHighResThumbnail || '';
      this.side = side || '';
    }

    if (this.type === ActivityTypes.CIRCUIT) {
      const {
        activities = [],
        rounds = 1,
      } = jsonActivity;
      this.activities = activities.map((jsonSubActivity) => new Activity(jsonSubActivity));
      this.rounds = rounds || 1;
    }
  }

  /**
   * Get the goal of the activity.
   * @return {string}
   */
  get goal() {
    if (this.type !== ActivityTypes.CIRCUIT) {
      const goalFieldName = this.type === ActivityTypes.REPETITIONS
        ? ActivityGoalType.REPETITIONS
        : ActivityGoalType.DURATION;

      const goalValue = this[goalFieldName];

      return formatGoal(goalValue, goalFieldName);
    }
    return '';
  }

  /**
   * Get the JSON representation of the activity.
   * @return {Object}
   * @property {string} type - The type of the activity.
   * @property {string} name - The name of the activity.
   * @property {string} note - The note of the activity.
   * @property {string} exerciseId - The ID of the exercise.
   * @property {string} side - The side of the activity.
   * @property {string} description - The description of the activity.
   * @property {Object} video - The video information of the activity.
   * @property {string} videoUrl - The URL of the video.
   * @property {string} videoPreviewUrl - The URL of the video preview.
   * @property {string} videoPreviewThumbnail - The URL of the video preview thumbnail.
   * @property {string} videoPreviewHighResThumbnail - The URL of the high res video preview thumbnail.
   * @property {number} restTime - The rest time.
   * @property {string} restTimeDisplayUnit - The display unit of the rest time.
   * @property {number} repetitions - The number of repetitions.
   * @property {number} duration - The duration of the activity.
   * @property {string} durationDisplayUnit - The display unit of the duration.
   * @property {Array} tags - The tags of the activity.
   * @property {Array} activities - The activities of the circuit.
   * @property {number} rounds - The rounds of the circuit.
   */
  get jsonActivity() {
    const jsonActivity = {
      type: this.type,
      ...(this.name && { name: this.name }),
      ...(this.note && { note: this.note }),
    };

    if (this.type !== ActivityTypes.REST && this.type !== ActivityTypes.CIRCUIT) {
      jsonActivity.exerciseId = this.exerciseId;
      jsonActivity.tags = this.tags;
      jsonActivity.side = this.side;
      jsonActivity.note = this.note;
      jsonActivity.description = this.description;
      jsonActivity.video = this.video;
      jsonActivity.videoUrl = this.videoUrl;
      jsonActivity.videoPreviewUrl = this.videoPreviewUrl;
      jsonActivity.videoPreviewThumbnail = this.videoPreviewThumbnail;
      jsonActivity.videoPreviewHighResThumbnail = this.videoPreviewHighResThumbnail;
      jsonActivity.restTime = this.restTime;
      jsonActivity.restTimeDisplayUnit = this.restTimeDisplayUnit;
    }

    if (this.type === ActivityTypes.REPETITIONS) {
      jsonActivity.repetitions = this.repetitions;
    }

    if (this.type === ActivityTypes.TIMED || this.type === ActivityTypes.REST) {
      jsonActivity.duration = this.duration;
      jsonActivity.durationDisplayUnit = this.durationDisplayUnit;
    }

    if (this.type === ActivityTypes.CIRCUIT) {
      jsonActivity.activities = this.activities.map((subActivity) => subActivity.jsonActivity);
      jsonActivity.rounds = this.rounds;
    }
    // remove all undefined values
    Object.keys(jsonActivity).forEach((key) => jsonActivity[key] === undefined && delete jsonActivity[key]);

    return jsonActivity;
  }
}

export default Activity;
export {
  ActivityTypes,
  ActivityGoalType,
};
