import { Collection } from 'firestorter';

import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';

/**
 * Type of an exercise
 * - **BASE:** Generic exercise added by the system
 * - **EDITED:** Exercise that was edited from a `BASE` exercise
 * - **CUSTOM:** Newly created custom exercise
 */
const ExerciseType = {
  BASE: 'BASE',
  CUSTOM: 'CUSTOM',
  EDITED: 'EDITED',
};

/**
 * Class representing an Exercise.
 *
 * @class Exercise
 * @extends BaseDocument
 */
class Exercise extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.EXERCISE}/${id}`, opts);
  }

  /**
   * Get the name of the exercise.
   * @returns {string}
   */
  get name() {
    return this.data.name;
  }

  /**
   * Get the type of the exercise.
   * @returns {string}
   */
  get type() {
    return this.data.type;
  }

  /**
   * Get the current status of the exercise (represents archival status for coach exercises).
   * @returns {boolean}
   */
  get current() {
    return !!this.data.current;
  }

  /**
   * Get the description of the exercise.
   * @returns {string}
   */
  get description() {
    return this.data.description || '';
  }

  /**
   * Get the tags associated with the exercise.
   * @returns {Object[]}
   */
  get tags() {
    return this.data.tags || [];
  }

  /**
   * Get the id of coach who created the exercise.
   * @returns {string}
   */
  get createdBy() {
    return this.data.createdBy || '';
  }

  /**
   * Get the exercise creation date in UTC format.
   * @returns {Date}
   */
  get createdAt() {
    return this.data.createdAt;
  }

  /**
   * Get the last modified date of the exercise in UTC format.
   * @returns {Date}
   */
  get modifiedAt() {
    return this.data.modifiedAt;
  }

  /**
   * Get the original exercise id. This is for `EDITED` exercise types.
   * @returns {string}
   */
  get originalExercise() {
    return this.data.originalExercise || '';
  }

  /**
   * Get the video information from Vimeo. It contains the height, width, hdVideoUrl and hlsVideoUrl.
   * @returns {Object}
   */
  get video() {
    return this.data.video || {};
  }

  /**
   * Get the preview thumbnail URL of the attached video.
   * @returns {string}
   */
  get videoPreviewThumbnail() {
    return this.data.videoPreviewThumbnail || '';
  }

  /**
   * Get the preview high resolution thumbnail URL of the attached video.
   * @returns {string}
   */
  get videoPreviewHighResThumbnail() {
    return this.data.videoPreviewHighResThumbnail || '';
  }

  /**
   * Get the preview video URL.
   * @returns {string}
   */
  get videoPreviewUrl() {
    return this.data.videoPreviewUrl || '';
  }

  /**
   * Get the URL of the attached video.
   * @returns {string}
   */
  get videoUrl() {
    return this.data.videoUrl || '';
  }

  /**
   * Archive/Unarchive an exercise. This functionality is only for EDITED and CUSTOM exercises
   * @param {boolean} current - New archival status
   * @returns {Promise<void>} Resolves once the attribute is updated
   */
  async updateArchiveStatus(current) {
    if (this.type !== ExerciseType.BASE) {
      await this.updateFields({ current });
    }
  }

  /**
   * Creates a new exercise document in firestore.
   *
   * @param {Object} data - JSON data to be attached to the new exercise doc
   * @returns {Promise<string>} The id of the created firestore document
   */
  static async addDoc(data) {
    const exercisesCollection = new Collection(Collections.EXERCISE);
    const { id } = await exercisesCollection.add(data);
    return id;
  }

  /**
   * Gets the active base set of exercises that was added by the system.
   *
   * @returns {Promise<Collection>} The collection of exercises from firestore
   */
  static async getBaseExercises() {
    const exercisesCollection = new Collection(Collections.EXERCISE, {
      createDocument: ({ id }, opts) => new Exercise(id, { ...opts, disableObserverRefCount: true }),
      query: (ref) => ref
        .where('current', '==', true)
        .where('type', '==', ExerciseType.BASE),
    });
    await exercisesCollection.fetch();
    return exercisesCollection;
  }

  /**
   * Gets the active exercises created by a particular coach.
   *
   * @param {string} coachId - Id of the coach
   * @param {boolean} fetchArchivedPlans - Whether or not to fetch archived exercises
   * @returns {Promise<Collection>} The collection of exercises from firestore
   */
  static async getExercisesforCoach(coachId, fetchArchivedPlans = false) {
    const exercisesCollection = new Collection(Collections.EXERCISE, {
      createDocument: ({ id }, opts) => new Exercise(id, { ...opts, disableObserverRefCount: true }),
      query: (ref) => {
        let query = ref
          .where('createdBy', '==', coachId);
        if (!fetchArchivedPlans) {
          query = query.where('current', '==', true);
        }
        return query;
      },
    });
    await exercisesCollection.fetch();
    return exercisesCollection;
  }

  /**
   * Gets the exercise document for a given exercise id.
   *
   * @param {string} exerciseId - Id of the exercise
   * @returns {Promise<Exercise>} The exercise document
   */
  static async getExerciseById(exerciseId) {
    const exerciseDoc = new Exercise(exerciseId);
    await exerciseDoc.init();
    return exerciseDoc;
  }
}

export default Exercise;
export {
  ExerciseType,
};
