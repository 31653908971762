import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import format from 'string-template';
import moment from 'moment';
import { Divider } from '@mui/material';
import PropTypes from 'prop-types';

import CollectionName from '../../../../../../../utils/collections';
import { MeasurementUnit } from '../../../../../../../utils/measurement';
import { openFirestoreDocument } from '../../../../../../../utils/support';
import { openStripeCustomer } from '../../../../../../utils/stripe';
import { DateFormat } from '../../../../../../../utils/date';
import { getCheckInDay } from '../../../../../../utils/checkIn';
import useSessionStore from '../../../../../../../hooks/useSessionStore';
import useUserDoc from '../../../../../../hooks/useUserDoc';
import useToast from '../../../../../../hooks/useToast';
import StripeCustomerDocument from '../../../../../../../Model/StripeCustomerDocument';
import UserNutritionProfile from '../../../../../../Model/UserNutritionProfile';
import ExternalCoachContext from '../../../../../../context/ExternalCoachContext';
import InfoItem from '../../../InfoItem';
import {
  InfoContainer,
  InfoWrapper,
  StyledLoadingPage,
  StyledHeaderRow,
  StyledTitleContainer,
  StyledOnCallActions,
} from '../../styles';
import texts from './texts';

const EMPTY_CELL = '-';
const genderLabel = {
  MALE: 'Male',
  FEMALE: 'Female',
};

const ProfileDetailsSection = ({ userDoc }) => {
  const {
    firstName,
    lastName,
    email,
    address,
    phoneNumber,
    birthdate,
    checkInDay,
  } = userDoc;

  const {
    params: {
      clientId,
    },
  } = useRouteMatch();
  const {
    externalCoachDoc: {
      stripeAccountId,
    },
  } = useContext(ExternalCoachContext);
  const { isOnCallUser } = useSessionStore();
  const { showToast } = useToast();
  const {
    isReady: isClientDocReady,
    userDoc: clientDoc,
  } = useUserDoc(clientId);

  const [userNutritionProfileDoc, setUserNutritionProfileDoc] = useState(null);

  useEffect(() => {
    const init = async () => {
      const nutritionProfile = new UserNutritionProfile(userDoc.id);
      await nutritionProfile.init();
      setUserNutritionProfileDoc(nutritionProfile);
    };

    init();
  }, [
    userDoc.id,
  ]);

  const heightText = useMemo(() => {
    if (!userNutritionProfileDoc) {
      return EMPTY_CELL;
    }

    const { height, heightMeasurementUnit } = userNutritionProfileDoc;

    if (!height) {
      return EMPTY_CELL;
    }

    return heightMeasurementUnit === MeasurementUnit.METRIC
      ? format(texts.heightValues[heightMeasurementUnit], { height })
      : format(texts.heightValues[heightMeasurementUnit], { ft: height.ft, in: height.in });
  }, [
    userNutritionProfileDoc,
  ]);

  const currentWeightText = useMemo(() => {
    if (!userNutritionProfileDoc) {
      return EMPTY_CELL;
    }

    const { currentWeight, weightMeasurementUnit } = userNutritionProfileDoc;

    if (!currentWeight) {
      return EMPTY_CELL;
    }

    return format(texts.weightValues[weightMeasurementUnit], { weight: currentWeight });
  }, [
    userNutritionProfileDoc,
  ]);

  const handleStripeCustomerClick = useCallback(async () => {
    const userStripeDoc = clientDoc
      ? await StripeCustomerDocument.getStripeCustomerByUserId(clientDoc.id)
      : null;
    if (!!stripeAccountId && !!userStripeDoc) {
      openStripeCustomer(stripeAccountId, userStripeDoc.id);
    } else {
      showToast(texts.noStripeCustomer, { type: 'error' });
    }
  }, [
    stripeAccountId,
    clientDoc,
    showToast,
  ]);

  if (!isClientDocReady) {
    return <StyledLoadingPage />;
  }

  return (
    <>
      <Divider flexItem />
      <StyledHeaderRow>
        <StyledTitleContainer>
          {texts.personalDetails}
        </StyledTitleContainer>
      </StyledHeaderRow>
      <InfoContainer>
        <InfoWrapper>
          <InfoItem label={texts.firstName}>
            {firstName || EMPTY_CELL}
          </InfoItem>
          <InfoItem label={texts.lastName}>
            {lastName || EMPTY_CELL}
          </InfoItem>
          <InfoItem label={texts.email}>
            {email || EMPTY_CELL}
          </InfoItem>
          <InfoItem label={texts.phone}>
            {phoneNumber || EMPTY_CELL}
          </InfoItem>
          <InfoItem label={texts.location}>
            {address || EMPTY_CELL}
          </InfoItem>
          <InfoItem label={texts.gender}>
            {(userNutritionProfileDoc && genderLabel[userNutritionProfileDoc?.biologicalSex]) || EMPTY_CELL}
          </InfoItem>
          <InfoItem label={texts.age}>
            {birthdate ? moment().diff(moment(birthdate), 'years') : EMPTY_CELL}
          </InfoItem>
          <InfoItem label={texts.birthdate}>
            {birthdate ? moment(birthdate).utc().format(DateFormat.DEFAULT_DATE_FORMAT) : EMPTY_CELL}
          </InfoItem>
          <InfoItem label={texts.height}>
            {heightText}
          </InfoItem>
          <InfoItem label={texts.weight}>
            {currentWeightText}
          </InfoItem>
          <InfoItem label={texts.checkInDay}>
            {getCheckInDay(checkInDay)}
          </InfoItem>
        </InfoWrapper>
        {isOnCallUser && (
          <StyledOnCallActions
            actions={[
              {
                onClick: () => openFirestoreDocument(`${CollectionName.USER}/${clientId}`),
                disabled: !clientId,
                label: texts.support.userDoc,
              },
              {
                onClick: handleStripeCustomerClick,
                disabled: !stripeAccountId,
                label: texts.support.stripeCustomer,
              },
            ]}
          />
        )}
      </InfoContainer>
    </>
  );
};

ProfileDetailsSection.propTypes = {
  userDoc: PropTypes.object.isRequired,
};

export default ProfileDetailsSection;
