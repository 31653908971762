import styled from 'styled-components';

import OnCallActions from '../../../../../components/OnCallActions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 10px;
  overflow: scroll;
`;

const StyledOnCallActions = styled(OnCallActions)`
  padding-bottom: 20px;
`;

const StyledContractDetails = styled.div`
  min-width: 600px;
  margin-left: 5px;
`;

export {
  Container,
  StyledOnCallActions,
  StyledContractDetails,
};
