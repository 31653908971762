import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { Button as MuiButton } from '@mui/material';
import format from 'string-template';

import Button from '../../../../../../../components/Button/Button';
import CoachHistory from '../../../../../../Model/CoachHistory';
import LoggedInUserContext from '../../../../../../../context/LoggedInUserContext';
import texts from './texts.json';

import { BtnContainer, TextBlock } from './styles';

const ActionButton = ({
  coachId,
  action,
  done,
  loadCoachHistory,
}) => {
  const [loading, setLoading] = useState(false);

  const {
    userDoc: {
      name: authUserName,
    },
    userId: authUserId,
  } = useContext(LoggedInUserContext);

  const markAsDone = async (item) => {
    try {
      setLoading(true);
      await CoachHistory.addCoachHistoryItem({
        type: item,
        coachId,
        actionedBy: authUserId,
        actionedByUserName: authUserName,
      });
      await loadCoachHistory();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw new Error(error);
    }
  };

  return (
    <>
      {
        done ? (
          <TextBlock>
            {format(texts.checklistButtons[action])}
          </TextBlock>
        ) : (
          <Tooltip title={texts.checklistTooltips[action]} placement="right-start">
            <BtnContainer>
              <Button
                variant="contained"
                size="small"
                disabled={loading || done}
                onClick={() => markAsDone(action)}
                component={MuiButton}
              >
                {loading ? texts.loading : texts.markAsDone}
              </Button>
            </BtnContainer>
          </Tooltip>
        )
      }
    </>
  );
};

ActionButton.propTypes = {
  coachId: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  done: PropTypes.bool.isRequired,
  loadCoachHistory: PropTypes.func.isRequired,
};

export default ActionButton;
