import React, { useCallback, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import PropTypes from 'prop-types';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { Picker, Swatch, Popover } from './styles';

const PopupColorPicker = ({ color, onChange }) => {
  const popover = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => setIsOpen(false), []);
  useOnClickOutside(popover, close);

  return (
    <Picker>
      <Swatch style={{ backgroundColor: color }} onClick={() => setIsOpen(true)} />

      {isOpen && (
        <Popover ref={popover}>
          <HexColorPicker color={color} onChange={onChange} />
        </Popover>
      )}
    </Picker>
  );
};

PopupColorPicker.propTypes = {
  color: PropTypes.string,
  onChange: PropTypes.string,
};

PopupColorPicker.defaultProps = {
  color: '#FFFFFF',
  onChange: () => {},
};

export default PopupColorPicker;
