import styled from 'styled-components';
import { LoadingButton } from '../../../../../../../components/Button';
import colors from '../../../../../../../styles/colors';

const StyledButton = styled(LoadingButton)`
  width: fit-content;
  padding: 5px 12px;
  background: ${colors.primary1};
  color: ${colors.base.secondary2};
  border-radius: 73px;
  font-weight: 600;
  font-size: 12px;
  text-transform: none;

  &:hover {
    background-color: ${colors.primary2};
  }
`;

const FormContainer = styled.div``;

const InputContainer = styled.div`
  width: 350px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Info = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export {
  StyledButton,
  FormContainer,
  InputContainer,
  ButtonContainer,
  InfoContainer,
  Info,
};
