import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import { ReactComponent as DescriptionIcon } from '../../../assets/icons/v2/notes.svg';
import { PrimaryButton } from '../../../components/Button/ActionButtons';
import QAPContext from '../../../context/QAPContext';

import {
  Container,
  StyledButton,
  StyledIconButton,
  StyledDescriptionIcon,
  ButtonTitle,
} from './styles';
import texts from './texts.json';

const ClientQuickInfoButton = ({
  className,
  iconButton,
  textIconButton,
  userId,
}) => {
  const { openQAP } = useContext(QAPContext);

  return (
    <>
      {iconButton && (
        <StyledButton onClick={() => openQAP(userId)}>
          <DescriptionIcon variant="secondary" />
        </StyledButton>
      )}
      {textIconButton && (
        <StyledIconButton
          onClick={() => openQAP(userId)}
        >
          <StyledDescriptionIcon />
          <ButtonTitle>
            {texts.caption}
          </ButtonTitle>
        </StyledIconButton>
      )}
      {!iconButton && !textIconButton && (
        <Container className={className}>
          <PrimaryButton
            onClick={() => openQAP(userId)}
            icon={<DescriptionIcon />}
            variant="info"
            size="small"
          >
            {texts.caption}
          </PrimaryButton>
        </Container>
      )}
    </>
  );
};

ClientQuickInfoButton.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.string.isRequired,
  iconButton: PropTypes.bool,
  textIconButton: PropTypes.bool,
};

ClientQuickInfoButton.defaultProps = {
  className: '',
  iconButton: false,
  textIconButton: false,
};

export default compose(
  observer,
)(ClientQuickInfoButton);
