import React, {
  useContext,
  useMemo,
  useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import useUserDoc from '../../hooks/useUserDoc';
import useToolsNavigation from '../../hooks/useToolsNavigation';
import LoadingPage from '../../../components/LoadingPage';
import BackButton from '../../components/BackButton';
import ManageClientContext from '../../context/ManageClientContext';
import config from '../../../config';
import { StyledTabs } from '../MealsPlanner/styles';
import UserInfoSection from './components/UserInfoSection';
import {
  TabHeaderRow,
  HeaderContainer,
  TabButtonsContainer,
  ClientContainer,
  ClientSideContainer,
  ClientRightContainer,
} from './styles';
import texts from './texts.json';
import { clientInfoTabsConfig } from './utils';
import MobileViewModal from './components/MobileView';

const ClientInfoView = () => {
  const {
    params: {
      clientId,
      userId: coachId,
    },
  } = useRouteMatch();
  const { navigateBack } = useToolsNavigation();
  const { selectedTab, setSelectedTab } = useContext(ManageClientContext);

  // We need the user doc of this specific client
  // TODO: we need to define a new coach context to give coach information
  // without doing that on the user context
  const {
    isReady: isClientDocReady,
    userDoc: clientDoc,
  } = useUserDoc(clientId);

  const [showMobileViewModal, setShowMobileViewModal] = useState(false);

  const mobileUrl = useMemo(() => `${config.mobileAppURL}/u/${clientId}/home?coachId=${coachId}`,
    [clientId, coachId]);

  const tabContent = useMemo(() => {
    const { component: TabContent, props } = clientInfoTabsConfig[selectedTab];
    return (
      <TabContent
        user={clientId}
        userDoc={clientDoc}
        {...props}
      />
    );
  }, [
    selectedTab,
    clientDoc,
    clientId,
  ]);

  if (!isClientDocReady) {
    return <LoadingPage />;
  }

  return (
    <>
      <ClientContainer>
        <ClientSideContainer>
          <UserInfoSection
            userDoc={clientDoc}
            showMobileViewModal={setShowMobileViewModal}
          />
        </ClientSideContainer>
        <ClientRightContainer>
          <HeaderContainer>
            <TabHeaderRow>
              <StyledTabs
                tabsConfig={clientInfoTabsConfig}
                selectedTab={selectedTab}
                onSelectTab={setSelectedTab}
              />
              <TabButtonsContainer>
                <BackButton
                  label={texts.goBack}
                  onClick={navigateBack}
                />
              </TabButtonsContainer>
            </TabHeaderRow>
          </HeaderContainer>
          {tabContent}
        </ClientRightContainer>
      </ClientContainer>

      <MobileViewModal
        showModal={showMobileViewModal}
        onClose={() => setShowMobileViewModal(false)}
        mobileUrl={mobileUrl}
      />
    </>
  );
};

export default compose(
  observer,
)(ClientInfoView);
