const fieldName = {
  NAME: 'name',
  WORKOUT_DAYS: 'workouts',
  DIFFICULTY: 'difficulty',
  EQUIPMENT: 'equipment',
};

const workoutDayAttributes = {
  ID: 'id',
  LABEL: 'label',
  WORKOUT: 'workout',
  EQUIPMENT: 'equipment',
  IS_VALID_WORKOUT: 'isValidWorkout',
};

export {
  fieldName,
  workoutDayAttributes,
};
