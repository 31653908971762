import React, {
  useState,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { autorun } from 'mobx';

import Modal from '../../../../../components/v2/Modal';
import CoachHistory from '../../../../Model/CoachHistory';
import useComponentMounted from '../../../../../hooks/useComponentMounted';

import {
  TabContainer,
  Tabs,
  Tabcontent,
} from './styles';
import { tabs, tabsConfig } from './utils';

const CoachLeadModal = ({
  open,
  onClose,
  onboardingDoc,
}) => {
  const [selectedTab, setSelectedTab] = useState(tabs.HISTORY);
  const [historyCollection, setHistoryCollection] = useState({ docs: [] });
  const [history, setHistory] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const isComponentMountedRef = useComponentMounted();

  const {
    coach: coachId,
    onboardingId,
  } = onboardingDoc;

  useEffect(() => {
    const init = async () => {
      let historyCol;
      if (coachId) {
        historyCol = await CoachHistory.getCoachHistoryById(coachId);
      } else {
        const historyDoc = await CoachHistory.getCoachHistoryByOnboardingDocId(onboardingId);
        // Make the fetched document look like a collection.
        historyCol = historyDoc ? { docs: [historyDoc] } : { docs: [] };
      }
      if (isComponentMountedRef.current) {
        setHistoryCollection(historyCol);
        setIsReady(true);
      }
    };

    init();
  }, [
    isComponentMountedRef,
    coachId,
    onboardingId,
  ]);

  useEffect(() => {
    const disposer = autorun(() => {
      if (isReady) {
        setHistory(historyCollection.docs.slice());
      }
    });

    return () => disposer();
  }, [
    historyCollection.docs,
    isReady,
  ]);

  const tabContent = useMemo(() => {
    const { component: TabContent } = tabsConfig[selectedTab];
    return (
      <TabContent
        onboardingDoc={onboardingDoc}
        history={history}
        isReady={isReady}
      />
    );
  }, [
    onboardingDoc,
    history,
    isReady,
    selectedTab,
  ]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={onboardingDoc.name}
      noPadding
    >
      <TabContainer>
        <Tabs
          tabsConfig={tabsConfig}
          selectedTab={selectedTab}
          onSelectTab={setSelectedTab}
        />
      </TabContainer>
      <Tabcontent>
        {tabContent}
      </Tabcontent>
    </Modal>
  );
};

CoachLeadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onboardingDoc: PropTypes.object,
};

CoachLeadModal.defaultProps = {
  onClose: () => {},
  onboardingDoc: {},
};

export default compose(
  observer,
)(CoachLeadModal);
