import styled from 'styled-components';
import { HexColorInput as BaseHexColorInput } from 'react-colorful';
import colors from '../../styles/colors';

const Picker = styled.div`
  position: relative;
  align-self: flex-end;
  margin-bottom: 20px;
`;

const HexColorInput = styled(BaseHexColorInput)`
  width: 250px;
  height: 50px;
  border: 1px solid ${colors.base.secondary2}44;
  border-radius: 4px;
  padding: 0 16px;
  &:focus {
    border: 2px solid ${colors.base.primary};
    outline: none;
  }
`;

const Swatch = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const Popover = styled.div`
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
`;
export {
  Picker, Swatch, Popover, HexColorInput,
};
