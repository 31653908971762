import moment from 'moment';

import {
  MeasurementUnit,
  getCentimetersFromFtIn,
  getKgFromLbs,
} from '../../utils/measurement';
import {
  DietaryRestriction,
  WeeklyWeightGoal,
  WorkoutDaysToActivityLevel,
} from '../Model/UserNutritionProfile';
import {
  ActivityLevel,
} from '../Model/UserActivityProfile';
import {
  calculateBMR,
  calculateTDEE,
  calculateCaloricGoal,
} from './macroGoals';

const carbsToCalories = (carbs) => carbs * 4;
const proteinToCalories = (protein) => protein * 4;
const fatToCalories = (fat) => fat * 9;

const caloriesToProtein = (calories) => calories / 4;
const caloriesToCarbs = (calories) => calories / 4;
const caloriesToFat = (calories) => calories / 9;

const getSummarizedMacroNutrients = (meals) => (
  meals.reduce((accMacroNutrients, meal) => {
    const { totalNutrients } = meal;
    const newAccMacroNutrients = {
      ...accMacroNutrients,
    };

    Object.keys(totalNutrients).forEach((macroNutrientKey) => {
      newAccMacroNutrients[macroNutrientKey] = newAccMacroNutrients[macroNutrientKey] || 0;
      newAccMacroNutrients[macroNutrientKey] += parseInt(totalNutrients[macroNutrientKey], 10);
    });

    return newAccMacroNutrients;
  }, {})
);

const RecipeTag = {
  BREAKFAST: 'BREAKFAST',
  LUNCH: 'LUNCH',
  SNACK: 'SNACK',
  DINNER: 'DINNER',
};

const AllergenTag = {
  NUTS: 'NUTS',
  TREE_NUTS: 'TREE_NUTS',
  PEANUTS: 'PEANUTS',
  ANIMAL_ORIGIN: 'ANIMAL_ORIGIN',
  DAIRY: 'DAIRY',
  EGGS: 'EGGS',
  SOY: 'SOY',
  FISH: 'FISH',
  SHELLFISH: 'SHELLFISH',
  MEAT: 'MEAT',
  PORK: 'PORK',
  BEEF: 'BEEF',
  RED_MEAT: 'RED_MEAT',
  GLUTEN: 'GLUTEN',
  SUGAR: 'SUGAR',
};

const AllergenTagText = {
  [AllergenTag.NUTS]: 'Nuts',
  [AllergenTag.TREE_NUTS]: 'Tree Nuts',
  [AllergenTag.PEANUTS]: 'Peanuts',
  [AllergenTag.DAIRY]: 'Dairy',
  [AllergenTag.EGGS]: 'Eggs',
  [AllergenTag.SOY]: 'Soy',
  [AllergenTag.FISH]: 'Fish',
  [AllergenTag.SHELLFISH]: 'ShellFish',
  [AllergenTag.PORK]: 'Pork',
  [AllergenTag.BEEF]: 'Beef',
  [AllergenTag.RED_MEAT]: 'Red meat',
  [AllergenTag.GLUTEN]: 'Gluten',
  [AllergenTag.SUGAR]: 'Sugar',
  [AllergenTag.ANIMAL_ORIGIN]: 'Animal Origin',
  [AllergenTag.MEAT]: 'Meat',
};

const MacroNutrient = {
  PROTEIN: 'protein',
  FAT: 'fat',
  CARBS: 'carbs',
};

// Each restriction is a set of allergen tags that the recipe should not contain
const RestrictionAllergens = {
  [DietaryRestriction.VEGAN]: [
    AllergenTag.ANIMAL_ORIGIN,
    AllergenTag.DAIRY,
    AllergenTag.EGGS,
    AllergenTag.MEAT,
    AllergenTag.FISH,
    AllergenTag.SHELLFISH,
    AllergenTag.PORK,
    AllergenTag.BEEF,
    AllergenTag.RED_MEAT,
  ],
  [DietaryRestriction.VEGETARIAN]: [
    AllergenTag.MEAT,
    AllergenTag.FISH,
    AllergenTag.SHELLFISH,
    AllergenTag.PORK,
    AllergenTag.BEEF,
    AllergenTag.RED_MEAT,
  ],
  [DietaryRestriction.GLUTEN_FREE]: [
    AllergenTag.GLUTEN,
  ],
  [DietaryRestriction.DAIRY_FREE]: [
    AllergenTag.DAIRY,
  ],
  [DietaryRestriction.SOY_FREE]: [
    AllergenTag.SOY,
  ],
  [DietaryRestriction.FISH_FREE]: [
    AllergenTag.FISH,
    AllergenTag.SHELLFISH,
  ],
  [DietaryRestriction.SHELLFISH_FREE]: [
    AllergenTag.SHELLFISH,
  ],
  [DietaryRestriction.EGG_FREE]: [
    AllergenTag.EGGS,
  ],
  [DietaryRestriction.PEANUT_FREE]: [
    AllergenTag.PEANUTS,
  ],
  [DietaryRestriction.TREE_NUTS_FREE]: [
    AllergenTag.TREE_NUTS,
  ],
  [DietaryRestriction.NUTS_FREE]: [
    AllergenTag.NUTS,
    AllergenTag.TREE_NUTS,
    AllergenTag.PEANUTS,
  ],
  [DietaryRestriction.RED_MEAT_FREE]: [
    AllergenTag.RED_MEAT,
    AllergenTag.PORK,
    AllergenTag.BEEF,
  ],
  [DietaryRestriction.MEAT_FREE]: [
    AllergenTag.MEAT,
    AllergenTag.RED_MEAT,
    AllergenTag.PORK,
    AllergenTag.BEEF,
  ],
  [DietaryRestriction.PORK_FREE]: [
    AllergenTag.PORK,
  ],
  [DietaryRestriction.BEEF_FREE]: [
    AllergenTag.BEEF,
  ],
  [DietaryRestriction.SUGAR_FREE]: [
    AllergenTag.SUGAR,
  ],
};

/*
  Get total % of calories allocated.
  Also check for errors, if any meal time has 0% calories allocated, or a meal time is missing a name.
  Meal times cannot have duplicated names.
*/
const getMealTimesStats = (mealTimes) => (
  mealTimes.reduce((acc, mealTime) => ({
    caloriesAllocated: acc.caloriesAllocated + mealTime.caloricSplit,
    noAllocationError: mealTime.caloricSplit === 0 || acc.noAllocationError,
    noNameError: !mealTime.name || acc.noNameError,
    duplicatedNamesError: acc.mealTimeNames.includes(mealTime.name.trim()),
    mealTimeNames: [...acc.mealTimeNames, mealTime.name.trim()],
    numberOfRecipesError: mealTime.recipesAmount < 1
      || mealTime.recipesAmount > 50
      || !Number.isInteger(mealTime.recipesAmount)
      || acc.numberOfRecipesError,
  }), {
    caloriesAllocated: 0,
    noAllocationError: false,
    noNameError: false,
    duplicatedNamesError: false,
    mealTimeNames: [],
    numberOfRecipesError: false,
  })
);

// Get macro averages of an array of meals (recipes)
const getMealsMacrosAvgs = (meals, roundResults = false) => {
  const avgs = meals.reduce((accMacrosAvg, {
    recipe: {
      protein,
      carbs,
      fat,
      totalCalories: calories,
    },
  }) => ({
    protein: (parseFloat(protein) / meals.length) + accMacrosAvg.protein,
    carbs: (parseFloat(carbs) / meals.length) + accMacrosAvg.carbs,
    fat: (parseFloat(fat) / meals.length) + accMacrosAvg.fat,
    calories: (parseFloat(calories) / meals.length) + accMacrosAvg.calories,
  }), {
    protein: 0,
    carbs: 0,
    fat: 0,
    calories: 0,
  });
  if (roundResults) {
    avgs.protein = Math.round(avgs.protein);
    avgs.carbs = Math.round(avgs.carbs);
    avgs.fat = Math.round(avgs.fat);
    avgs.calories = Math.round(avgs.calories);
  }
  return avgs;
};

const getMacroAverages = (mealTimes) => {
  // Using buckets caloric split percentages as weights
  const weightsSum = mealTimes.reduce((sum, mealTime) => sum + mealTime.caloricSplit, 0);

  // Macros weighted averages for all meals in the meal plan, in grams.
  const weightedAverages = mealTimes.reduce((accBucketAvgs, mealTime) => {
    const { meals, caloricSplit: weight } = mealTime;
    if (!meals) {
      return {
        protein: 0,
        carbs: 0,
        fat: 0,
      };
    }
    // Regular averages per bucket
    const macrosAvgsPerBucket = getMealsMacrosAvgs(meals);

    return {
      protein: Math.round((macrosAvgsPerBucket.protein * weight) / weightsSum) + accBucketAvgs.protein,
      carbs: Math.round((macrosAvgsPerBucket.carbs * weight) / weightsSum) + accBucketAvgs.carbs,
      fat: Math.round((macrosAvgsPerBucket.fat * weight) / weightsSum) + accBucketAvgs.fat,
    };
  }, {
    protein: 0,
    carbs: 0,
    fat: 0,
  });

  // Get percentages from weighted averages
  const proteinCalories = proteinToCalories(weightedAverages.protein);
  const carbsCalories = carbsToCalories(weightedAverages.carbs);
  const fatCalories = fatToCalories(weightedAverages.fat);

  const totalCalories = proteinCalories + carbsCalories + fatCalories;

  const proteinPerc = Math.round((proteinCalories / totalCalories) * 100);
  const carbsPerc = Math.round((carbsCalories / totalCalories) * 100);
  const fatPerc = 100 - proteinPerc - carbsPerc;

  return {
    macros: weightedAverages,
    percentages: {
      protein: proteinPerc,
      carbs: carbsPerc,
      fat: fatPerc,
    },
  };
};

// Support half servings
const SCALING_FACTOR = 0.5;

const getMealServings = ({
  meal,
  mealTimes,
  bucket,
  totalDailyCalories,
}) => {
  const {
    recipe: {
      totalCalories: recipeCalories,
    },
    servings,
  } = meal;

  const { caloricSplit } = mealTimes.find(({ name: mealTimeName }) => mealTimeName === bucket) || {};

  if (caloricSplit && recipeCalories > 0) {
    // Get bucket target calories using meal times caloric split
    const bucketTargetCalories = totalDailyCalories * (caloricSplit / 100);
    // Fit recipe calories in bucket target
    const bucketRecipeRatio = bucketTargetCalories / recipeCalories;
    // Round to nearest 0.5 multiple (supporting half servings)
    const calculatedServings = Math.round(bucketRecipeRatio / SCALING_FACTOR) * SCALING_FACTOR;
    // If calculatedServings is 0 then use half a serving
    return calculatedServings || SCALING_FACTOR;
  }
  return servings;
};

const Diet = {
  ATKINS: 'ATKINS',
  LOW_CARB: 'LOW_CARB',
  LOW_FAT: 'LOW_FAT',
  BALANCED: 'BALANCED',
  KETO: 'KETO',
  HIGH_PROTEIN: 'HIGH_PROTEIN',
};

const dietTexts = {
  [Diet.ATKINS]: 'Atkins',
  [Diet.LOW_CARB]: 'Low Carb',
  [Diet.LOW_FAT]: 'Low Fat',
  [Diet.BALANCED]: 'Balanced',
  [Diet.KETO]: 'Keto',
  [Diet.HIGH_PROTEIN]: 'High Protein',
};

const dietMacroRanges = {
  [Diet.ATKINS]: {
    proteinRange: {
      from: 20,
      to: 30,
    },
    carbsRange: {
      from: 0,
      to: 15,
    },
    fatRange: {
      from: 55,
      to: 65,
    },
  },
  [Diet.LOW_CARB]: {
    proteinRange: {
      from: 0,
      to: 100,
    },
    carbsRange: {
      from: 0,
      to: 20,
    },
    fatRange: {
      from: 0,
      to: 100,
    },
  },
  [Diet.LOW_FAT]: {
    proteinRange: {
      from: 0,
      to: 100,
    },
    carbsRange: {
      from: 0,
      to: 100,
    },
    fatRange: {
      from: 0,
      to: 20,
    },
  },
  [Diet.BALANCED]: {
    proteinRange: {
      from: 25,
      to: 35,
    },
    carbsRange: {
      from: 40,
      to: 50,
    },
    fatRange: {
      from: 20,
      to: 30,
    },
  },
  [Diet.KETO]: {
    proteinRange: {
      from: 10,
      to: 20,
    },
    carbsRange: {
      from: 0,
      to: 10,
    },
    fatRange: {
      from: 70,
      to: 80,
    },
  },
  [Diet.HIGH_PROTEIN]: {
    proteinRange: {
      from: 40,
      to: 100,
    },
    carbsRange: {
      from: 0,
      to: 100,
    },
    fatRange: {
      from: 0,
      to: 100,
    },
  },
};

const getMacroPercentages = (macros) => {
  const proteinCalories = proteinToCalories(macros.protein);
  const carbsCalories = carbsToCalories(macros.carbs);
  const fatCalories = fatToCalories(macros.fat);

  const totalCalories = proteinCalories + carbsCalories + fatCalories;

  const proteinPercentage = Math.round((proteinCalories / totalCalories) * 100);
  const carbsPercentage = Math.round((carbsCalories / totalCalories) * 100);
  const fatPercentage = 100 - proteinPercentage - carbsPercentage;

  return {
    proteinPercentage,
    carbsPercentage,
    fatPercentage,
  };
};

const getMacroGoalsValuesInGrams = (totalGoalCalories, macroPercentages) => {
  const protein = Math.round(caloriesToProtein(totalGoalCalories * (macroPercentages.protein / 100)));
  const carbs = Math.round(caloriesToCarbs(totalGoalCalories * (macroPercentages.carbs / 100)));
  const fat = Math.round(caloriesToFat(totalGoalCalories * (macroPercentages.fat / 100)));

  return {
    protein,
    carbs,
    fat,
  };
};

const getMacroNutrientsFromCalories = (calories, percentages) => ({
  protein: Math.round(caloriesToProtein(calories * (percentages.protein / 100))),
  carbs: Math.round(caloriesToCarbs(calories * (percentages.carbs / 100))),
  fat: Math.round(caloriesToFat(calories * (percentages.fat / 100))),
});

const calculateUserMacroGoals = ({
  userDoc = {},
  nutritionProfileDoc = {},
  activityProfileDoc = {},
  currentWeight = null,
  weeklyWeightGoal = '',
  minCaloriesThreshold = null,
  maxCaloriesThreshold = null,
} = {}) => {
  // Required
  if (nutritionProfileDoc && activityProfileDoc && userDoc) {
    const {
      birthdate,
    } = userDoc;

    const {
      biologicalSex,
      currentWeight: userCurrentWeight,
      height: userHeight = {},
      heightMeasurementUnit,
      weightMeasurementUnit,
      weeklyWeightGoal: userWeeklyWeightGoal = WeeklyWeightGoal.MAINTAIN_WEIGHT,
    } = nutritionProfileDoc;

    const {
      currentWorkoutDays,
    } = activityProfileDoc;

    let height;

    if (heightMeasurementUnit === MeasurementUnit.METRIC) {
      height = userHeight;
    } else if (typeof userHeight.ft === 'number' && typeof userHeight.in === 'number') {
      height = getCentimetersFromFtIn({ ft: userHeight.ft, inches: userHeight.in });
    }

    let weight;

    if (currentWeight) {
      weight = currentWeight;
    } else if (weightMeasurementUnit === MeasurementUnit.METRIC) {
      weight = userCurrentWeight;
    } else {
      weight = getKgFromLbs(userCurrentWeight);
    }

    const activityLevel = WorkoutDaysToActivityLevel[currentWorkoutDays] || ActivityLevel.SEDENTARY;

    const age = moment().diff(moment(birthdate), 'year');
    const bmr = calculateBMR(biologicalSex, weight, height, age);
    const tdee = calculateTDEE(bmr, activityLevel);
    const calculatedDailyCalories = calculateCaloricGoal(tdee, weeklyWeightGoal || userWeeklyWeightGoal);

    let totalDailyCalories = calculatedDailyCalories;
    if (minCaloriesThreshold) {
      totalDailyCalories = Math.max(totalDailyCalories, minCaloriesThreshold);
    }
    if (maxCaloriesThreshold) {
      totalDailyCalories = Math.min(totalDailyCalories, maxCaloriesThreshold);
    }

    return {
      bmr,
      tdee,
      calculatedDailyCalories,
      totalDailyCalories,
    };
  }
  return {};
};

export {
  getSummarizedMacroNutrients,
  carbsToCalories,
  proteinToCalories,
  fatToCalories,
  caloriesToProtein,
  caloriesToCarbs,
  caloriesToFat,
  RecipeTag,
  AllergenTag,
  AllergenTagText,
  MacroNutrient,
  RestrictionAllergens,
  getMealTimesStats,
  getMealsMacrosAvgs,
  getMacroAverages,
  getMealServings,
  Diet,
  dietTexts,
  dietMacroRanges,
  getMacroPercentages,
  getMacroNutrientsFromCalories,
  calculateUserMacroGoals,
  getMacroGoalsValuesInGrams,
};
