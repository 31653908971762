import * as Yup from 'yup';

import {
  fieldName,
  ingredientField,
} from './formFields';

import texts from './texts';

const initialIngredientValues = {
  [ingredientField.NAME]: '',
  [ingredientField.AMOUNT]: '',
  [ingredientField.UNIT]: '',
  [ingredientField.SECOND_AMOUNT]: '',
  [ingredientField.SECOND_UNIT]: '',
  [ingredientField.PREPARATION_COMMENT]: '',
  [ingredientField.ALLERGEN_TAGS]: [],
};

const initialValues = {
  [fieldName.NAME]: '',
  [fieldName.DESCRIPTION]: '',
  [fieldName.TAGS]: [],
  [fieldName.PREPARATION_TIME]: '',
  [fieldName.COOKING_TIME]: '',
  [fieldName.SERVINGS]: '',
  [fieldName.INSTRUCTIONS]: [''],
  [fieldName.SCALE]: false,
  [fieldName.PROTEIN]: 0,
  [fieldName.FAT]: 0,
  [fieldName.CARBS]: 0,
  [fieldName.TOTAL_CALORIES]: 0,
  [fieldName.INGREDIENTS]: [
    initialIngredientValues,
  ],
};

const validationSchema = Yup.object().shape({
  [fieldName.NAME]: Yup.string().required(texts.validation.requiredField),
  [fieldName.DESCRIPTION]: Yup.string(),
  [fieldName.PREPARATION_TIME]: Yup.number().required(texts.validation.requiredField)
    .min(0, texts.validation.nonNegativeField),
  [fieldName.COOKING_TIME]: Yup.number().required(texts.validation.requiredField)
    .min(0, texts.validation.nonNegativeField),
  [fieldName.SERVINGS]: Yup.number()
    .integer(texts.validation.integerNumber)
    .positive(texts.validation.positiveNumber)
    .required(texts.validation.requiredField),
  [fieldName.INSTRUCTIONS]: Yup.array().of(
    Yup.string().required(texts.validation.requiredField),
  ),
  [fieldName.SCALE]: Yup.boolean(),
  [fieldName.PROTEIN]: Yup.number()
    .min(0, texts.validation.positiveNumber)
    .required(texts.validation.requiredField),
  [fieldName.FAT]: Yup.number()
    .min(0, texts.validation.positiveNumber)
    .required(texts.validation.requiredField),
  [fieldName.CARBS]: Yup.number()
    .min(0, texts.validation.positiveNumber)
    .required(texts.validation.requiredField),
  [fieldName.INGREDIENTS]: Yup.array().of(
    Yup.object().shape({
      [ingredientField.NAME]: Yup.string().required(texts.validation.requiredField),
      [ingredientField.AMOUNT]: Yup.string().required(texts.validation.requiredField),
      [ingredientField.UNIT]: Yup.string(),
      [ingredientField.SECOND_AMOUNT]: Yup.string(),
      [ingredientField.SECOND_UNIT]: Yup.string(),
      [ingredientField.PREPARATION_COMMENT]: Yup.string(),
    }),
  ),
});

export {
  initialIngredientValues,
  initialValues,
  validationSchema,
};
