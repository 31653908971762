import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import format from 'string-template';
import { DeleteOutline, EmailOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import useQuickSearch from '../../../../../hooks/useQuickSearch';
import FirebaseContext from '../../../../../context/FirebaseContext';
import CoachOnboarding from '../../../../Model/CoachOnboarding';
import GenericDataGrid from '../../../../components/GenericDataGrid';
import QuickSearchToolbar from '../../../../components/QuickSearchToolbar';
import LabelCheckbox from '../../../../components/LabelCheckbox';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import useToast from '../../../../hooks/useToast';
import CoachLeadModal from '../CoachLeadModal';

import {
  CoachName,
  ActionsContainer,
  DataGridContainer,
} from './styles';
import texts from './texts.json';

const OnboardingTable = ({
  rows,
  showAllOnboardings,
  handleCheckboxClick,
}) => {
  const [leadToDelete, setLeadToDelete] = useState();
  const [selectedLead, setSelectedLead] = useState(null);

  const { showToast } = useToast();
  const { firebase } = useContext(FirebaseContext);

  const onResendCoachEmail = useCallback(async (coachEmail) => {
    await firebase.remote('coachAccountCreation/onboardingemail', {
      coachOnboardingId: rows.find(({ email }) => email === coachEmail).id,
    });
    showToast(format(texts.emailSentMessage, { email: coachEmail }));
  }, [
    firebase,
    rows,
    showToast,
  ]);

  const renderNameCell = useCallback(({ row }) => (
    <CoachName onClick={() => setSelectedLead(row)}>{row.name}</CoachName>
  ), []);

  // The actions cell is rendered differently, based on the status of the row.
  const renderActionCell = useCallback(
    ({
      row: {
        id,
        isOnboarded,
      } = {},
    }) => {
      const onRemove = () => setLeadToDelete(id);
      const onEmail = () => onResendCoachEmail(id);
      return (
        <ActionsContainer>
          <IconButton disabled={isOnboarded} onClick={onRemove}>
            <DeleteOutline />
          </IconButton>
          <IconButton disabled={isOnboarded} onClick={onEmail}>
            <EmailOutlined />
          </IconButton>
        </ActionsContainer>
      );
    },
    [onResendCoachEmail],
  );

  const columns = useMemo(() => [
    {
      field: 'name',
      headerName: texts.headers.name,
      flex: 15,
      quickSearch: true,
      renderCell: renderNameCell,
    },
    {
      field: 'email',
      headerName: texts.headers.email,
      flex: 20,
    },
    {
      field: 'stripeAccountId',
      headerName: texts.headers.stripeAccountId,
      type: 'boolean',
      flex: 15,
    },
    {
      field: 'avatarUrl',
      headerName: texts.headers.avatarUrl,
      type: 'boolean',
      flex: 10,
    },
    {
      field: 'isOnboarded',
      headerName: texts.headers.isOnboarded,
      type: 'boolean',
      flex: 10,
    },
    {
      field: 'status',
      headerName: texts.headers.status,
      flex: 15,
    },
    {
      field: 'coach',
      headerName: texts.headers.coach,
      flex: 20,
      sortable: false,
    },
    {
      field: 'createdAt',
      headerName: texts.headers.dateCreated,
      type: 'dateTime',
      flex: 10,
    },
    {
      field: 'actions',
      headerName: texts.headers.actions,
      flex: 10,
      renderCell: renderActionCell,
      sortable: false,
    },
  ], [
    renderNameCell,
    renderActionCell,
  ]);

  const preparedRows = useMemo(() => rows.map((row) => ({
    onboardingId: row.id,
    id: row.email,
    name: row.name,
    email: row.email,
    isOnboarded: row.isOnboarded,
    status: row.status,
    coach: row.coach,
    stripeAccountId: row.coachHasStripeAccountId,
    avatarUrl: row.coachHasAvatarUrl,
    createdAt: row.createdAt,
  })), [rows]);

  const handleLeadDelete = useCallback(async () => {
    await CoachOnboarding.removeCoachOnboardingDoc(rows.find(({ email }) => email === leadToDelete).id);
    showToast(format(texts.leadDeleteMessage, { leadName: leadToDelete }));
    setLeadToDelete(null);
  }, [
    leadToDelete,
    rows,
    showToast,
  ]);

  const {
    filteredRows: quickSearchRows,
    toolbarProps,
  } = useQuickSearch(preparedRows, columns);

  return (
    <DataGridContainer>
      <GenericDataGrid
        rows={quickSearchRows}
        columns={columns}
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          },
          pagination: {
            paginationModel: { pageSize: 25, page: 0 },
          },
        }}
        components={{
          Toolbar: QuickSearchToolbar,
        }}
        componentsProps={{
          toolbar: {
            ...toolbarProps,
            placeholder: texts.searchPlaceholder,
            filterTools: [
              {
                Component: LabelCheckbox,
                id: 'crx-filter',
                props: {
                  isChecked: showAllOnboardings,
                  description: texts.showAll,
                  onChange: handleCheckboxClick,
                },
              },
            ],
          },
        }}
      />
      <ConfirmDialog
        isOpen={!!leadToDelete}
        onConfirm={handleLeadDelete}
        onCancel={() => setLeadToDelete(null)}
        dialogTexts={{
          title: texts.deleteConfirm,
          content: leadToDelete || '',
        }}
      />
      {!!selectedLead && (
        <CoachLeadModal
          open={!!selectedLead}
          onClose={() => setSelectedLead(null)}
          onboardingDoc={selectedLead}
        />
      )}
    </DataGridContainer>
  );
};

OnboardingTable.propTypes = {
  rows: PropTypes.array.isRequired,
  showAllOnboardings: PropTypes.bool.isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
};

export default compose(
  observer,
)(OnboardingTable);
