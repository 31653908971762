import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import format from 'string-template';

import useComponentMounted from '../../../hooks/useComponentMounted';
import useStorage from '../../../hooks/useStorage';
import { storagePaths, pathPlaceholder } from '../../../utils/firebasePaths';
import LoadingPage from '../../../components/LoadingPage';
import Form from '../../Model/Form';
import CoachesListContext from '../../context/CoachesListContext';
import useToolsNavigation from '../../hooks/useToolsNavigation';
import useToast from '../../hooks/useToast';
import AutoComplete from '../AutoComplete';
import LoggedInUserContext from '../../../context/LoggedInUserContext';

import FormQuestions from './components/FormQuestions';
import HiddenFields from './components/HiddenFields';
import EndScreen from './components/EndScreen/EndScreen';
import { initialValues as startValues } from './validation';
import fieldName, { endScreenContentFieldName, questionId } from './formFields';
import {
  Container,
  HeaderContainer,
  Title,
  StyledBackButton,
  StyledForm,
  FormSectionContainer,
  StyledFormInput,
  StyledFormButton,
  StyledTopRow,
  StyledBottomRow,
  StyledTypography,
  CoachContainer,
} from './styles';
import texts from './texts';
import QuestionType from './questionType';

const FormBuilder = ({
  formDoc,
  onBackClick,
}) => {
  const [initialFormValues, setInitialFormValues] = useState(startValues);

  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();
  const { navigateToForm } = useToolsNavigation();
  const { uploadJSON, getJSONData } = useStorage();

  const { coachesCollection } = useContext(CoachesListContext);

  const isEditView = !!formDoc;

  const {
    userId: createdBy,
  } = useContext(LoggedInUserContext);

  const coaches = useMemo(() => (
    coachesCollection.docs.map(({ id, name }) => ({ id, label: name }))
  ), [coachesCollection]);

  const handleSubmit = useCallback(async (values, actions) => {
    const {
      [fieldName.NAME]: formName,
      [fieldName.COACH]: coach,
      [fieldName.COACH_ID]: coachId,
      [fieldName.FIELDS]: questions,
      [fieldName.HIDDEN_FIELDS]: hiddenFields,
      [fieldName.END_SCREEN_CONTENT]: {
        [endScreenContentFieldName.LINE_1]: endScreenLine1,
        [endScreenContentFieldName.LINE_2]: endScreenLine2,
      },
    } = values;

    // Remove isDefault attributes from the from questions as they are not needed for the form after creation
    const updatedQuestions = questions.map((question) => {
      const { isDefault, ...rest } = question;
      return rest;
    });
    const updatedHiddenFields = hiddenFields.map((hiddenField) => {
      const { isDefault, ...rest } = hiddenField;
      return rest;
    });

    let errMsg;
    if (!coach) { // First, check if a coach is selected
      errMsg = texts.validation.coach;
    } else if (!formName) { // The form name should not be empty either
      errMsg = texts.validation.formNameRequired;
    } else if (updatedQuestions.length === 0) { // Then, check if there are at least one question in the form
      errMsg = texts.validation.questionsRequired;
    } else if (!endScreenLine1 || !endScreenLine2) { // Also check whether the end screen values are not empty
      errMsg = texts.validation.endScreenContentRequired;
    }

    // Check the financial commitment question
    const financialCommitment = updatedQuestions.find(
      (question) => question.id === questionId.FINANCIAL_COMMITMENT,
    );
    if (financialCommitment) {
      if (financialCommitment.type === QuestionType.OPTIONS) {
        const options = financialCommitment.options.split(',').map((option) => option.trim().toLowerCase());
        // Check for at least 2 options
        if (options.length < 2) {
          errMsg = texts.validation.financialCommitment.options;
        } else {
          // Check if one option starts with "Yes" and another starts with "No"
          const hasYes = options.some((item) => item.startsWith(texts.yes));
          const hasNo = options.some((item) => item.startsWith(texts.no));
          if (!hasYes || !hasNo) {
            errMsg = texts.validation.financialCommitment.requiredYesAndNo;
          }
        }
      } else {
        errMsg = texts.validation.financialCommitment.type;
      }
    }

    if (errMsg) {
      showToast(errMsg, { error: true });
      actions.setSubmitting(false);
      return;
    }

    // After form validation, we will save the form in firestore
    const newFormData = {
      formName,
      coach,
      coachId,
    };
    let formId;
    if (isEditView) {
      formId = formDoc.id;
      await formDoc.updateFields({ ...newFormData });
    } else {
      formId = await Form.addForm({
        ...newFormData,
        createdBy,
        isArchived: false,
      });
    }

    // Then, the form will be saved in storage for public access using the form ID
    const formConfigString = JSON.stringify({
      ...values,
      [fieldName.FIELDS]: updatedQuestions,
      [fieldName.HIDDEN_FIELDS]: updatedHiddenFields,
    });
    const storagePath = format(storagePaths.COACH_FORM_COLLECTION, {
      [pathPlaceholder.COACH_ID]: coachId,
    });
    await uploadJSON(formConfigString, storagePath, formId);

    showToast(texts.formPublished);

    // Load the newly created form in the editor
    if (!isEditView) {
      navigateToForm(formId);
    }
  }, [
    uploadJSON,
    showToast,
    navigateToForm,
    formDoc,
    isEditView,
    createdBy,
  ]);

  useEffect(() => {
    const fetchFormData = async () => {
      const {
        id: formId,
        coach,
        coachId,
        formName,
      } = formDoc;

      // Fetch form data from the storage JSON file
      const storagePath = format(storagePaths.COACH_FORM_COLLECTION, {
        [pathPlaceholder.COACH_ID]: coachId,
      });
      const formData = await getJSONData(`${storagePath}/${formId}`);
      const {
        fields,
        hiddenFields,
        endScreenContent,
      } = formData;

      const values = {
        [fieldName.COACH]: coach,
        [fieldName.COACH_ID]: coachId,
        [fieldName.NAME]: formName,
        [fieldName.FIELDS]: fields,
        [fieldName.HIDDEN_FIELDS]: hiddenFields,
        [fieldName.END_SCREEN_CONTENT]: endScreenContent,
      };

      if (isComponentMountedRef.current) {
        setInitialFormValues(values);
      }
    };

    if (isEditView) {
      fetchFormData();
    }
  }, [
    formDoc,
    isEditView,
    getJSONData,
    isComponentMountedRef,
  ]);

  // Show loading page until form data is loaded from storage
  if (isEditView && initialFormValues === startValues) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <HeaderContainer>
        <Title>
          {isEditView ? texts.editForm : texts.createForm}
        </Title>
        <StyledBackButton
          onClick={onBackClick}
        >
          {texts.back}
        </StyledBackButton>
      </HeaderContainer>
      <Formik
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
        }) => (
          <StyledForm>
            <StyledTopRow>
              <FormSectionContainer>
                <StyledTypography>
                  {texts.selectDescription}
                  {isEditView && ` : ${formDoc[fieldName.COACH]}`}
                </StyledTypography>
                {!isEditView
                && (
                  <CoachContainer>
                    <AutoComplete
                      options={coaches}
                      onChange={(data) => {
                        setFieldValue(fieldName.COACH_ID, data?.id);
                        setFieldValue(fieldName.COACH, data?.label);
                      }}
                    />
                  </CoachContainer>
                )}
              </FormSectionContainer>
            </StyledTopRow>
            <StyledFormInput
              name={fieldName.NAME}
              label={texts.field[fieldName.NAME].label}
            />
            <HiddenFields hiddenFields={values[fieldName.HIDDEN_FIELDS]} />
            <FormQuestions questions={values[fieldName.FIELDS]} />
            <EndScreen />
            <StyledBottomRow>
              <StyledFormButton
                disabled={isSubmitting}
                type="submit"
              >
                {texts.submitButton}
              </StyledFormButton>
            </StyledBottomRow>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
};

FormBuilder.propTypes = {
  formDoc: PropTypes.object,
  onBackClick: PropTypes.func.isRequired,
};

FormBuilder.defaultProps = {
  formDoc: null,
};

export default FormBuilder;
