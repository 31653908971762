import React, {
  useMemo,
  useContext,
  useEffect,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { FastField, useFormikContext } from 'formik';
import { IconButton, Tooltip } from '@mui/material';
import { Container as DraggableContainer, Draggable } from 'react-smooth-dnd';
import format from 'string-template';

import { ReactComponent as ReviewIcon } from '../../../../../../../../assets/icons/v2/arrow-right.svg';
import { ReactComponent as EditIcon } from '../../../../../../../../assets/icons/v2/edit-blue.svg';
import { ReactComponent as DragIcon } from '../../../../../../../../assets/icons/v2/drag.svg';
import { ReactComponent as ResetIcon } from '../../../../../../../../assets/icons/v2/reload.svg';
import { VisibilityType } from '../../../../../../../../Model/Workout';
import { PrimaryButton } from '../../../../../../../../components/Button/ActionButtons';
import ConfirmDialog from '../../../../../../../components/ConfirmDialog';
import { AttentionTag, WarningTag } from '../../../../../../../../components/Tags';
import WorkoutContext from '../../../../../../../context/WorkoutContext';
import { WorkoutAssignmentCode, validateActivities } from '../../../../../../../../utils/workout';
import {
  initialWorkoutDayValues,
} from '../../validation';
import { fieldName } from '../../formFields';
import {
  Label,
} from '../../styles';

import {
  Container,
  StyledFieldArray,
  WorkoutDaysContainer,
  AddNewOptionContainer,
  StyledTypography,
  WorkoutDayIndex,
  StyledOption,
  StyledTextField,
  StyledTrashIcon,
  StyledAddIcon,
  StyledOptionSubContent,
  StyledAutocomplete,
  DraggableContent,
  ButtonContainer,
  StyledAutocompleteWrapper,
  ChipContainer,
} from './styles';
import texts from './texts';

const VISIBLE_TAGS_COUNT = 1;

const WorkoutDaysSelector = ({
  workoutDays,
  onPreviewWorkout,
  onEditWorkout,
  onClosePreview,
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [indexToRemove, setIndexToRemove] = useState(null);

  const { workouts } = useContext(WorkoutContext);

  const { values, setFieldValue } = useFormikContext();

  const workoutDaysList = values[fieldName.WORKOUT_DAYS];

  /**
   * Updates the equipment list based on the selected workouts in the workout days.
   *
   * Depending on the selected workout, this function updates the value of the formik field
   * 'equipment' with unique equipment items.
   */
  useEffect(() => {
    let currentEquipmentList = workoutDaysList
      .flatMap((day) => {
        if (day.workout) {
          return day.workout.equipmentList;
        }
        return [];
      });

    currentEquipmentList = Array.from(new Set(currentEquipmentList));
    setFieldValue(fieldName.EQUIPMENT, currentEquipmentList);
  }, [
    setFieldValue,
    workoutDaysList,
  ]);

  const renderEquipmentChip = useCallback((equipment) => {
    if (equipment && equipment.length > 0) {
      const firstEquipmentItem = equipment.slice(0, VISIBLE_TAGS_COUNT);
      const remainingEquipment = equipment.slice(VISIBLE_TAGS_COUNT, equipment.length);
      const tooltipText = remainingEquipment.join(', ');
      return (
        <>
          {remainingEquipment.length ? (
            <Tooltip
              title={tooltipText}
              placement="top"
              arrow
              PopperProps={{
                disablePortal: true,
              }}
            >
              <WarningTag>
                {format(texts.equipment,
                  { equipment: `${firstEquipmentItem[0]}, ${texts.moreEquipment}` })}
              </WarningTag>
            </Tooltip>
          ) : (
            <WarningTag>{format(texts.equipment, { equipment: firstEquipmentItem[0] })}</WarningTag>
          )}
        </>
      );
    }
    return <WarningTag>{format(texts.equipment, { equipment: texts.none })}</WarningTag>;
  }, []);

  const renderTimeChip = useCallback((estimatedDurationInMinutes) => (
    <AttentionTag>{format(texts.estDuration, { minutes: estimatedDurationInMinutes })}</AttentionTag>
  ), []);

  const renderInput = useCallback((params, index) => (
    <StyledTextField
      {...params}
      label={texts.textFieldLabel}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <ChipContainer>
            {renderEquipmentChip(workoutDaysList[index].equipment)}
            {!!workoutDaysList[index].workout
            && !!workoutDaysList[index].workout.estimatedDurationInMinutes && (
              renderTimeChip(
                workoutDaysList[index].workout.estimatedDurationInMinutes,
              )
            )}
            {params.InputProps.endAdornment}
          </ChipContainer>
        ),
      }}
    />
  ), [
    renderEquipmentChip,
    renderTimeChip,
    workoutDaysList,
  ]);

  const onWorkoutChange = useCallback((value, index) => {
    onClosePreview();
    setFieldValue(`${fieldName.WORKOUT_DAYS}[${index}]`, value);
  }, [
    onClosePreview,
    setFieldValue,
  ]);

  const onClickRemove = (index) => {
    setIndexToRemove(index);
    setShowConfirmDialog(true);
  };

  const cancelRemove = () => {
    setShowConfirmDialog(false);
    setIndexToRemove(null);
  };

  const workoutOptions = useMemo(() => workouts.map((workout) => {
    const workoutStatus = validateActivities(workout, true);
    return ({
      id: workout.id,
      label: workout.name,
      equipment: workout.equipmentList,
      workout,
      isValidWorkout: workoutStatus === WorkoutAssignmentCode.VALID_WORKOUT,
      workoutStatus,
    });
  }), [workouts]);

  return (
    <Container>
      <Label>{`${texts.title}:`}</Label>
      <StyledFieldArray
        name={fieldName.WORKOUT_DAYS}
        render={({
          remove,
          push,
          move,
        }) => {
          const confirmRemove = () => {
            remove(indexToRemove);
            setShowConfirmDialog(false);
            setIndexToRemove(null);
          };

          return (
            <>
              <DraggableContainer
                dragHandleSelector=".drag-handle"
                lockAxis="y"
                onDrop={({ removedIndex, addedIndex }) => move(removedIndex, addedIndex)}
              >
                {workoutDays.map((day, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Draggable key={index}>
                    <DraggableContent>
                      <WorkoutDaysContainer>
                        <IconButton className="drag-handle">
                          <DragIcon />
                        </IconButton>
                        <WorkoutDayIndex>{`${texts.day} ${index + 1}`}</WorkoutDayIndex>
                        <FastField name={`${fieldName.WORKOUT_DAYS}.${index}`}>
                          {({ field }) => (
                            <StyledAutocompleteWrapper>
                              <StyledAutocomplete
                                {...field}
                                disablePortal
                                disableClearable
                                options={workoutOptions.concat(initialWorkoutDayValues)}
                                getOptionDisabled={(option) => !option.isValidWorkout}
                                noOptionsText={texts.noOptionText}
                                onChange={(value) => onWorkoutChange(value, index)}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => renderInput(params, index)}
                                renderOption={(props, {
                                  id,
                                  label,
                                  equipment,
                                  workout,
                                  isValidWorkout,
                                  workoutStatus,
                                }) => (
                                  <Tooltip
                                    key={id}
                                    title={!isValidWorkout ? format(
                                      texts.draftWorkout, { draftReason: workoutStatus },
                                    ) : ''}
                                    arrow
                                    PopperProps={{
                                      disablePortal: true,
                                    }}
                                  >
                                    <StyledOption
                                      {...props}
                                      key={id}
                                      className="workout-option"
                                    >
                                      {label}
                                      <StyledOptionSubContent>
                                        {renderEquipmentChip(equipment)}
                                        {!!workout && !!workout.estimatedDurationInMinutes && (
                                          renderTimeChip(workout.estimatedDurationInMinutes)
                                        )}
                                      </StyledOptionSubContent>
                                    </StyledOption>
                                  </Tooltip>
                                )}
                              />
                            </StyledAutocompleteWrapper>

                          )}
                        </FastField>
                        <IconButton onClick={() => onClickRemove(index)}>
                          <StyledTrashIcon />
                        </IconButton>
                      </WorkoutDaysContainer>
                      { day.label !== initialWorkoutDayValues.label
                      && (
                      <ButtonContainer>
                        <PrimaryButton
                          onClick={() => setFieldValue(`${fieldName.WORKOUT_DAYS}[${index}]`, initialWorkoutDayValues)}
                          icon={<ResetIcon />}
                          variant="info"
                          size="medium"
                        >
                          {texts.resetWorkout}
                        </PrimaryButton>
                        <PrimaryButton
                          onClick={() => onEditWorkout(day.workout.id)}
                          disabled={day.workout.visibility === VisibilityType.PUBLIC}
                          icon={<EditIcon />}
                          variant="info"
                          size="medium"
                        >
                          {texts.editWorkout}
                        </PrimaryButton>
                        <PrimaryButton
                          onClick={() => onPreviewWorkout(day.workout.id)}
                          endIcon={<ReviewIcon />}
                          variant="info"
                          size="medium"
                        >
                          {texts.viewWorkout}
                        </PrimaryButton>
                      </ButtonContainer>
                      )}
                    </DraggableContent>
                  </Draggable>
                ))}
              </DraggableContainer>
              <AddNewOptionContainer>
                <IconButton onClick={() => push(initialWorkoutDayValues)}>
                  <StyledAddIcon />
                </IconButton>
                <StyledTypography>{texts.addNewDay}</StyledTypography>
              </AddNewOptionContainer>
              {showConfirmDialog && (
                <ConfirmDialog
                  isOpen={showConfirmDialog}
                  onConfirm={confirmRemove}
                  onCancel={cancelRemove}
                  dialogTexts={{
                    title: format(texts.deleteConfirmMsg, { workoutDate: indexToRemove + 1 }),
                  }}
                />
              )}
            </>
          );
        }}
      />
    </Container>
  );
};

WorkoutDaysSelector.propTypes = {
  workoutDays: PropTypes.array.isRequired,
  onPreviewWorkout: PropTypes.func.isRequired,
  onEditWorkout: PropTypes.func.isRequired,
  onClosePreview: PropTypes.func.isRequired,
};

export default WorkoutDaysSelector;
