import * as Yup from 'yup';

import { DifficultyLevel } from '../../../../../../Model/Program';
import {
  fieldName,
  workoutDayAttributes,
} from './formFields';

import texts from './texts';

const DEFAULT_NO_OF_DAYS = 7;

const initialWorkoutDayValues = {
  [workoutDayAttributes.ID]: null,
  [workoutDayAttributes.LABEL]: texts.restAndRecovery,
  [workoutDayAttributes.WORKOUT]: null,
  [workoutDayAttributes.EQUIPMENT]: [],
  [workoutDayAttributes.IS_VALID_WORKOUT]: true,
};

const initialValues = {
  [fieldName.NAME]: '',
  [fieldName.WORKOUT_DAYS]: Array(DEFAULT_NO_OF_DAYS).fill().map(() => initialWorkoutDayValues),
  [fieldName.DIFFICULTY]: DifficultyLevel.BEGINNER,
  [fieldName.EQUIPMENT]: [],
};

const validationSchema = Yup.object().shape({
  [fieldName.NAME]: Yup.string().required(texts.validation.requiredField),
});

export {
  initialValues,
  validationSchema,
  initialWorkoutDayValues,
};
