import styled from 'styled-components';
import { AddCircleOutline as AddIcon } from '@mui/icons-material';

import RoundedButton from '../../../components/v2/RoundedButton';
import { ButtonIconStyle } from '../../../components/v2/Button';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_icon.svg';
import colors from '../../../styles/colors';

const StyledRoundedButton = styled(RoundedButton)`
  background-color: ${colors.shades.secondary4};
  color: ${colors.base.secondary2};
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
  padding: 6px;
  margin: 0;
  border-radius: 5px;
`;

const StyledAddIcon = styled(AddIcon)`
  ${ButtonIconStyle}
  color: ${colors.base.secondary2};
`;

const StyledEditIcon = styled(EditIcon)`
  width: 16px;
  height: 16px;
  margin-right: 5px;

  > path {
    stroke: ${colors.base.secondary2};
  }
`;

export {
  StyledRoundedButton,
  StyledAddIcon,
  StyledEditIcon,
};
