import React from 'react';
import PropTypes from 'prop-types';

import {
  Stat,
  MacroStatContainer,
  MacroStat,
  MacroLabelText,
  BaseMacroValueText,
  MacroTitleText,
  MacroTitleContainer,
  MacroValueText,
} from './styles';
import texts from './texts.json';

const MacroStats = ({
  macros,
  macrosPercentages,
  isPopup,
  dailyGoalMacroPercentages,
  dailyGoalMacroValues,
}) => (
  <MacroStatContainer>
    {!isPopup && (
      <MacroTitleContainer>
        <MacroTitleText $bold>{texts.macroBreakdown}</MacroTitleText>
        <MacroTitleText>{texts.macroBreakdownOtherText}</MacroTitleText>
      </MacroTitleContainer>
    )}
    {macros && macrosPercentages && (
      <MacroStat>
        <Stat>
          <MacroLabelText $secondary>{texts.protein}</MacroLabelText>
          <MacroValueText $secondary={isPopup}>
            {`${macros.protein?.toFixed(0)}g (${macrosPercentages.proteinPercentage}%)`}
          </MacroValueText>
          {!isPopup && (
            <BaseMacroValueText $secondary>
              {`${dailyGoalMacroValues.protein?.toFixed(0)}g (${dailyGoalMacroPercentages.protein}%)`}
            </BaseMacroValueText>
          )}
        </Stat>
        <Stat>
          <MacroLabelText $secondary>{texts.carbs}</MacroLabelText>
          <MacroValueText $secondary={isPopup}>
            {`${macros.carbs?.toFixed(0)}g (${macrosPercentages.carbsPercentage}%)`}
          </MacroValueText>
          {!isPopup && (
            <BaseMacroValueText $secondary>
              {`${dailyGoalMacroValues.carbs?.toFixed(0)}g (${dailyGoalMacroPercentages.carbs}%)`}
            </BaseMacroValueText>
          )}
        </Stat>
        <Stat>
          <MacroLabelText $secondary>{texts.fat}</MacroLabelText>
          <MacroValueText $secondary={isPopup}>
            {`${macros.fat?.toFixed(0)}g (${macrosPercentages.fatPercentage}%)`}
          </MacroValueText>
          {!isPopup && (
            <BaseMacroValueText $secondary>
              {`${dailyGoalMacroValues.fat?.toFixed(0)}g (${dailyGoalMacroPercentages.fat}%)`}
            </BaseMacroValueText>
          )}
        </Stat>
      </MacroStat>
    )}
  </MacroStatContainer>
);

MacroStats.propTypes = {
  macros: PropTypes.object,
  macrosPercentages: PropTypes.object,
  isPopup: PropTypes.bool,
  dailyGoalMacroPercentages: PropTypes.object,
  dailyGoalMacroValues: PropTypes.object,
};

MacroStats.defaultProps = {
  macros: {},
  macrosPercentages: {},
  isPopup: false,
  dailyGoalMacroPercentages: {},
  dailyGoalMacroValues: {},
};

export default MacroStats;
