import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  reason: Yup.string()
    .required('Reason is required'),
});

const initialValues = {
  reason: '',
};

export {
  validationSchema,
  initialValues,
};
