import styled from 'styled-components';
import { Typography } from '@mui/material';

import colors from '../../../../../../styles/colors';
import LoadingPage from '../../../../../../components/LoadingPage';

const StyledLoadingPage = styled(LoadingPage)`
  height: 100px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 20px;
`;

const ActivityItem = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
`;

const ContentList = styled.ul`
  margin: 0;
`;

const ContentListItem = styled.li`
  margin-bottom: 5px;
`;

const Tag = styled.div`
  min-width: 120px;
  height: fit-content;
  text-align: center;
  padding: 7px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 10px;
  background: ${colors.shades.secondary7};
  border: 1px solid ${colors.shades.secondary4};
`;

const ContentText = styled(Typography)`
  font-size: 14px;
`;

export {
  StyledLoadingPage,
  Container,
  ActivityItem,
  ContentList,
  ContentListItem,
  Tag,
  ContentText,
};
