const offboardingChecklistItems = [
  'POST_PAYMENT_FORM_DELETED',
  'COACH_WEBSITE_DELETED',
  'APP_ICON_DELETED',
  'ZOHO_EMAIL_REMOVED',
];

const OffboardingChecklistEnum = Object.freeze({
  POST_PAYMENT_FORM_DELETED: 'POST_PAYMENT_FORM_DELETED',
  COACH_WEBSITE_DELETED: 'COACH_WEBSITE_DELETED',
  APP_ICON_DELETED: 'APP_ICON_DELETED',
  ZOHO_EMAIL_REMOVED: 'ZOHO_EMAIL_REMOVED',
  LEAD_FORM_ARCHIVED: 'LEAD_FORM_ARCHIVED',
});

export { offboardingChecklistItems, OffboardingChecklistEnum };
