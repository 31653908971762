import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton, Tooltip } from '@mui/material';
import format from 'string-template';

import FirebaseContext from '../../../../../../../context/FirebaseContext';
import useToast from '../../../../../../hooks/useToast';
import Button from '../../../../../../../components/Button/Button';
import { HistoryEventType } from '../../../../../../Model/CoachHistory';
import { BtnContainer, Container, TextBlock } from './styles';
import texts from './texts.json';

const StripeDeauthorization = ({
  coachDoc, onboardingDoc, refetch, coachHistory,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    firebase: { remote },
  } = useContext(FirebaseContext);
  const { showToast } = useToast();

  const handleDeauthorization = async () => {
    setIsLoading(true);
    const apiResponse = await remote('deauthorizeStripeAccount', {
      coachId: coachDoc.id,
    });

    if (apiResponse.ok) {
      refetch();
      showToast(texts.deauthorizationSuccess);
    } else {
      showToast(texts.deauthorizationError, { error: true });
    }
    setIsLoading(false);
  };

  const stripeAccountUnlinked = coachHistory.find((h) => h.type === HistoryEventType.STRIPE_DEAUTHORIZED);

  const renderStripeAction = () => {
    if (stripeAccountUnlinked) {
      return (
        <TextBlock>
          {format(texts.stripeAccountUnlinkedBy, {
            userName: stripeAccountUnlinked.actionedByUserName,
          })}
        </TextBlock>
      );
    }

    if (onboardingDoc.coachHasStripeAccountId) {
      return (
        <Tooltip title={texts.unlinkStripeAcc} placement="right-start">
          <BtnContainer>
            <Button
              variant="contained"
              size="small"
              disabled={isLoading}
              onClick={handleDeauthorization}
              component={MuiButton}
            >
              {isLoading ? texts.unlinkActionLoading : texts.unlinkAction}
            </Button>
          </BtnContainer>
        </Tooltip>
      );
    }

    return <TextBlock>{texts.stripeAccountUnavailable}</TextBlock>;
  };

  return (
    <Container>
      <div>{texts.title}</div>
      {renderStripeAction()}
    </Container>
  );
};

StripeDeauthorization.propTypes = {
  coachDoc: PropTypes.object.isRequired,
  onboardingDoc: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  coachHistory: PropTypes.array.isRequired,
};

export default StripeDeauthorization;
