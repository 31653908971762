import styled from 'styled-components';
import LoadingPage from '../../../../../../components/LoadingPage';
import { LoadingButton } from '../../../../../../components/Button';
import colors from '../../../../../../styles/colors';

const StyledLoadingPage = styled(LoadingPage)`
  height: 100px;
`;

const StyledButton = styled(LoadingButton)`
  width: fit-content;
  margin-top: 10px;
  padding: 5px 12px;
  background: ${colors.primary1};
  color: ${colors.base.secondary2};
  border-radius: 73px;
  font-weight: 600;
  font-size: 12px;
  text-transform: none;

  &:hover {
    background-color: ${colors.primary2};
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  padding: 20px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Info = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const Note = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid ${colors.primary1};
  border-radius: 10px;
`;

export {
  StyledLoadingPage,
  StyledButton,
  Container,
  InfoContainer,
  Info,
  Note,
};
