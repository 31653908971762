import { getFirebase } from 'firestorter';
import { firestorePaths } from '../../utils/paths';
import BaseDocument from '../../Model/BaseDocument';

import AppCustomHabit from './AppCustomHabit';
import AppCustomCheckin from './AppCustomCheckin';

class AppCustomization extends BaseDocument {
  constructor(id, opts) {
    super(`${firestorePaths.APP_CUSTOMIZATION}/${id}`, opts);
  }

  get texts() {
    return this.data.texts || {};
  }

  get appIconName() {
    return this.data.appIconName;
  }

  get webAppIconRef() {
    return this.data.webAppIconRef;
  }

  get theme() {
    return this.data.theme || {};
  }

  get baseTheme() {
    return this.theme.baseTheme;
  }

  get primaryColor() {
    return this.theme.colors?.base?.primary || '';
  }

  get baseHabit() {
    return this.data.baseHabit;
  }

  get baseCheckin() {
    return this.data.baseCheckin;
  }

  get welcomeMessage() {
    return this.data.welcomeMessage || '';
  }

  get welcomeMessageAttachments() {
    return this.data.welcomeMessageAttachments || [];
  }

  get coach() {
    return this.data.coach;
  }

  async getBaseHabitDoc() {
    if (this.baseHabit) {
      return AppCustomHabit.getById(this.baseHabit);
    }
    return null;
  }

  async getBaseCheckinDoc() {
    if (this.baseCheckin) {
      return AppCustomCheckin.getById(this.baseCheckin);
    }
    return null;
  }

  setBaseHabitDoc(baseHabit) {
    this.updateFields({
      baseHabit,
    });
  }

  setBaseCheckin(baseCheckin) {
    this.updateFields({
      baseCheckin,
    });
  }

  async setBaseTheme(baseTheme, lastUpdatedByUserName) {
    return this.updateFields({
      'theme.baseTheme': baseTheme,
      lastUpdatedByUserName,
    });
  }

  async setPrimaryColor(primaryColor, lastUpdatedByUserName) {
    return this.updateFields({
      'theme.colors.base.primary': primaryColor,
      lastUpdatedByUserName,
    });
  }

  async setWelcomeMessage(message) {
    await this.updateFields({
      welcomeMessage: message || getFirebase().firestore.FieldValue.delete(),
    });
  }

  async setWelcomeMessageAttachments(attachments) {
    await this.updateFields({
      welcomeMessageAttachments: attachments || getFirebase().firestore.FieldValue.delete(),
    });
  }

  static getAppCustomizationByCoach = async (coachId) => {
    const appCustomizationDoc = new AppCustomization(coachId);
    await appCustomizationDoc.init();
    return appCustomizationDoc.exists ? appCustomizationDoc : null;
  };
}

export default AppCustomization;
