import styled from 'styled-components';

const QuestionContainer = styled.div`
  margin: 0 0 15px;
  font-size: 18px;
`;

const Question = styled.h6`
  font-size: 1em;
  margin: 0 0 5px;
  font-weight: 600;
`;

const Answer = styled.p`
  font-size: 0.8em;
  margin: 0;
  white-space: pre-wrap;
`;

export {
  QuestionContainer,
  Question,
  Answer,
};
