import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';

import ClientTaggingModal from './components/ClientTaggingModal';

import {
  StyledRoundedButton,
  StyledAddIcon,
  StyledEditIcon,
} from './styles';

const ManageClientTags = ({
  selectedClientList,
  assignedTags,
  buttonLabel,
  editButton,
}) => {
  const [showTagsModal, setShowTagsModal] = useState(false);

  return (
    <>
      <StyledRoundedButton onClick={() => setShowTagsModal(true)}>
        {!editButton ? <StyledAddIcon /> : <StyledEditIcon />}
        {buttonLabel}
      </StyledRoundedButton>
      {showTagsModal && (
        <ClientTaggingModal
          selectedClients={selectedClientList}
          showModal={showTagsModal}
          onClose={() => setShowTagsModal(false)}
          assignedTagsList={assignedTags}
        />
      )}
    </>
  );
};

ManageClientTags.propTypes = {
  selectedClientList: PropTypes.array.isRequired,
  assignedTags: PropTypes.array,
  buttonLabel: PropTypes.string.isRequired,
  editButton: PropTypes.bool,
};

ManageClientTags.defaultProps = {
  assignedTags: [],
  editButton: false,
};

export default ManageClientTags;
