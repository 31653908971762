import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledModal,
  Title,
  ActionButton,
  CancelButton,
  WarningIcon,
  ActionsContainer,
} from './styles';

import texts from './texts.json';

const ArchiveModal = ({
  isOpen,
  mealPlanDoc,
  onDidDismiss,
}) => {
  const archiveAction = async () => {
    await (mealPlanDoc.isArchived ? mealPlanDoc.unarchive() : mealPlanDoc.archive());
    onDidDismiss();
  };

  return (
    <StyledModal isOpen={isOpen} onDidDismiss={onDidDismiss}>
      <WarningIcon />
      <Title>{!mealPlanDoc.isArchived ? texts.title.archive : texts.title.unarchive}</Title>
      <ActionsContainer>
        <ActionButton
          onClick={archiveAction}
        >
          {!mealPlanDoc.isArchived ? texts.buttons.archive : texts.buttons.unarchive}
        </ActionButton>
        <CancelButton onClick={onDidDismiss}>{texts.buttons.cancel}</CancelButton>
      </ActionsContainer>
    </StyledModal>
  );
};

ArchiveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  mealPlanDoc: PropTypes.object.isRequired,
  onDidDismiss: PropTypes.func.isRequired,
};

export default ArchiveModal;
