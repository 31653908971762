import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';

import ManageClientTags from '../../../../../../components/ManageClientTags';
import { InfoTag } from '../../../../../../../components/Tags';
import {
  InfoContainer,
  StyledHeaderRow,
  StyledTitleContainer,
  StyledSubTitle,
} from '../../styles';
import {
  TagsSection,
  ChipContainer,
} from './styles';
import texts from './texts.json';

const ClientTagsSection = ({ userId, customTags }) => (
  <>
    <Divider flexItem />
    <StyledHeaderRow>
      <StyledTitleContainer>
        {texts.clientTags}
      </StyledTitleContainer>
      <ManageClientTags
        selectedClientList={[userId]}
        assignedTags={customTags}
        buttonLabel={texts.edit}
        editButton
      />
    </StyledHeaderRow>
    <InfoContainer>
      <TagsSection>
        {customTags.length !== 0 ? (
          <ChipContainer>
            {customTags.map((tag) => (
              <InfoTag key={tag}>{tag}</InfoTag>
            ))}
          </ChipContainer>
        ) : (
          <StyledSubTitle>
            {texts.noTags}
          </StyledSubTitle>
        )}
      </TagsSection>
    </InfoContainer>
  </>
);

ClientTagsSection.propTypes = {
  userId: PropTypes.string.isRequired,
  customTags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ClientTagsSection;
