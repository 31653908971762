import BillingDetails from './components/BillingDetails';
import CheckInDetailsSlider from './components/CheckInDetailsSlider';
import FoodLog from './components/FoodLog';
import ClientInfo from './components/ClientInfo';

import {
  StyledBillingIcon,
  StyledCheckinIcon,
  StyledForkIcon,
  StyledUserIcon,
} from './styles';
import texts from './texts.json';

const clientInfoTabs = {
  CHECKIN_ACTIVITY: 'CHECKIN_ACTIVITY',
  BILLING_DETAILS: 'BILLING_DETAILS',
  FOOD_LOG: 'FOOD_LOG',
  CLIENT_INFO: 'CLIENT_INFO',
};

const clientInfoTabsConfig = {
  [clientInfoTabs.CHECKIN_ACTIVITY]: {
    label: texts.tabLabels[clientInfoTabs.CHECKIN_ACTIVITY],
    icon: StyledCheckinIcon,
    component: CheckInDetailsSlider,
  },
  [clientInfoTabs.BILLING_DETAILS]: {
    label: texts.tabLabels[clientInfoTabs.BILLING_DETAILS],
    icon: StyledBillingIcon,
    component: BillingDetails,
  },
  [clientInfoTabs.FOOD_LOG]: {
    label: texts.tabLabels[clientInfoTabs.FOOD_LOG],
    icon: StyledForkIcon,
    component: FoodLog,
  },
  [clientInfoTabs.CLIENT_INFO]: {
    label: texts.tabLabels[clientInfoTabs.CLIENT_INFO],
    icon: StyledUserIcon,
    component: ClientInfo,
  },
};

export {
  clientInfoTabs,
  clientInfoTabsConfig,
};
