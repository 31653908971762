import styled from 'styled-components';
import { Typography } from '@mui/material';

import colors from '../../../styles/colors';
import DialogModal from '../../DialogModal';

const RoundedModal = styled(DialogModal)`
  .MuiDialogContent-root, .MuiPaper-root {
    border-radius: 8px;
  }
`;

const ModalContentWrapper = styled.div`
  outline: 0;
  height: fit-content;
  width: 540px;
  background-color: ${colors.shades.secondary5};
  border-radius: 8px;
  border: 1px solid ${colors.shades.secondary7};
  position: relative;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
  margin-top: 20px;
  padding: 10px 50px 10px 50px;
  border-top: 1px solid ${colors.shades.secondary6};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 66px 50px 14px 50px;
  color: ${colors.shades.secondary3};
  border-bottom: 1px solid ${colors.shades.secondary6};
`;

const Title = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
`;

const Description = styled(Typography)`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: ${colors.shades.gamma2};
  margin-top: 5px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${(props) => (props.$noPadding ? '0' : '30px 50px 0px 50px')};
  color: ${colors.shades.secondary3};
`;

export {
  Title,
  Description,
  ActionsContainer,
  RoundedModal,
  ModalContentWrapper,
  HeaderContainer,
  BodyContainer,
};
