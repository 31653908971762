import styled, { css } from 'styled-components';
import {
  Typography,
  Button,
  TableCell,
  TableContainer,
} from '@mui/material';
import { ModeEdit, Pause, PlayArrow } from '@mui/icons-material';

import { ReactComponent as CancelSubscriptionIcon } from '../../../../../../assets/icons/cancel-subscription.svg';
import { ReactComponent as ReonboardIcon } from '../../../../../../assets/icons/userIcon.svg';
import colors from '../../../../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 5px;
  flex: 1;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 10px;
  justify-content: flex-end;
`;

const StyledText = styled(Typography)`
  font-size: 12px;
  color: ${colors.shades.primary1};
  padding: 20px;
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  background: ${colors.shades.gamma2};
  color: ${colors.base.alpha};
  text-transform: none;
  font-size: 12px;
  margin-left: 10px;
  &:hover {
    background: ${colors.base.beta};
  }
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const StyledTableCell = styled(TableCell)`
  font-size: 12px;
  padding: 12px;
  text-transform: capitalize;
`;

const StyledTableContainer = styled(TableContainer)`
  padding: 0 8px;
`;

const Title = styled(Typography)`
  margin: 10px 0 10px 8px;
  border-bottom: 1px solid ${colors.shades.gamma1};
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
`;

const iconStyle = css`
  height: 15px;
  width: 15px;
  margin: 4px;
  > path {
    stroke: ${colors.base.alpha};
  }
`;

const StyledCancelSubscriptionIcon = styled(CancelSubscriptionIcon)`
  ${iconStyle};
`;

const EditSubscriptionIcon = styled(ModeEdit)`
  ${iconStyle};
`;

const StyledLink = styled.a`
  color: ${colors.base.beta};
`;

const StyledPauseSubscriptionIcon = styled(Pause)`
  ${iconStyle};
`;

const StyledUnpauseSubscriptionIcon = styled(PlayArrow)`
  ${iconStyle};
`;

const StyledReonboardIcon = styled(ReonboardIcon)`
  ${iconStyle};
`;

export {
  Container,
  HeaderContainer,
  StyledText,
  StyledButton,
  DataContainer,
  StyledTableCell,
  StyledTableContainer,
  Title,
  StyledCancelSubscriptionIcon,
  StyledLink,
  EditSubscriptionIcon,
  StyledPauseSubscriptionIcon,
  StyledUnpauseSubscriptionIcon,
  StyledReonboardIcon,
};
