import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import Coach from '../../../../../../Model/Coach';
import CoachOnboarding from '../../../../../Model/CoachOnboarding';
import { isCRXAdmin } from '../../../../../../utils/postHog';
import OffboardingForm from './OffboardingForm';

import {
  Container, InfoContainer, StyledLoadingPage, Note,
} from './styles';

import texts from './texts.json';
import OffboardingChecklist from './OffboardingChecklist';

const CoachOffboarding = ({ onboardingDoc: onboardingRow }) => {
  const { coach: coachId } = onboardingRow;
  const [isLoading, setIsLoading] = useState(false);
  const [coachDoc, setCoachDoc] = useState(null);
  const [onboardingDoc, setOnboardingDoc] = useState(null);

  const isComponentMountedRef = useComponentMounted();

  const fetchCoachDocs = useCallback(async () => {
    if (!coachId) return;

    setIsLoading(true);
    const coach = await Coach.getCoachById(coachId);
    const onboarding = await CoachOnboarding.getCoachOnboardingByCoachId(coachId);
    if (isComponentMountedRef.current) {
      setCoachDoc(coach);
      setOnboardingDoc(onboarding);
    }

    setIsLoading(false);
  }, [coachId, isComponentMountedRef]);

  useEffect(() => {
    fetchCoachDocs();
  }, [coachId, fetchCoachDocs, isComponentMountedRef]);

  if (isLoading) {
    return <StyledLoadingPage />;
  }

  if (!coachDoc || !coachId) {
    return (
      <Container>
        <InfoContainer>{texts.noCoachId}</InfoContainer>
      </Container>
    );
  }

  if (!isCRXAdmin()) {
    return (
      <Note>
        <p>{texts.onlyForCRXUsers}</p>
      </Note>
    );
  }

  return (
    <Container>
      {coachDoc.isActive && (
        <OffboardingForm coachId={coachId} onboardingId={onboardingDoc.id} onDeactivate={fetchCoachDocs} />
      )}
      {!coachDoc.isActive && (
      <OffboardingChecklist
        coachDoc={coachDoc}
        onboardingDoc={onboardingDoc}
        refetch={fetchCoachDocs}
      />
      )}
    </Container>
  );
};

CoachOffboarding.propTypes = {
  onboardingDoc: PropTypes.object.isRequired,
};

export default CoachOffboarding;
