import moment from 'moment';
import { getFirestore } from 'firestorter';

import { firestorePaths } from '../../../../../../utils/paths';
import { BroadcastMessageRequestStatus } from '../../../../../../Model/BroadcastMessageRequest';

const scheduleSingleMessage = async (data, processedAttachments = []) => {
  const db = getFirestore();

  const messageRequestRef = db.collection(firestorePaths.CHAT_MESSAGE_REQUEST).doc();
  await messageRequestRef.set({
    ...data,
    attachments: processedAttachments,
    createdAt: moment.utc().toDate(),
    status: BroadcastMessageRequestStatus.CREATED,
  });
};

export {
  scheduleSingleMessage,
};
