import styled from 'styled-components';
import { IonLabel } from '@ionic/react';
import {
  TextField,
  Tabs,
  Tab,
  Chip,
  Modal,
  IconButton,
  Typography,
  Alert,
} from '@mui/material';

import QuickSearchToolbar from '../../../../components/QuickSearchToolbar';
import { ReactComponent as ChatIcon } from '../../../../../assets/icons/chat-icon.svg';

import { ReactComponent as CloseButtonIcon } from '../../assets/icon/close-thread-icon.svg';
import colors from '../../../../../styles/colors';
import Button from '../../../../../components/Button';

const Container = styled.div`
  min-height: 60vh;
  min-width: 60vw;
  position: relative;
  border: 1px solid ${colors.shades.secondary4};
  border-radius: 5px;
  background: ${colors.shades.secondary5};
`;

const RecipientActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const DateSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
  border: 1px solid ${colors.shades.secondary4};
  border-radius: 5px;
  padding: 8px;
  margin-top: 8px;
`;

const DateSelector = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabContainer = styled.div`
  padding: 0 4px;
`;

const TitleContainer = styled.div`
  font-size: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

const ScheduleButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  margin: 8px 0;
`;

const StyledHeader = styled.div`
  min-height: 60px;
  padding: 50px 52px 21px;
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  color: ${colors.shades.secondary3};
  border-bottom: 1px solid ${colors.shades.secondary4};
`;

const SendBroadcastButton = styled(Button)`
  border-radius: 5px;
  background: ${({ light }) => (light ? colors.shades.secondary4 : colors.shades.beta2)};
  cursor: pointer;
  color: ${({ light }) => (light ? colors.base.beta : colors.base.alpha)};
  column-gap: 8px;
  text-transform: none;
  height: fit-content;
  padding: 10px 14px;
  font-size: 14px;
  line-height: 16px;
  width: fit-content;
`;

const StyledChatIcon = styled(ChatIcon)`
  width: 20px;
  height: 20px;
  > path {
    stroke: ${({ light }) => (light ? colors.base.secondary2 : colors.base.primary)};
    fill: ${({ light }) => (light ? colors.base.secondary3 : colors.shades.beta2)};
  }
`;

const StyledLabel = styled(IonLabel)`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 10px;
  margin-bottom: 4px;
  color: ${colors.base.primary};
`;

const CloseButton = styled(CloseButtonIcon)`
  height: 50px;
  position: absolute;
  right: -20px;
  top: -20px;
  z-index: 1;
`;

const ModalContent = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  background: ${colors.shades.secondary5};
`;

const SelectedRecipientContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTextField = styled(TextField)`
  background: ${colors.base.alpha};

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    border: 1px solid ${colors.shades.secondary4};
  }
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid ${colors.shades.secondary4};
  background: ${colors.base.alpha};
  box-sizing: border-box;

  .MuiTabs-indicator {
    background-color: ${colors.shades.secondary3};
  }
`;

const StyledTab = styled(Tab)`
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  padding: 0;
  text-transform: none;
  font-weight: 400;
  color: ${colors.shades.secondary3};
  &.Mui-selected {
    color: ${colors.shades.secondary3};
  }
`;

const SearchBar = styled(QuickSearchToolbar)`
  width: unset;
  background: white;
`;

const StyledListContainer = styled.div`
  max-height: 230px;
  overflow-y: scroll;
  border: 1px solid ${colors.shades.secondary4};
  border-radius: 5px;
`;

const StyledIonLabel = styled(IonLabel)`
  color: ${colors.base.beta};
  margin-left: auto;
  text-transform: uppercase;
  cursor: pointer;
`;

const StyledChip = styled(Chip)`
  background-color: ${colors.shades.primary4};
  color: #333;
  border: 1px solid ${colors.base.primary};
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 10px 0 auto;
`;

const StyledText = styled(Typography)`
  font-size: 12px;
  color: ${colors.shades.primary1};
  padding: 10px;
  font-weight: bold;
`;

const StyledButton = styled(IconButton)`
  border-radius: 50%;
  border: 1px solid;
  color: ${colors.shades.gamma2};
  margin: 10px;
  width: 20px;
  height: 20px;
  font-size: 14px;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: ${colors.base.alpha};
`;

const StyledAlert = styled(Alert)`
  width: 600px;
`;

export {
  Container,
  CloseButton,
  DateSelectorContainer,
  DateSelector,
  TitleContainer,
  ModalContent,
  TabContainer,
  RecipientActionsContainer,
  SearchBar,
  ScheduleButtonsContainer,
  SelectedRecipientContainer,
  SendBroadcastButton,
  StyledModal,
  StyledChip,
  StyledTextField,
  StyledTabs,
  StyledTab,
  StyledLabel,
  StyledHeader,
  StyledChatIcon,
  StyledListContainer,
  StyledIonLabel,
  NavigationContainer,
  StyledButton,
  StyledText,
  MenuContainer,
  StyledAlert,
};
