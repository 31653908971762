import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import copy from 'clipboard-copy';

import useToast from '../../../hooks/useToast';

import useComponentMounted from '../../../../hooks/useComponentMounted';
import {
  Container,
  ActionButton,
} from './styles';

import texts from './texts.json';

const ActionContainer = ({
  row,
  navigateToForm,
  handleFormDuplicateClick,
  setIsLoading,
}) => {
  const { showToast } = useToast();
  const [isArchived, setIsArchived] = useState(row.isArchived);
  const isComponentMountedRef = useComponentMounted();

  const handleArchiveButtonClick = useCallback(async () => {
    setIsLoading(true);
    if (row.isArchived) {
      await row.unarchive();
      setIsLoading(false);
    } else {
      await row.archive();
      setIsLoading(false);
    }
    if (isComponentMountedRef.current) {
      setIsArchived((prev) => !prev);
    }
  }, [isComponentMountedRef, row, setIsLoading]);

  const copyFormEmbedCode = (coachId, formId) => {
    // Create the HTML string to be inserted to webflow
    const htmlString = `<div id="form" data-coachId="${coachId}" data-formId="${formId}"></div>`;
    copy(htmlString);
    showToast(texts.copied);
  };

  return (
    <Container>
      <ActionButton onClick={() => navigateToForm(row.id)}>
        {texts.edit}
      </ActionButton>
      <ActionButton onClick={() => handleFormDuplicateClick(row.id)}>
        {texts.duplicate}
      </ActionButton>
      <ActionButton onClick={() => copyFormEmbedCode(row.coachId, row.id)}>
        {texts.copy}
      </ActionButton>
      <ActionButton onClick={handleArchiveButtonClick}>
        {isArchived ? texts.unarchive : texts.archive}
      </ActionButton>
    </Container>
  );
};

ActionContainer.propTypes = {
  row: PropTypes.object.isRequired,
  navigateToForm: PropTypes.func.isRequired,
  handleFormDuplicateClick: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default ActionContainer;
