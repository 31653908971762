import styled from 'styled-components';

import colors from '../../../../../../styles/colors';

const TitledContainer = styled.div`
  border: 1px solid ${colors.shades.secondary7};
  border-radius: 5px;
`;

const TitledContainerHead = styled.div`
  background: ${colors.shades.secondary5};
  border-bottom: 1px solid ${colors.shades.secondary7};
  color: ${colors.shades.secondary8};
  padding: 15px 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const TitledContainerHeadTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${colors.shades.primary1};
`;

const TitledContainerHeadDescription = styled.div`
  color: ${colors.shades.secondary8};
  border: 1px solid ${colors.shades.secondary7};
  border-radius: 4px;
  font-size: 8px;
  font-weight: 500;
  line-height: 9px;
  letter-spacing: -0.01em;
  padding: 5px;
`;

const TitledContainerBody = styled.div`
  background: ${colors.base.alpha};
  padding: 35px 22px 30px 22px;
  max-width: 500px;
`;

const TitledContainerFoot = styled.div`
  background: ${colors.shades.secondary5};
  border-top: 1px solid ${colors.shades.secondary7};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 18px;
`;

const TitledContainerFootEditor = styled.div`
  border-top: 1px solid ${colors.shades.secondary7};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const FootActionButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
  padding: 21px 50px 21px 18px;
`;

const MealPlanTemplateTypeSelector = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 18px;
`;

const AnalysisWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 10px;
`;

const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
`;

const MealPlanTemplateDisclaimer = styled.div`
  color: ${colors.shades.secondary8};
  margin-bottom: 18px;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
`;

const MealPlanTemplateExcluder = styled.div`
  color: ${colors.shades.secondary8};
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
`;

const InlineSelectRow = styled.div`
  color: ${colors.shades.secondary8};
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0;
  text-align: left;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

const Label = styled.div`
  font-size: 9px;
  font-weight: 500;
  line-height: 9px;
  letter-spacing: 0.07em;
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0;
  column-gap: 10px;
`;

export {
  TitledContainer,
  TitledContainerHead,
  TitledContainerHeadTitle,
  TitledContainerHeadDescription,
  TitledContainerBody,
  TitledContainerFoot,
  TitledContainerFootEditor,
  FootActionButton,
  MealPlanTemplateTypeSelector,
  AnalysisWrapper,
  ErrorsContainer,
  MealPlanTemplateDisclaimer,
  MealPlanTemplateExcluder,
  InlineSelectRow,
  ItemContainer,
  Label,
  ItemList,
};
