import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import * as Sentry from '@sentry/browser';
import format from 'string-template';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import {
  IconButton,
  Button,
  Tooltip,
} from '@mui/material';
import {
  DatePicker,
  TimePicker,
} from '@mui/lab';

import {
  DateFormat,
  isToday,
} from '../../../../../../../utils/date';
import BroadcastMessageRequest from '../../../../../../../Model/BroadcastMessageRequest';
import useToast from '../../../../../../hooks/useToast';
import useStorage, { StorageProcessState } from '../../../../../../../hooks/useStorage';
import { MAX_FILE_UPLOAD_SIZE, MAX_FILE_UPLOAD_SIZE_MB } from '../../../../util/fileUpload';
import { pathPlaceholder, storagePaths } from '../../../../../../../utils/firebasePaths';
import UserContext from '../../../../../../../context/UserContext';
import useComponentMounted from '../../../../../../../hooks/useComponentMounted';
import FileAttachmentContainer from '../../../../../../components/AttachmentsContainer';

import { StyledTextField } from '../../styles';
import {
  ItemContainer,
  MessageDate,
  MessageText,
  ActionsContainer,
  InfoContainer,
  EditContainer,
  InfoText,
} from './styles';
import texts from './texts.json';

const ItemStatus = {
  INITIAL: 'INITIAL',
  EDITING: 'EDITING',
  DELETING: 'DELETING',
};

const ScheduledMessageItem = ({
  broadcastMessageRequestDoc,
}) => {
  const {
    message,
    sendAt,
    attachmentsRefs,
  } = broadcastMessageRequestDoc;

  // remove uuid from file name
  const sanitizeFileName = (encodedName) => encodedName
    .replace(/-[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}/i, '');

  const [sendDate, setSendDate] = useState(moment(sendAt).local());
  const [sendTime, setSendTime] = useState(moment(sendAt).local());
  const [messageText, setMessageText] = useState(message);
  const [itemStatus, setItemStatus] = useState(ItemStatus.INITIAL);
  const [attachments, setAttachments] = useState(attachmentsRefs.map((ref) => ({
    name: sanitizeFileName(ref.split('/').pop()),
    ref,
    isUploaded: true,
  })));
  const [isUploading, setIsUploading] = useState(false);
  const { showToast } = useToast();
  const { userId } = useContext(UserContext);
  const { uploadAttachments, deleteAttachments } = useStorage();
  const isComponentMountedRef = useComponentMounted();

  const uploadMessageAttachments = useCallback(async () => {
    // adding already uploaded files to the array
    const fileAttachmentRefs = attachments.reduce((arr, current) => {
      if (current.isUploaded) {
        arr.push(current.ref);
      }
      return arr;
    }, []);

    // filtering files that are not uploaded yet
    const filesToUpload = attachments.filter((file) => !file.isUploaded);
    const storagePath = format(storagePaths.COACH_BROADCAST_MESSAGES_ATTACHMENTS, {
      [pathPlaceholder.COACH_ID]: userId,
    });

    setIsUploading(true);
    try {
      const result = await uploadAttachments(filesToUpload, storagePath);

      let storageTaskFailed = false;
      const successfulUploads = [];
      result.forEach((res) => {
        if (res.state === StorageProcessState.SUCCESS) {
          successfulUploads.push(res.fileRef);
        } else {
          storageTaskFailed = true;
        }
      });
      if (storageTaskFailed) {
        // If some upload fails, we should stop the saving process
        // and delete the files that were uploaded (if there is any)
        if (fileAttachmentRefs.length) {
          const filesToDelete = successfulUploads.map((ref) => ({ storagePath: ref }));
          await deleteAttachments(filesToDelete);
        }
      }
      fileAttachmentRefs.push(...successfulUploads);
      if (isComponentMountedRef.current) {
        setIsUploading(false);
      }
      return { fileAttachmentRefs, storageTaskFailed };
    } catch (error) {
      if (isComponentMountedRef.current) {
        setIsUploading(false);
      }
      Sentry.captureException(error, {
        extra: {
          coachId: userId,
        },
      });
      // Returning this to handle error messages from the caller function
      return { storageTaskFailed: true };
    }
  }, [
    attachments,
    userId,
    uploadAttachments,
    deleteAttachments,
    isComponentMountedRef,
  ]);

  const getAttachmentRefs = useCallback(async () => {
    let fileAttachments = [];
    let error = false;
    if (attachments.length) {
      const { storageTaskFailed, fileAttachmentRefs } = await uploadMessageAttachments();
      if (storageTaskFailed) {
        error = true;
      }
      fileAttachments = fileAttachmentRefs;
    }
    return {
      fileAttachments,
      error,
    };
  }, [
    attachments,
    uploadMessageAttachments,
  ]);

  const onSave = useCallback(async () => {
    const { fileAttachments, error } = await getAttachmentRefs();
    if (error) {
      showToast(texts.uploadError, { error: true });
      return;
    }
    // Convert to UTC after adding date and time
    const newSendAt = `${sendDate.format(DateFormat.DEFAULT_DATE_FORMAT)} ${sendTime.format('HH:mm')}`;
    await broadcastMessageRequestDoc.update({
      message: messageText,
      sendAt: newSendAt,
      attachmentsRefs: fileAttachments,
    });

    setAttachments(fileAttachments.map((ref) => ({
      name: sanitizeFileName(ref.split('/').pop()),
      ref,
      isUploaded: true,
    })));
    setSendTime(moment(newSendAt).local());
    setSendDate(moment(newSendAt).local());
    setItemStatus(ItemStatus.INITIAL);
  }, [
    broadcastMessageRequestDoc,
    messageText,
    sendDate,
    sendTime,
    getAttachmentRefs,
    showToast,
  ]);

  const onDelete = useCallback(async () => {
    await broadcastMessageRequestDoc.delete();
    setItemStatus(ItemStatus.INITIAL);
  }, [
    broadcastMessageRequestDoc,
  ]);

  // If the selected date is today, then the user cannot select a time in the past
  const minTime = useMemo(() => (
    isToday(sendDate) ? moment().local() : null
  ), [
    sendDate,
  ]);

  const onFilesSelected = useCallback((event) => {
    const { files } = event.target;
    if (files.length) {
      const filesArray = [...files];
      const validFiles = filesArray.every((file) => file.size < MAX_FILE_UPLOAD_SIZE);
      if (validFiles) {
        const formattedFiles = filesArray.map((file) => {
          const lastDotIndex = file.name.lastIndexOf('.');
          return {
            dataUrl: URL.createObjectURL(file),
            format: file.name.slice(lastDotIndex + 1),
            fileName: file.name.slice(0, lastDotIndex),
            fileType: file.type,
            name: file.name,
            isUploaded: false,
          };
        });
        setAttachments((prev) => [...prev, ...formattedFiles]);
      } else {
        showToast(format(texts.fileSizeError, {
          maxFileSize: MAX_FILE_UPLOAD_SIZE_MB, // Bytes to MB
        }), { error: true });
      }
    }
  }, [
    showToast,
  ]);

  const onFileRemove = useCallback(async (index) => {
    const resultAttachments = [...attachments];
    resultAttachments.splice(index, 1);
    setAttachments(resultAttachments);
  }, [
    attachments,
  ]);

  const renderItemContent = useCallback(() => {
    switch (itemStatus) {
      case ItemStatus.EDITING:
        return (
          <EditContainer>
            <StyledTextField
              label={texts.message}
              value={messageText}
              fullWidth
              multiline
              rows={4}
              onChange={(e) => setMessageText(e.target.value)}
            />
            <DatePicker
              label={texts.selectDate}
              value={sendDate}
              onChange={(newValue) => setSendDate(newValue)}
              minDate={moment().local()}
              maxDate={moment().local().add(3, 'months')}
              renderInput={(params) => (
                <StyledTextField {...params} />
              )}
            />
            <TimePicker
              label={texts.selectTime}
              value={sendTime}
              onChange={(newValue) => setSendTime(newValue)}
              minTime={minTime}
              minutesStep={5}
              renderInput={(params) => (
                <StyledTextField {...params} />
              )}
            />
            <FileAttachmentContainer
              fileAttachments={attachments}
              onFileRemoved={onFileRemove}
              onFilesSelected={onFilesSelected}
              isUploading={isUploading}
            />
            <ActionsContainer>
              <Button
                onClick={onSave}
                color="success"
              >
                {texts.button.save}
              </Button>
            </ActionsContainer>
          </EditContainer>
        );
      case ItemStatus.DELETING:
        return (
          <InfoContainer>
            <InfoText>{texts.deleteConfirm}</InfoText>
          </InfoContainer>
        );
      case ItemStatus.INITIAL:
      default:
        return (
          <InfoContainer>
            <MessageDate>{moment(sendAt).format(DateFormat.DATE_MONTH_YEAR_TIME_SHORT_DISPLAY_FORMAT)}</MessageDate>
            <MessageText>{message}</MessageText>
            {attachmentsRefs.length !== 0 && (
              <Tooltip title={texts.tooltip.attachments}>
                <AttachFileIcon />
              </Tooltip>
            )}
          </InfoContainer>
        );
    }
  }, [
    itemStatus,
    message,
    messageText,
    onSave,
    minTime,
    sendAt,
    sendDate,
    sendTime,
    attachmentsRefs,
    attachments,
    onFileRemove,
    onFilesSelected,
    isUploading,
  ]);

  const renderActionButtons = useCallback(() => {
    switch (itemStatus) {
      case ItemStatus.EDITING:
        return <></>;
      case ItemStatus.DELETING:
        return (
          <ActionsContainer>
            <Button
              onClick={() => setItemStatus(ItemStatus.INITIAL)}
              color="info"
            >
              {texts.button.cancel}
            </Button>
            <Button
              onClick={onDelete}
              color="warning"
            >
              {texts.button.delete}
            </Button>
          </ActionsContainer>
        );
      case ItemStatus.INITIAL:
      default:
        return (
          <ActionsContainer>
            <Tooltip title={texts.tooltip.edit}>
              <IconButton
                onClick={() => setItemStatus(ItemStatus.EDITING)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={texts.tooltip.delete}>
              <IconButton
                onClick={() => setItemStatus(ItemStatus.DELETING)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </ActionsContainer>
        );
    }
  }, [
    itemStatus,
    onDelete,
  ]);

  return (
    <ItemContainer>
      {renderItemContent()}
      {renderActionButtons()}
    </ItemContainer>
  );
};

ScheduledMessageItem.propTypes = {
  broadcastMessageRequestDoc: PropTypes.instanceOf(BroadcastMessageRequest).isRequired,
};

export default observer(ScheduledMessageItem);
