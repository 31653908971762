import styled from 'styled-components';
import LoadingPage from '../../../../../../../components/LoadingPage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Heading = styled.div`
  font-weight: bold;
`;

const CheckList = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CheckListItem = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  gap: 20px;
`;

const StyledLoadingPage = styled(LoadingPage)`
  height: 100px;
`;

const TextBlock = styled.div`
  font-size: 14px;
`;

const BtnContainer = styled.div`
  width: fit-content;
`;

export {
  Container,
  Heading,
  CheckList,
  CheckListItem,
  StyledLoadingPage,
  TextBlock,
  BtnContainer,
};
