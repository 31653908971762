import React from 'react';
import PropTypes from 'prop-types';

import {
  Title,
  Description,
  ActionsContainer,
  RoundedModal,
  ModalContentWrapper,
  HeaderContainer,
  BodyContainer,
} from './styles';

const Modal = ({
  open,
  onClose,
  children,
  title,
  description,
  actionButtons,
  noPadding,
}) => (
  <RoundedModal
    open={open}
    onClose={onClose}
  >
    <ModalContentWrapper>
      <HeaderContainer>
        <Title>{title}</Title>
        {!!description && <Description>{description}</Description>}
      </HeaderContainer>
      <BodyContainer $noPadding={noPadding}>
        {children}
      </BodyContainer>
      {!!actionButtons && (
        <ActionsContainer>
          {actionButtons}
        </ActionsContainer>
      )}
    </ModalContentWrapper>
  </RoundedModal>
);

Modal.propTypes = {
  children: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  actionButtons: PropTypes.element,
  description: PropTypes.string,
  noPadding: PropTypes.bool,
};
Modal.defaultProps = {
  description: '',
  actionButtons: null,
  noPadding: false,
};
export default Modal;
