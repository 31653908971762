import styled from 'styled-components';
import { Typography } from '@mui/material';

import LoadingPage from '../../../../../../components/LoadingPage';
import LabelCheckbox from '../../../../../components/LabelCheckbox';

const StyledLoadingPage = styled(LoadingPage)`
  height: 100px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  row-gap: 15px;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCheckbox = styled(LabelCheckbox)`
  height: 35px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  row-gap: 10px;
`;

const ContentText = styled(Typography)`
  font-size: 12px;
`;

export {
  StyledLoadingPage,
  Container,
  SubContainer,
  StyledCheckbox,
  ContentContainer,
  ContentText,
};
