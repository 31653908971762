import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../../../styles/colors';

import {
  InfoWrapper,
  InfoLabel,
  InfoContent,
} from './styles';

const InfoItem = ({
  label,
  children,
  labelColor,
  contentColor,
}) => (
  <InfoWrapper>
    <InfoLabel color={labelColor}>{label}</InfoLabel>
    <InfoContent color={contentColor}>{children}</InfoContent>
  </InfoWrapper>
);

InfoItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  labelColor: PropTypes.string,
  contentColor: PropTypes.string,
};

InfoItem.defaultProps = {
  labelColor: colors.shades.secondary8,
  contentColor: colors.shades.beta1,
};

export default InfoItem;
