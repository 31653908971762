import React, {
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import useToolsNavigation from '../../../../hooks/useToolsNavigation';
import MealPlanContext, { withMealPlanContextReady } from '../../../../context/MealPlanContext';
import ChatButton from '../../../../components/ChatButton';
import ClientQuickInfoButton from '../../../../components/ClientQuickInfoButton';
import ProfileDetailsSection from './components/ProfileDetailsSection';
import ClientTagsSection from './components/ClientTagsSection';
import SubscriptionDetailsSection from './components/SubscriptionDetailsSection';
import {
  Container,
  Name,
  StyledAvatar,
  ButtonContainer,
  StyledButton,
  StyledMealPlanIcon,
  StyledMobileIcon,
} from './styles';
import texts from './texts.json';

const UserInfoSection = ({
  userDoc,
  showMobileViewModal,
}) => {
  const {
    name,
    avatarUrl,
    customTags,
  } = userDoc;

  const {
    params: {
      clientId,
    },
  } = useRouteMatch();

  const { navigateToClientMealPlanPage } = useToolsNavigation();
  const { mealPlanAssignments } = useContext(MealPlanContext);

  const isMealPlanAvailable = useMemo(() => (
    !!mealPlanAssignments?.find((assignment) => assignment.id === clientId)
  ), [
    clientId,
    mealPlanAssignments,
  ]);

  return (
    <Container>
      <StyledAvatar src={avatarUrl} />
      <Name>{name}</Name>
      <ButtonContainer>
        <ChatButton
          userId={userDoc.id}
          text={texts.chatLabel}
          popperPlacement="right-start"
          textIconButton
        />
        <ClientQuickInfoButton userId={userDoc.id} textIconButton />
        {isMealPlanAvailable && (
          <StyledButton
            onClick={() => navigateToClientMealPlanPage(clientId)}
          >
            <StyledMealPlanIcon />
            {texts.mealPlan}
          </StyledButton>
        )}
        <StyledButton
          onClick={() => showMobileViewModal(true)}
        >
          <StyledMobileIcon />
          {texts.AppView}
        </StyledButton>
      </ButtonContainer>
      <ProfileDetailsSection userDoc={userDoc} />
      <ClientTagsSection userId={userDoc.id} customTags={customTags} />
      <SubscriptionDetailsSection userDoc={userDoc} />
    </Container>
  );
};

UserInfoSection.propTypes = {
  userDoc: PropTypes.object.isRequired,
  showMobileViewModal: PropTypes.func.isRequired,
};

export default compose(
  withMealPlanContextReady,
  observer,
)(UserInfoSection);
