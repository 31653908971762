import React, {
  useState, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton, Tooltip } from '@mui/material';
import format from 'string-template';

import useToast from '../../../../../../hooks/useToast';
import Button from '../../../../../../../components/Button/Button';
import { HistoryEventType } from '../../../../../../Model/CoachHistory';
import Form from '../../../../../../Model/Form';
import LoggedInUserContext from '../../../../../../../context/LoggedInUserContext';
import { OffboardingChecklistEnum } from '../OffboardingChecklist/utils';
import { BtnContainer, Container, TextBlock } from './styles';
import texts from './texts.json';

const LeadFormArchiver = ({
  coachDoc, coachHistory, loadCoachHistory, refetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToast();
  const {
    userDoc: {
      name: authUserName,
    },
    userId: authUserId,
  } = useContext(LoggedInUserContext);

  const handleLeadFormArchive = async (coachId) => {
    try {
      setIsLoading(true);
      await Form.archiveLeadForms(
        coachId, authUserId, authUserName, OffboardingChecklistEnum.LEAD_FORM_ARCHIVED,
      );
      await loadCoachHistory();
      refetch();
      showToast(texts.archiveSuccess);
      setIsLoading(false);
    } catch (error) {
      showToast(error.message || texts.archiveError, { error: true });
      setIsLoading(false);
    }
  };

  const leadFormArchived = coachHistory.find((h) => h.type === HistoryEventType.LEAD_FORM_ARCHIVED);

  const renderLeadFormAction = () => {
    if (leadFormArchived) {
      return (
        <TextBlock>
          {format(texts.leadFormArchivedBy, {
            userName: leadFormArchived.actionedByUserName,
          })}
        </TextBlock>
      );
    }
    return (
      <Tooltip title={texts.archiveLeadForm} placement="right-start">
        <BtnContainer>
          <Button
            variant="contained"
            size="small"
            disabled={isLoading}
            onClick={() => handleLeadFormArchive(coachDoc.id)}
            component={MuiButton}
          >
            {isLoading ? texts.archiveActionLoading : texts.archiveAction}
          </Button>
        </BtnContainer>
      </Tooltip>

    );
  };

  return (
    <Container>
      <div>{texts.title}</div>
      {renderLeadFormAction()}
    </Container>
  );
};

LeadFormArchiver.propTypes = {
  coachDoc: PropTypes.object.isRequired,
  coachHistory: PropTypes.array.isRequired,
  loadCoachHistory: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default LeadFormArchiver;
