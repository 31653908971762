import React, { useContext } from 'react';
import FirebaseContext from '../../../context/FirebaseContext';
import UserContext from '../../../context/UserContext';
import useSessionStore from '../../../hooks/useSessionStore';
import useToolsNavigation from '../../hooks/useToolsNavigation';
import ClientQuickInfoButton from '../../components/ClientQuickInfoButton';
import useLogger from '../../../hooks/useLogger';
import WebMediaRecorder from './WebMediaRecorder';

const withChatProps = (Component) => (props) => {
  const { firebase } = useContext(FirebaseContext);
  const { userId: chatUserId } = useContext(UserContext);
  const sessionStore = useSessionStore();
  const { navigateToClientPage } = useToolsNavigation();
  const webMediaRecorder = new WebMediaRecorder();
  const { logEvent } = useLogger();

  const renderHeaderExtras = (clientId) => (
    <ClientQuickInfoButton userId={clientId} />
  );

  const chatProps = {
    firebase,
    sessionStore,
    chatUserId,
    mediaRecorder: webMediaRecorder,
    navigateToClientPage,
    renderHeaderExtras,
    logEvent,
    parentApp: 'web',
  };

  return <Component {...props} chatProps={chatProps} />;
};

export default withChatProps;
