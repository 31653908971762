import React, { useContext, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { Divider } from '@mui/material';
import format from 'string-template';
import * as Sentry from '@sentry/browser';

import {
  EditOutlined,
} from '@mui/icons-material';

import Button, { LoadingButton, variants } from '../../../../../components/Button';
import useStorage from '../../../../../hooks/useStorage';
import useSessionStore from '../../../../../hooks/useSessionStore';
import useCurrentLoggedInUser from '../../../../../hooks/useCurrentLoggedInUser';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import ExternalCoachContext from '../../../../context/ExternalCoachContext';
import UserContext from '../../../../../context/UserContext';
import LoggedInUserContext from '../../../../../context/LoggedInUserContext';
import { storagePaths, pathPlaceholder } from '../../../../../utils/firebasePaths';
import useToast from '../../../../hooks/useToast';
import ImageCrop from '../../../../../components/ImageCrop';
import { getCroppedImageUrl } from '../../../../../components/ImageCrop/utils';
import {
  Wrapper,
  Title,
  Description,
} from '../../styles';
import CoachOnboarding from '../../../../Model/CoachOnboarding';
import CoachHistory, { HistoryEventType } from '../../../../Model/CoachHistory';
import {
  HeroImageContainer,
  HeroImageDescription,
  HeroImageUploader,
  HeroImageWrapper,
  StyledPreview,
  StyledEditIcon,
  StyledFileSelectButton,
  StyledImageSearch,
  HeroImageTipsDescription,
  HeroImageDescriptionContainer,
} from './styles';
import texts from './texts.json';

// Let's accept images if up to 5MB. TODO: optimize the image programatically
const MAX_FILE_UPLOAD_SIZE = 5000000;

// Size in MB
const MAX_FILE_SIZE_MB = MAX_FILE_UPLOAD_SIZE / 1000000;

// These are the aspect ratios we recommend
const RECT_ASPECT_RATIO = 4 / 3;
const SQUARE_ASPECT_RATIO = 1;

// We allow a 10% deviation in both limits.
const MIN_ASPECT_RATIO = SQUARE_ASPECT_RATIO * 0.9;
const MAX_ASPECT_RATIO = RECT_ASPECT_RATIO * 1.1;

const CheckoutPageConfiguration = () => {
  const { coachDoc } = useContext(ExternalCoachContext);

  const [imagePreviewUrl, setImagePreview] = useState(coachDoc.avatar);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [cropper, setCropper] = useState();

  const {
    userId,
    userDoc,
  } = useContext(UserContext);

  const {
    userDoc: {
      name: authUserName,
    },
    userId: authUserId,
  } = useContext(LoggedInUserContext);
  const {
    uploadAttachment,
  } = useStorage();

  const isComponentMountedRef = useComponentMounted();

  const { showToast } = useToast();

  const { isCoachAssistant, isAdmin } = useSessionStore();
  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();

  const onImageSave = useCallback(async () => {
    const croppedImageUrl = getCroppedImageUrl(cropper);
    const extensionIndex = fileToUpload.name.lastIndexOf('.');

    const storagePath = format(storagePaths.COACH, {
      [pathPlaceholder.COACH_ID]: userId,
    });

    const imageFormat = fileToUpload.name.slice(extensionIndex + 1);
    const fileName = fileToUpload.name.slice(0, extensionIndex);
    const fileType = fileToUpload.type;

    setIsUploadingFile(true);

    try {
      // Upload the image to firebase storage
      const { publicUrl } = await uploadAttachment(croppedImageUrl, imageFormat, storagePath, fileName, fileType);

      // Add coach history document if it is the first time coach's checkout image has been set
      if (!coachDoc.avatar && publicUrl) {
        await CoachHistory.addCoachHistoryItem({
          type: HistoryEventType.PROFILE_SET,
          coachId: userDoc.id,
          actionedBy: authUserId,
          actionedByUserName: authUserName,
        });
      }
      // Save the public path in the coach document.
      await coachDoc.updateFields({
        avatarUrl: publicUrl,
      });

      // Update the user profile picture if it hasn't been set before. This will only happen the first time.
      // TODO: Remove this once we implement the profile section.
      if (!userDoc.avatarUrl) {
        await userDoc.updateFields({
          avatarUrl: publicUrl,
        });
        try {
          // NOTE: Update the user profile picture field in the CRX coach onboarding collection.
          const coachOnboardingDoc = await CoachOnboarding.getCoachOnboardingByCoachId(userId);
          if (coachOnboardingDoc) {
            await coachOnboardingDoc.ref.update({
              coachHasAvatarUrl: true,
            });
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      }

      setFileToUpload(null);
      setImagePreview(croppedImageUrl);
      if (isComponentMountedRef.current) {
        showToast(texts.imageUploadedSuccessfully);
      }
    } catch (error) {
      if (isComponentMountedRef.current) {
        showToast(
          `${texts.errors.upload} ${error.message}`,
          { error: true },
        );
        setImagePreview(coachDoc.avatar);
      }
    } finally {
      if (isComponentMountedRef.current) {
        setIsUploadingFile(false);
        setFileToUpload(null);
      }
    }
  }, [
    uploadAttachment,
    userId,
    coachDoc,
    fileToUpload,
    showToast,
    isComponentMountedRef,
    userDoc,
    cropper,
    authUserId,
    authUserName,
  ]);

  const onImageSelected = useCallback(({ target }) => {
    const { files } = target;
    if (files.length) {
      const filesArray = [...files];
      const validFile = filesArray.every((file) => file.size < MAX_FILE_UPLOAD_SIZE);

      if (validFile) {
        // Revoke any previously created URL.
        URL.revokeObjectURL(imagePreviewUrl);

        const [file] = filesArray;
        const imageUrl = URL.createObjectURL(file);

        setImagePreview(imageUrl);
        setFileToUpload(file);
      } else {
        showToast(
          format(texts.errors.size, { maxFileSize: MAX_FILE_SIZE_MB }),
          { error: true },
        );
      }
    }
  }, [
    imagePreviewUrl,
    showToast,
  ]);

  /**
   * This gets executed every time an image is loaded in the preview component. This allows us to show warning messages
   * to the user if we find anything off (for example, when we detect an aspect ratio we don't recomend)
   * @param {Object} event The event object
   */
  const imageOnLoad = (event) => {
    const { naturalHeight, naturalWidth } = event.target;
    const aspectRatio = naturalWidth / naturalHeight;

    if (aspectRatio < MIN_ASPECT_RATIO || aspectRatio > MAX_ASPECT_RATIO) {
      showToast(texts.warnings.aspectRatio, { warning: true });
    }
  };

  const onCancel = useCallback(() => {
    setFileToUpload(null);
    setImagePreview(coachDoc.avatar);
  }, [coachDoc.avatar]);

  const renderImagePreview = () => {
    if (!coachDoc.avatar && !fileToUpload) {
      return <StyledImageSearch />;
    }

    return (
      <>
        <StyledEditIcon>
          <EditOutlined />
        </StyledEditIcon>
        <StyledPreview src={imagePreviewUrl} onLoad={imageOnLoad} />
      </>
    );
  };

  const isEditionEnabled = (isCoachAssistant || isAdmin || isCurrentLoggedInUserInPath);
  const isUploadButtonEnabled = isEditionEnabled && (fileToUpload && !isUploadingFile);

  return (
    <Wrapper>
      <Title>{texts.title}</Title>
      <Description>{texts.description}</Description>
      <Divider />
      <HeroImageContainer>
        <HeroImageUploader>
          {!!fileToUpload && (
            <ImageCrop
              onCropperChange={setCropper}
              aspectRatio={RECT_ASPECT_RATIO}
              imageUrl={imagePreviewUrl}
            />
          )}
          {!fileToUpload && (
            <HeroImageWrapper>
              <StyledFileSelectButton
                onSelect={onImageSelected}
                multiple={false}
                extraInputProps={{ accept: 'image/*' }}
                extraButtonProps={{ disabled: !isEditionEnabled }}
              >
                {renderImagePreview()}
              </StyledFileSelectButton>
            </HeroImageWrapper>
          )}
          <LoadingButton
            onClick={onImageSave}
            disabled={!isUploadButtonEnabled}
            isLoading={isUploadingFile}
          >
            {coachDoc.avatar ? texts.updateHeroImage : texts.uploadHeroImage}
          </LoadingButton>
          {fileToUpload && (
            <Button
              onClick={onCancel}
              variant={variants.SECONDARY}
            >
              {texts.cancel}
            </Button>
          )}
        </HeroImageUploader>
        <HeroImageDescriptionContainer>
          <HeroImageDescription>{texts.heroImageDescription}</HeroImageDescription>
          <HeroImageTipsDescription>{texts.heroImageTipsDescription}</HeroImageTipsDescription>
          <ul>
            {texts.heroRequirements.map((text) => (
              <li key={text}>{format(text, { maxFileSize: MAX_FILE_SIZE_MB })}</li>
            ))}
          </ul>
        </HeroImageDescriptionContainer>
      </HeroImageContainer>
    </Wrapper>
  );
};

export default compose(
  observer,
)(CheckoutPageConfiguration);
