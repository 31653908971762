import moment from 'moment';

const DateFormat = {
  DEFAULT_DATE_TIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
  DEFAULT_DATE_FORMAT: 'YYYY-MM-DD',
  DEFAULT_DATE_WITH_TIME_FORMAT: 'YYYY-MM-DD-HH-mm-ss',
  SHORT_DATE_FORMAT: 'MM-DD-YY',
  DATE_FORMAT_SLASH: 'MM/DD/YYYY',
  DATE_FORMAT_SLASH_WITH_TIME_FORMAT: 'MM/DD/YY HH:mm:ss',
  DATE_FORMAT_COMMA: 'MMMM DD, YYYY',
  DATE_FORMAT_COMMA_TH: 'MMMM Do, YYYY',
  DATE_FORMAT_TIME_MINUTES: 'YYYY-MM-DD HH:mm',
  LOCAL_DATE_AND_TIME: 'L LT',
  CONTRACT_DATE_FORMAT: 'MMMM D, YYYY',
  MONTH_NAME_DATE_FORMAT: 'DD MMMM YYYY',
  MONTH_NAME: 'MMMM',
  YEAR_MONTH_FORMAT: 'YYYY-MM',
  YEAR_MONTH_DISPLAY_FORMAT: 'MMMM YYYY',
  YEAR_MONTH_SHORT_DISPLAY_FORMAT: 'MMM YY',
  DATE_YEAR_MONTH_SHORT_DISPLAY_FORMAT: 'MMM DD YYYY',
  SHORT_MONTH_DAY_COMMA_YEAR_FORMAT: 'MMM DD, YYYY',
  DATE_MONTH_SHORT_DISPLAY_FORMAT: 'MMM DD',
  DATE_MONTH_TIME_SHORT_DISPLAY_FORMAT: 'MMM DD, HH:mm',
  DATE_MONTH_YEAR_TIME_SHORT_DISPLAY_FORMAT: 'MMM DD, YYYY - HH:mm',
  DATE_FORMAT_YYYYMMDD: 'YYYYMMDD',
  DATE_FORMAT_WEEKDAY_MONTH_FORMAT: 'ddd - D MMM',
  WEEK_DAY_NAME: 'dddd',
  TEXT_DATE_FORMAT: 'LL',
  ABBREVIATED_TEXT_DATE_FORMAT: 'll',
};

const isYesterday = (momentDate) => {
  const yesterday = moment().subtract(1, 'days').startOf('day');
  return momentDate.isSame(yesterday, 'day');
};

const isPastDay = (momentDate) => {
  const startOfNow = moment().startOf('day');
  return momentDate.isBefore(startOfNow, 'day');
};

const isToday = (momentDate) => {
  const today = moment();
  return today.isSame(momentDate, 'day');
};

const isTomorrow = (momentDate) => {
  const tomorrow = moment().add(1, 'days');
  return tomorrow.isSame(momentDate, 'day');
};

const isFutureDay = (momentDate) => {
  const startOfNow = moment().startOf('day');
  return startOfNow.isBefore(momentDate, 'day');
};

const toWeekdayDate = (momentDate) => (
  momentDate.format('ddd D')
);

const toWeekdayDateOfMonth = (momentDate) => (
  momentDate.format('ddd D MMM')
);

const getDayofWeek = () => (
  moment(new Date()).format('dddd')
);

const isSameMonth = (momentDate) => {
  const today = moment();
  return momentDate.isSame(today, 'month');
};

const isFirstDayOfMonth = (momentDate) => (
  parseInt(momentDate.format('D'), 10) === 1
);

const todayStartDate = () => (
  moment().startOf('day')
);

/**
 * Converts a UTC date to a local user date time.
 *
 * @param {Object} date Date to convert to local time date.
 * @returns {Object} A moment date instance.
 */
const convertToLocalDate = (date) => {
  const momentDate = moment(date);
  const localMomentDate = momentDate.subtract(momentDate.utcOffset(), 'minutes');

  return localMomentDate;
};

/**
 * Converts a date to a UTC date.
 *
 * @param {Object} date The date object to convert.
 * @returns {Object} A moment date instance.
 */
const convertToUTCDate = (date) => {
  const momentDate = moment(date);
  const offset = moment().utcOffset();
  momentDate.subtract(offset, 'minutes');

  return momentDate;
};

const nowUTCDate = () => convertToUTCDate(moment());

const nowInDateOnlyFormat = () => moment().format(DateFormat.DEFAULT_DATE_FORMAT);

const convertToLocalStartMomentDate = (date) => convertToLocalDate(date).startOf('day');

const calculateUTCDay = (date) => {
  const localTimeStartDate = moment(date).startOf('day');

  return {
    startDate: moment.utc(localTimeStartDate).startOf('day').toDate(),
    endDate: moment.utc(localTimeStartDate).endOf('day').toDate(),
  };
};

const timeStampToStr = (timestamp, format) => (timestamp
  ? moment(timestamp.toDate()).format(format)
  : '');

/**
 * Combines date and time from two different moment objects into a single one
 * @param {Object} momentDate a moment object representing the day
 * @param {Object} momentTime a moment object representing the time
 */
const combineDateAndTime = (momentDate, momentTime) => {
  const day = momentDate.startOf('day');
  const timeDuration = moment.duration(momentTime.format('HH:mm'));
  return day.add(timeDuration);
};

/**
 * Converts a duration in seconds to a formatted string representing the equivalent in hours, minutes, and seconds.
 * @param {number} seconds - Duration in seconds
 * @returns {string} - Representing the equivalent duration in hours, minutes, and seconds
 */
const formatSeconds = (seconds) => {
  const duration = moment.duration(seconds, 'seconds');
  const formattedDurations = [];

  if (duration.hours() > 0) {
    formattedDurations.push(`${duration.hours()}hr`);
  }
  if (duration.minutes() > 0) {
    formattedDurations.push(`${duration.minutes()}mins`);
  }
  if (duration.seconds() > 0) {
    formattedDurations.push(`${duration.seconds()}s`);
  }

  return formattedDurations.join(' ');
};

/**
 * Calculates all the dates between a start and end date for specific days of the week.
 *
 * @param {string} startDate - The start date in 'YYYY-MM-DD' format.
 * @param {string} endDate - The end date in 'YYYY-MM-DD' format.
 * @param {number[]} days - An array of numbers representing the days of the week to include in the range
 *                          (0 = Sunday, 1 = Monday, ..., 6 = Saturday).
 * @returns {string[]} An array of dates in 'YYYY-MM-DD' format for the specified days of the week
 *                     between the start and end dates.
 */
const getDatesBetween = (startDate, endDate, days) => {
  const dates = [];
  let currentDate = moment(startDate);

  while (currentDate <= moment(endDate)) {
    if (days.includes(currentDate.format('ddd'))) {
      dates.push(currentDate.format('YYYY-MM-DD'));
    }
    currentDate = currentDate.add(1, 'days');
  }
  return dates;
};

const combineDateAndTimeWithUTCOffset = (momentDate, momentTime, utcOffset) => {
  const dateTime = moment({
    year: momentDate.year(),
    month: momentDate.month(),
    date: momentDate.date(),
    hour: momentTime.hour(),
    minute: momentTime.minute(),
  });
  return dateTime.utcOffset(utcOffset, true);
};

export {
  isYesterday,
  isPastDay,
  isToday,
  isTomorrow,
  isFutureDay,
  isSameMonth,
  isFirstDayOfMonth,
  toWeekdayDate,
  toWeekdayDateOfMonth,
  getDayofWeek,
  convertToLocalDate,
  todayStartDate,
  convertToUTCDate,
  nowUTCDate,
  nowInDateOnlyFormat,
  convertToLocalStartMomentDate,
  calculateUTCDay,
  combineDateAndTime,
  DateFormat,
  timeStampToStr,
  formatSeconds,
  getDatesBetween,
  combineDateAndTimeWithUTCOffset,
};
