import { HistoryEventType } from '../../../../../Model/CoachHistory';

const texts = {
  eventText: {
    [HistoryEventType.ACCOUNT_CREATED]: 'Coach to sign up to the system',
    [HistoryEventType.PROFILE_SET]: 'Set up the coach profile',
    [HistoryEventType.STRIPE_CONNECTED]: 'Create and connect the stripe account',
    [HistoryEventType.LEAD_FORM_CREATED]: 'Create the coach lead form',
    [HistoryEventType.POST_PAYMENT_FORM_LINKED]: 'Create and link the post-payment form',
    [HistoryEventType.FIRST_PRODUCT_CREATED]: 'Create the products for the coach',
    [HistoryEventType.LOGO_SUBMITTED]: 'App logo submitted for approval by design',
    [HistoryEventType.LOGO_APPROVED]: 'App logo approved by coach',
    [HistoryEventType.WEBSITE_SUBMITTED]: 'Website submitted for approval by design',
    [HistoryEventType.WEBSITE_APPROVED]: 'Website approved by coach',
    [HistoryEventType.WELCOME_PACK_SUBMITTED]: 'Welcome pack submitted for approval by design',
    [HistoryEventType.APP_LOGO_SET]: 'Set up mobile app logo for the coach',
    [HistoryEventType.APP_THEME_SET]: 'Set up mobile app theme for the coach',
    [HistoryEventType.WELCOME_PACK_SET]: 'Welcome pack was set up by {userName}.',
    [HistoryEventType.SLACK_CHANNEL_SET]: 'Configure slack channel for the coach',
    [HistoryEventType.COACH_LAUNCHED]: 'Coach has been officially launched',
    [HistoryEventType.FIRST_LEAD]: 'First lead is yet to submit',
    [HistoryEventType.FIRST_SUBSCRIPTION]: 'First client is yet to subscribe',
  },
  notSignedUp: 'Coach has not signed up yet',
  completionFailed: 'Failed to mark the item as completed',
  manualChecklist: 'Manual Checklist',
  automaticChecklist: 'Auto Captured Pending Events',
  noPending: `No pending items to show for the coach. It means either the coach has completed all the onboarding
    steps or the coach is not elligible for pending items.`,
  modalTitle: 'Are you sure you want to mark this item as completed?',
};

export default texts;
