import {
  CoachOnboardingEventList,
  ManualCoachEventList,
} from '../../../../../Model/CoachHistory';

/**
 * Util function to identify pending events, separated into manual and automatically captured types.
 *
 * @param {Array} history - Array of recorded history events of the coach.
 * @returns {Object} Pending manual and automatic items.
 */
const getPendingEvents = (history) => {
  const completedEventTypes = new Set(history.map((event) => event.type));

  const manualEvents = [];
  const automaticEvents = [];

  CoachOnboardingEventList.forEach((eventType) => {
    if (!completedEventTypes.has(eventType)) {
      if (ManualCoachEventList.includes(eventType)) {
        manualEvents.push(eventType);
      } else {
        automaticEvents.push(eventType);
      }
    }
  });

  return {
    manualEvents,
    automaticEvents,
  };
};

export { getPendingEvents };
