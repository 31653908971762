import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import format from 'string-template';

import { DateFormat } from '../../../../../../utils/date';

import {
  StyledLoadingPage,
  Container,
  ActivityItem,
  ContentList,
  ContentListItem,
  Tag,
  ContentText,
} from './styles';
import texts from './texts';

const ActivityList = ({
  history,
  isReady,
}) => {
  const groupedActivities = useMemo(() => {
    const activityMap = new Map();
    // Assign the history documents to the event text
    history.forEach((historyDoc) => {
      const tag = moment(historyDoc.createdAt).format(DateFormat.DATE_FORMAT_COMMA);
      const text = format(
        texts.eventText[historyDoc.type] ?? historyDoc.type,
        { userName: historyDoc.actionedByUserName || '-' },
      );

      if (!activityMap.has(tag)) {
        activityMap.set(tag, []);
      }

      activityMap.get(tag).push({ id: `${historyDoc.id}`, text });
    });

    return activityMap;
  }, [history]);

  if (!isReady) {
    return <StyledLoadingPage />;
  }

  return (
    <Container>
      {groupedActivities.size === 0 && texts.emptyText}
      {Array.from(groupedActivities.keys()).map((activityDate) => (
        <ActivityItem key={activityDate}>
          <Tag>{activityDate}</Tag>
          <ContentList>
            {groupedActivities.get(activityDate).map((activity) => (
              <ContentListItem key={activity.id}>
                <ContentText>{activity.text}</ContentText>
              </ContentListItem>
            ))}
          </ContentList>
        </ActivityItem>
      ))}
    </Container>
  );
};

ActivityList.propTypes = {
  history: PropTypes.array.isRequired,
  isReady: PropTypes.bool.isRequired,
};

export default ActivityList;
