import React, {
  useMemo,
  useCallback,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import format from 'string-template';
import { Button } from '@mui/material';

import { ReactComponent as HistoryIcon } from '../../../../../assets/icons/v2/clock-rewind.svg';
import useRunOnInitialRender from '../../../../../hooks/useRunOnInitialRender';
import { getKgFromLbs } from '../../../../../utils/measurement';
import { DateFormat } from '../../../../../utils/date';
import MealPlanAssignmentContext from '../../../../context/MealPlanAssignmentContext';
import MealPlanContext from '../../../../context/MealPlanContext';
import {
  getMacroNutrientsFromCalories,
  calculateUserMacroGoals,
} from '../../../../utils/meals';

import RadioButtonGroup from '../../../../components/RadioButtonGroup';
import NutritionGoalsEditorModal from '../../MealPlanAssignment/components/NutritionGoalsEditorModal';
import NutritionGoalsHistoryModal from '../../MealPlanAssignment/components/NutritionGoalsHistoryModal';

import {
  StyledTableContainer,
  StyledTable,
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledTableBody,
  StyledHeaderCell,
  StyledButton,
  FooterContainer,
  DateContainer,
  EmptyRow,
} from '../styles';

import { NutritionalGoalsAction } from './utils';
import texts from './texts';

const UserNutritionalGoals = ({
  onSelectNutritionalGoals,
  isOnlyManuallyEditable,
}) => {
  const {
    isReady,
    mealPlanAssignmentDoc = {},
    macroGoalsDoc = {},
    userDoc = {},
    nutritionProfileDoc = {},
    activityProfileDoc = {},
    weeklyCheckinDocs = [],
    nutritionalGoalsAction,
    setNutritionalGoalsAction,
    nutritionalGoals: newNutritionalGoals,
    newMealPlanData: selectedMealPlanDoc,
    selectedWeightGoal,
  } = useContext(MealPlanAssignmentContext);

  const { mealPlanConfig } = useContext(MealPlanContext);

  const {
    minCaloriesThreshold,
    maxCaloriesThreshold,
  } = mealPlanConfig;

  const [isGoalsModalOpen, setIsGoalsModalOpen] = useState(false);
  const [isGoalsHistoryModalOpen, setIsGoalsHistoryModalOpen] = useState(false);

  // Default to recommended goals only when no meal plan is assigned and no macro goals are set
  const defaultAction = !mealPlanAssignmentDoc && macroGoalsDoc?.history?.length === 0
    ? NutritionalGoalsAction.USE_RECOMMENDED_GOALS
    : NutritionalGoalsAction.KEEP_PREVIOUS_GOALS;

  const {
    calculatedDailyCalories,
    totalDailyCalories,
    updatedMacroAveragesAt,
    macroAverages: clientMacroAverages = {},
  } = macroGoalsDoc || {};

  const {
    // If these are present, these are manually set macros and percentages
    macros: newMacros,
    percentages: newPercentages,
  } = newNutritionalGoals?.macroAverages || {};

  const {
    macroAverages: {
      percentages: mealPlanPercentages = {
        protein: 0,
        carbs: 0,
        fat: 0,
      },
    } = {},
  } = mealPlanAssignmentDoc || {};

  const {
    macroAverages: {
      percentages: newMealPlanPercentages = {
        protein: 0,
        carbs: 0,
        fat: 0,
      },
    } = {},
  } = selectedMealPlanDoc || {};

  const newCalculatedMacroGoals = useMemo(() => {
    if (isReady) {
      let currentWeight;

      if (weeklyCheckinDocs.length) {
        const {
          checkInData: {
            current: {
              weight: {
                value: weightValue,
                unit: weightUnit,
              } = {},
            } = {},
          } = {},
        } = weeklyCheckinDocs[weeklyCheckinDocs.length - 1];

        if (!!weightValue && !!weightUnit) {
          currentWeight = weightUnit !== 'kg' ? getKgFromLbs(weightValue) : weightValue;
        }
      }

      return (
        calculateUserMacroGoals({
          userDoc,
          nutritionProfileDoc,
          activityProfileDoc,
          currentWeight,
          weeklyWeightGoal: selectedWeightGoal,
          minCaloriesThreshold,
          maxCaloriesThreshold,
        })
      );
    }
    return {};
  }, [
    isReady,
    userDoc,
    nutritionProfileDoc,
    activityProfileDoc,
    selectedWeightGoal,
    weeklyCheckinDocs,
    minCaloriesThreshold,
    maxCaloriesThreshold,
  ]);

  const {
    totalDailyCalories: newCalculatedCalories = 0,
  } = newCalculatedMacroGoals || {};

  // Calculated macro nutrients amounts in grams
  const calculatedMacroNutrients = useMemo(() => (
    getMacroNutrientsFromCalories(calculatedDailyCalories, mealPlanPercentages)
  ), [
    calculatedDailyCalories,
    mealPlanPercentages,
  ]);

  // New calculated macro nutrients amounts in grams
  const newCalculatedMacroNutrients = useMemo(() => (
    getMacroNutrientsFromCalories(newCalculatedCalories, newMealPlanPercentages)
  ), [
    newCalculatedCalories,
    newMealPlanPercentages,
  ]);

  // Current macro averages
  const macroAverages = useMemo(() => {
    let macros;
    let percentages;

    if (clientMacroAverages?.macros) {
      macros = clientMacroAverages.macros;
    } else {
      macros = getMacroNutrientsFromCalories(totalDailyCalories, mealPlanPercentages);
    }

    if (clientMacroAverages?.percentages) {
      percentages = clientMacroAverages.percentages;
    } else {
      percentages = mealPlanPercentages;
    }

    return { macros, percentages };
  }, [
    clientMacroAverages,
    mealPlanPercentages,
    totalDailyCalories,
  ]);

  const updatedAtText = updatedMacroAveragesAt
    ? moment(updatedMacroAveragesAt.toDate()).format(DateFormat.MONTH_NAME_DATE_FORMAT)
    : '';

  const isPreviousGoalOverwritten = useMemo(() => (
    !!clientMacroAverages.percentages && !!clientMacroAverages.macros
  ), [
    clientMacroAverages.percentages,
    clientMacroAverages.macros,
  ]);

  const setRecommendedNutritionGoals = useCallback(() => {
    const recommendedMacroAverages = {
      macros: {
        protein: newCalculatedMacroNutrients.protein,
        carbs: newCalculatedMacroNutrients.carbs,
        fat: newCalculatedMacroNutrients.fat,
      },
      percentages: {
        protein: newMealPlanPercentages.protein,
        carbs: newMealPlanPercentages.carbs,
        fat: newMealPlanPercentages.fat,
      },
    };
    onSelectNutritionalGoals({
      ...newCalculatedMacroGoals,
      macroAverages: recommendedMacroAverages,
      isRecommended: true,
      updatedMacroAveragesAt: new Date(),
    });
  }, [
    newMealPlanPercentages,
    onSelectNutritionalGoals,
    newCalculatedMacroGoals,
    newCalculatedMacroNutrients,
  ]);

  const actionOptions = useMemo(() => {
    if (isOnlyManuallyEditable) {
      return [{
        label: texts.button[NutritionalGoalsAction.SET_MANUAL_GOALS],
        value: NutritionalGoalsAction.SET_MANUAL_GOALS,
      }];
    }
    let actionValues = Object.values(NutritionalGoalsAction).map((option) => ({
      label: texts.button[option],
      value: option,
    }));
    // Remove recommended option if there are no calculated calories available
    if (!newCalculatedCalories) {
      actionValues = actionValues.filter(({ value }) => value !== NutritionalGoalsAction.USE_RECOMMENDED_GOALS);
    }
    // Remove keep previous goals option if there is no current goal or
    // previous meal plan assigned and the history is empty
    if ((!totalDailyCalories || !mealPlanAssignmentDoc) && macroGoalsDoc?.history?.length === 0) {
      actionValues = actionValues.filter(({ value }) => value !== NutritionalGoalsAction.KEEP_PREVIOUS_GOALS);
    }
    return actionValues;
  }, [
    totalDailyCalories,
    mealPlanAssignmentDoc,
    newCalculatedCalories,
    isOnlyManuallyEditable,
    macroGoalsDoc,
  ]);

  const buttonActionHandler = useMemo(() => ({
    [NutritionalGoalsAction.USE_RECOMMENDED_GOALS]: () => {
      setNutritionalGoalsAction(NutritionalGoalsAction.USE_RECOMMENDED_GOALS);
      setRecommendedNutritionGoals();
    },
    [NutritionalGoalsAction.SET_MANUAL_GOALS]: () => {
      setNutritionalGoalsAction(NutritionalGoalsAction.SET_MANUAL_GOALS);
      setIsGoalsModalOpen(true);
    },
    [NutritionalGoalsAction.KEEP_PREVIOUS_GOALS]: () => {
      setNutritionalGoalsAction(NutritionalGoalsAction.KEEP_PREVIOUS_GOALS);
      // Even if we are keeping previous goals we need to update the update date.
      onSelectNutritionalGoals({
        updatedMacroAveragesAt: new Date(),
        totalDailyCalories,
        macroAverages,
      });
    },
  }), [
    setRecommendedNutritionGoals,
    setNutritionalGoalsAction,
    onSelectNutritionalGoals,
    totalDailyCalories,
    macroAverages,
  ]);

  // There should be a macro goal, and the nutrition goals should be calculated
  // and checking if it is a manual edits flow
  const conditionsForDefaultAction = isReady
    && Object.keys(newCalculatedMacroGoals || {}).length
    && !isOnlyManuallyEditable;

  useRunOnInitialRender(() => {
    if (defaultAction) {
      buttonActionHandler[defaultAction]();
    }
  }, conditionsForDefaultAction);

  // If modal was closed and was not saved, unset selected action
  const handleModalClose = useCallback(({ isSaved }) => {
    setIsGoalsModalOpen(false);
    if (isOnlyManuallyEditable) {
      setNutritionalGoalsAction('');
    } else if (!isSaved && nutritionalGoalsAction === NutritionalGoalsAction.SET_MANUAL_GOALS) {
      setNutritionalGoalsAction(defaultAction);
      buttonActionHandler[defaultAction]();
    }
  }, [
    defaultAction,
    nutritionalGoalsAction,
    isOnlyManuallyEditable,
    setNutritionalGoalsAction,
    buttonActionHandler,
  ]);

  const renderEmptyRow = useCallback(() => {
    if (!isReady) {
      return <EmptyRow>{texts.loading}</EmptyRow>;
    }

    const dataAvailable = totalDailyCalories || newCalculatedMacroGoals || newNutritionalGoals?.macroAverages;
    if (!dataAvailable) {
      return <EmptyRow>{texts.noData}</EmptyRow>;
    }

    return null;
  }, [
    isReady,
    totalDailyCalories,
    newCalculatedMacroGoals,
    newNutritionalGoals,
  ]);

  // We will not show current goals when it's a new assignment, unless it has manually set goals
  const showCurrentGoals = useMemo(() => (
    (!!mealPlanAssignmentDoc || !!clientMacroAverages.percentages) && !!totalDailyCalories
  ), [
    mealPlanAssignmentDoc,
    clientMacroAverages.percentages,
    totalDailyCalories,
  ]);

  // We will show recommended goals if its not only manually editable, or if it is, but no goal was set
  const showRecommendedGoals = useMemo(() => (
    (!isOnlyManuallyEditable && newCalculatedMacroGoals) || (isOnlyManuallyEditable && !updatedMacroAveragesAt)
  ), [
    isOnlyManuallyEditable,
    newCalculatedMacroGoals,
    updatedMacroAveragesAt,
  ]);

  return (
    <StyledTableContainer>
      <StyledTable>
        <StyledTableHead>
          <StyledTableRow $header>
            <StyledHeaderCell $highlight>{texts.nutritionalGoals}</StyledHeaderCell>
            <StyledHeaderCell>{texts.headers.dailyCalories}</StyledHeaderCell>
            <StyledHeaderCell>{texts.headers.protein}</StyledHeaderCell>
            <StyledHeaderCell>{texts.headers.carb}</StyledHeaderCell>
            <StyledHeaderCell>{texts.headers.fat}</StyledHeaderCell>
            <StyledHeaderCell align="right">
              {!!macroGoalsDoc?.history?.length && (
                <StyledButton
                  $light
                  component={Button}
                  startIcon={<HistoryIcon />}
                  onClick={() => (setIsGoalsHistoryModalOpen(true))}
                >
                  {texts.viewHistory}
                </StyledButton>
              )}
            </StyledHeaderCell>
          </StyledTableRow>
        </StyledTableHead>
        {isReady && (
          <StyledTableBody>
            {showCurrentGoals && (
              <StyledTableRow>
                <StyledTableCell $flex>
                  {texts.currentGoals}
                  {(isPreviousGoalOverwritten && updatedAtText) && (
                    <DateContainer>{format(texts.manuallySet, { date: updatedAtText })}</DateContainer>
                  )}
                </StyledTableCell>
                <StyledTableCell $strike>
                  {(isPreviousGoalOverwritten && calculatedDailyCalories !== totalDailyCalories) && (
                    <span>{`${calculatedDailyCalories || 0} ${texts.kCal}`}</span>
                  )}
                  {`${totalDailyCalories} ${texts.kCal}`}
                </StyledTableCell>
                <StyledTableCell $strike>
                  {(isPreviousGoalOverwritten && !!calculatedMacroNutrients.protein) && (
                    <span>{`${calculatedMacroNutrients.protein || 0}g (${mealPlanPercentages.protein}%)`}</span>
                  )}
                  {macroAverages.macros.protein
                    ? `${macroAverages.macros.protein}g (${macroAverages.percentages.protein}%)`
                    : texts.emptyCell}
                </StyledTableCell>
                <StyledTableCell $strike>
                  {(isPreviousGoalOverwritten && !!calculatedMacroNutrients.carbs) && (
                    <span>{`${calculatedMacroNutrients.carbs || 0}g (${mealPlanPercentages.carbs}%)`}</span>
                  )}
                  {macroAverages.macros.protein
                    ? `${macroAverages.macros.carbs || 0}g (${macroAverages.percentages.carbs}%)`
                    : texts.emptyCell}
                </StyledTableCell>
                <StyledTableCell $strike>
                  {(isPreviousGoalOverwritten && !!calculatedMacroNutrients.fat) && (
                    <span>{`${calculatedMacroNutrients.fat || 0}g (${mealPlanPercentages.fat}%)`}</span>
                  )}
                  {macroAverages.macros.fat
                    ? `${macroAverages.macros.fat || 0}g (${macroAverages.percentages.fat}%)`
                    : texts.emptyCell}
                </StyledTableCell>
                <StyledTableCell />
              </StyledTableRow>
            )}
            {showRecommendedGoals && (
              <StyledTableRow>
                <StyledTableCell>{texts.recommendedGoals}</StyledTableCell>
                <StyledTableCell>
                  {newCalculatedCalories ? `${newCalculatedCalories} ${texts.kCal}` : texts.emptyCell}
                </StyledTableCell>
                {mealPlanAssignmentDoc ? (
                  <>
                    <StyledTableCell>
                      {(newCalculatedCalories && newMealPlanPercentages.protein)
                        ? `${newCalculatedMacroNutrients.protein}g (${newMealPlanPercentages.protein}%)`
                        : texts.emptyCell}
                    </StyledTableCell>
                    <StyledTableCell>
                      {(newCalculatedCalories && newMealPlanPercentages.carbs)
                        ? `${newCalculatedMacroNutrients.carbs}g (${newMealPlanPercentages.carbs}%)`
                        : texts.emptyCell}
                    </StyledTableCell>
                    <StyledTableCell>
                      {(newCalculatedCalories && newMealPlanPercentages.fat)
                        ? `${newCalculatedMacroNutrients.fat}g (${newMealPlanPercentages.fat}%)`
                        : texts.emptyCell}
                    </StyledTableCell>
                  </>
                ) : (
                  <StyledTableCell colSpan={4} $secondary>
                    {texts.newAssignmentMacros}
                  </StyledTableCell>
                )}
              </StyledTableRow>
            )}
            {(nutritionalGoalsAction === NutritionalGoalsAction.SET_MANUAL_GOALS
              && newNutritionalGoals?.macroAverages) && (
              <StyledTableRow>
                <StyledTableCell>{texts.manuallySetGoals}</StyledTableCell>
                <StyledTableCell>
                  {`${newNutritionalGoals.totalDailyCalories} ${texts.kCal}`}
                </StyledTableCell>
                <StyledTableCell>
                  {`${newMacros.protein}g (${newPercentages.protein}%)`}
                </StyledTableCell>
                <StyledTableCell>
                  {`${newMacros.carbs}g (${newPercentages.carbs}%)`}
                </StyledTableCell>
                <StyledTableCell>
                  {`${newMacros.fat}g (${newPercentages.fat}%)`}
                </StyledTableCell>
                <StyledTableCell />
              </StyledTableRow>
            )}
          </StyledTableBody>
        )}
      </StyledTable>
      {renderEmptyRow()}
      <FooterContainer>
        {(!!onSelectNutritionalGoals && !!macroGoalsDoc) && (
          <>
            {texts.nutritionalGoalsFooter}
            <RadioButtonGroup
              options={actionOptions}
              selectedOption={nutritionalGoalsAction}
              onOptionChange={(value) => buttonActionHandler[value]?.()}
              fullWidth={false}
              enableReselection
            />
          </>
        )}
      </FooterContainer>
      {isGoalsModalOpen && (
        <NutritionGoalsEditorModal
          isOpen={isGoalsModalOpen}
          mealPlanMacroAverages={selectedMealPlanDoc?.macroAverages || {}}
          currentCalories={
            (nutritionalGoalsAction === NutritionalGoalsAction.SET_MANUAL_GOALS
              && newNutritionalGoals?.totalDailyCalories)
              ? newNutritionalGoals.totalDailyCalories
              : totalDailyCalories || 0
          }
          // manual goals takes precedence in a flow where we set the manual goals and wants to update again
          currentMacroAverages={
            (nutritionalGoalsAction === NutritionalGoalsAction.SET_MANUAL_GOALS && newNutritionalGoals?.macroAverages)
              ? newNutritionalGoals.macroAverages
              : macroAverages || {}
          }
          userNutritionMacroGoalsDoc={macroGoalsDoc}
          mealPlanId={selectedMealPlanDoc?.id}
          mealPlanName={selectedMealPlanDoc?.name}
          onSave={onSelectNutritionalGoals}
          onClose={handleModalClose}
        />
      )}
      {isGoalsHistoryModalOpen && (
        <NutritionGoalsHistoryModal
          isOpen={isGoalsHistoryModalOpen}
          onClose={() => setIsGoalsHistoryModalOpen(false)}
          currentGoals={{
            mealPlanId: mealPlanAssignmentDoc?.mealPlanId,
            mealPlanName: mealPlanAssignmentDoc?.name,
            totalDailyCalories: macroGoalsDoc.totalDailyCalories || 0,
            calculatedDailyCalories: macroGoalsDoc.calculatedDailyCalories || 0,
            updatedMacroAveragesAt: macroGoalsDoc.updatedMacroAveragesAt || null,
            macroAverages,
            previousMacroAverages: isPreviousGoalOverwritten && {
              macros: calculatedMacroNutrients,
              percentages: mealPlanPercentages,
            },
            isRecommended: !isPreviousGoalOverwritten,
          }}
          history={macroGoalsDoc?.history}
        />
      )}
    </StyledTableContainer>
  );
};

UserNutritionalGoals.propTypes = {
  onSelectNutritionalGoals: PropTypes.func,
  isOnlyManuallyEditable: PropTypes.bool,
};

UserNutritionalGoals.defaultProps = {
  onSelectNutritionalGoals: null,
  isOnlyManuallyEditable: false,
};

export default UserNutritionalGoals;
