import styled, { css } from 'styled-components';

import { ReactComponent as History } from '../../../../../assets/icons/v2/clock-rewind.svg';
import { ReactComponent as Coach } from '../../../../../assets/icons/v2/people.svg';
import { ReactComponent as Offboarding } from '../../../../../assets/icons/v2/pause.svg';
import colors from '../../../../../styles/colors';
import { StyledTabs } from '../../../MealsPlanner/styles';

const iconStyles = css`
  width: 20px;
  height: 20px;
`;

const TabContainer = styled.div`
  width: 100%;
  background: ${colors.base.alpha};
`;

const Tabs = styled(StyledTabs)`
  button {
    background-color: unset !important;
  }
`;

const Tabcontent = styled.div`
  background-color: ${colors.shades.secondary5} !important;
  height: 50vh;
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  box-sizing: border-box;
`;

const HistoryIcon = styled(History)`
  ${iconStyles};
`;

const CoachIcon = styled(Coach)`
  ${iconStyles};
`;

const OffboardingIcon = styled(Offboarding)`
  width: 20px;
  height: 20px;
`;

export {
  TabContainer,
  Tabs,
  Tabcontent,
  HistoryIcon,
  CoachIcon,
  OffboardingIcon,
};
