import styled from 'styled-components';
import { Typography } from '@mui/material';

const CoachName = styled(Typography)`
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
`;

const DataGridContainer = styled.div`
  width: calc(100% - 40px);
  min-height: 500px;
  max-height: calc(100% - 100px);
  min-width: 920px
`;

export {
  CoachName,
  ActionsContainer,
  DataGridContainer,
};
