import React, {
  useState, useEffect, useContext,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import { ToggleButton } from '@mui/material';
import moment from 'moment/moment';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import UserCompositionChart from '../components/UserCompositionChart';
import FirebaseContext from '../../../../context/FirebaseContext';
import { DateFormat } from '../../../../utils/date';
import AnalyticsGroup from '../components/AnalyticsGroup';
import {
  Container,
  FilterRow,
  PageContent,
  StyledToggleButtonGroup,
} from './styles';

const DaysOptions = [7, 30, 60, 90];

const AppAnalytics = () => {
  const [userComposition, setUserComposition] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(DaysOptions[0]);

  const isComponentMountedRef = useComponentMounted();
  const { firebase: { remote } } = useContext(FirebaseContext);

  useEffect(() => {
    const getUserCompositionAnalytics = async () => {
      const from = moment().utc().subtract(selectedPeriod, 'days').format(DateFormat.DATE_FORMAT_YYYYMMDD);
      const to = moment().utc().format(DateFormat.DATE_FORMAT_YYYYMMDD);
      const endpoint = `analytics/userCompositionByCoach?from=${from}&to=${to}`;
      const response = await remote(endpoint, null, { method: 'GET' });
      const data = await response.json();
      if (isComponentMountedRef.current) {
        setUserComposition(data);
      }
    };

    getUserCompositionAnalytics();
  }, [isComponentMountedRef, remote, selectedPeriod]);

  return (
    <Container>
      <PageContent>
        <FilterRow>
          <StyledToggleButtonGroup
            value={selectedPeriod}
            exclusive
            onChange={(e, period) => setSelectedPeriod(period)}
          >
            {DaysOptions.map((days) => (
              <ToggleButton key={days} value={days}>
                {`${days}d`}
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>

        </FilterRow>
        <AnalyticsGroup>
          <UserCompositionChart data={userComposition} />
        </AnalyticsGroup>
      </PageContent>
    </Container>
  );
};

export default compose(
  observer,
)(AppAnalytics);
