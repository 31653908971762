import styled, { css } from 'styled-components';
import { Box, Typography } from '@mui/material';
import { FieldArray } from 'formik';

import Autocomplete from '../../../../../../../components/AutoComplete';
import { ReactComponent as TrashIcon } from '../../../../../../../../assets/icons/v2/trash.svg';
import { ReactComponent as AddIcon } from '../../../../../../../../assets/icons/v2/add.svg';
import colors from '../../../../../../../../styles/colors';
import InputLabel from '../../../../../../../../components/v2/InputLabel';
import TextField from '../../../../../../../../components/v2/TextField';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledFieldArray = styled(FieldArray)`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
`;

const rowsContainer = css`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${colors.shades.secondary7};
  background-color: ${colors.shades.secondary10};
  display: flex;
  flex-direction: row;
  column-gap: 8px;
`;

const WorkoutDaysContainer = styled.div`
  ${rowsContainer};
`;

const AddNewOptionContainer = styled.div`
  ${rowsContainer};
  align-items: center;
  margin-top: 20px;
  width: 80%;
`;

const StyledTypography = styled(Typography)`
  font-weight: 400;
  color: ${colors.shades.secondary8};
  text-transform: none;
  font-size: 12px;
  letter-spacing: 0.1em;
`;

const ChipContainer = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const WorkoutDayIndex = styled.span`
  color: ${colors.base.danger};
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-wrap: nowrap;
`;

const StyledOption = styled.div`
  border: 1px solid ${colors.shades.secondary7};
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-size: 12px;
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input{
    height: 20px;
  }
`;

const StyledTrashIcon = styled(TrashIcon)`
  width: 12px;
  height: 12px;
`;

const StyledAddIcon = styled(AddIcon)`
  width: 17px;
  height: 17px;
`;

const StyledOptionSubContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const StyledAutocompleteWrapper = styled.div`
  .MuiAutocomplete-listbox div[aria-disabled="true"] {
    pointer-events: inherit;
    opacity: 0.5;

    &:active {
      pointer-events: none;
    }
  }

  .MuiInputLabel-root {
    font-size: 12px;
    transform: translate(14px, 10px) scale(1); 
    transition: transform 0.2s ease-out;
  }

  .MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75); 
  }
`;

const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    font-size: 12px;
    padding: 15px 10px;
  }
  width: 400px;
`;

const Label = styled(InputLabel)`
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
`;

const DraggableContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 10px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
`;

export {
  Container,
  StyledFieldArray,
  WorkoutDaysContainer,
  AddNewOptionContainer,
  StyledTypography,
  ChipContainer,
  WorkoutDayIndex,
  StyledOption,
  StyledTextField,
  StyledTrashIcon,
  StyledAddIcon,
  StyledOptionSubContent,
  StyledAutocomplete,
  Label,
  DraggableContent,
  ButtonContainer,
  StyledAutocompleteWrapper,
};
