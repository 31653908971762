import styled from 'styled-components';

import { ReactComponent as DescriptionIcon } from '../../../assets/icons/v2/notes.svg';
import Button from '../../../components/Button';
import colors from '../../../styles/colors';

const chatIconPx = 30;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 8px;
`;

const ButtonTitle = styled.div`
  width: 51px;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 12px;
  margin-top: 10px;
`;

const StyledIconButton = styled(Button)`
  display: flex;
  flex-direction: column;
  padding: 0px 7.5px;
  background-color: transparent;
  color: ${colors.base.beta};
  text-transform: capitalize;
  height: auto;
  font-size: 11px;
  font-weight: 500;
  border-radius: 8px; 
  cursor: pointer;
  gap: 2px;

  &:hover {
    background-color: ${colors.shades.gamma1};
  }
`;

const StyledDescriptionIcon = styled(DescriptionIcon)`
  height: 26px;
  width: 26px;
  position: relative;
  top: 5.5px;

  > path {
    fill: ${colors.shades.secondary7};
  }
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  color: ${colors.shades.secondary3};
  border-radius: 50%;
  width: ${chatIconPx}px;
  height: ${chatIconPx}px;
  padding: 8px;
  margin-left: 2px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.shades.gamma1};
  }
`;

export {
  Container,
  StyledButton,
  StyledIconButton,
  StyledDescriptionIcon,
  ButtonTitle,
};
