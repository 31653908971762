import styled from 'styled-components';
import {
  CircularProgress,
  TextField,
} from '@mui/material';

import { ReactComponent as ChatIcon } from '../../../../../../assets/icons/chat-icon.svg';
import { ReactComponent as GenerateIcon } from '../../../../../../assets/icons/regenerate-icon.svg';
import { ReactComponent as S2Logo } from '../../../../../../assets/s2-logo.svg';
import Button from '../../../../../../components/Button';
import AttachmentsContainer from '../../../../../components/AttachmentsContainer';
import colors from '../../../../../../styles/colors';

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0px;
  column-gap: 8px;
  width: 100%;
`;

const HeaderTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
`;

const HeaderTime = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledChatIcon = styled(ChatIcon)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  > path {
    stroke: ${colors.base.beta};
    fill: ${colors.shades.primary3};
  }
`;

const StyledS2Icon = styled(S2Logo)`
  width: 12px;
  height: 12px;
  margin-right: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .MuiIconButton-root {
    padding: 2px 10px;
    svg > path {
      stroke: ${colors.base.primary};
      fill: ${colors.shades.warning1};
    }
  }
`;

const FeedbackDiffContainer = styled.p`
  margin: 0;
  padding: 16.5px 14px;
  border-radius: 5px;
  border: 1px solid ${colors.base.gamma}
`;

const FeedbackDiffText = styled.span`
  white-space: pre-wrap;
  color: ${({ highlight }) => (highlight ? colors.base.success : 'unset')};
`;

const StyledButton = styled(Button)`
  width: 200px;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  margin: 20px 0;
  border-radius: 5px;
  background: ${colors.base.primary};
`;

const StyledDiffButton = styled(Button)`
  float: right;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-transform: none;
  width: fit-content;
  padding: 6px 8px;
  border-radius: 4px;
  cursor: pointer;
  color: ${colors.base.primary};
  background: transparent;
`;

const StyledTextArea = styled(TextField)`
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 16px;
`;

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  padding: 0 10px;
  width: calc(100% - 20px);
  align-items: center;
  overflow: auto;
`;

const StyledGenerateIcon = styled(GenerateIcon)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const StyledInfoText = styled.div`
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  margin: 16px 14px 0 14px;
  color: ${colors.shades.gamma2};
`;

const SmartResponseTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  margin: 12px 4px;
  text-align: left;
  align-self: flex-start;
`;

const StyledGenerateButton = styled(Button)`
  width: max-content;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  height: 45px;
  margin: 8px 16px;
  border-radius: 36px;
  border: 1px solid ${colors.shades.secondary4};
  background: ${colors.shades.secondary5};
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
`;

const StyledIconButton = styled(Button)`
  background-color: transparent;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.shades.gamma5};
    color: ${colors.base.secondary2};
    opacity: 1;
  }
`;

const StyledSpinner = styled(CircularProgress)`
  color: ${colors.shades.secondary3};
  position: absolute;
  bottom: 10px;
  left: calc(50% - 20px);
`;

const StyledAttachmentsContainer = styled(AttachmentsContainer)`
  margin: 0 10px;
  width: 100%;
  min-height: 45px;
`;

const ScheduleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;
`;

const DateSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding: 8px;
  margin: 20px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 20px;
  width: 100%;
`;

export {
  ActionsContainer,
  FeedbackContainer,
  FeedbackDiffContainer,
  FeedbackDiffText,
  StyledButton,
  StyledTextArea,
  StyledChatIcon,
  StyledDiffButton,
  StyledInfoText,
  StyledGenerateButton,
  StyledGenerateIcon,
  HeaderContainer,
  HeaderTime,
  HeaderTitle,
  NameContainer,
  Container,
  StyledIconButton,
  StyledSpinner,
  SmartResponseTitle,
  StyledS2Icon,
  StyledAttachmentsContainer,
  ScheduleWrapper,
  DateSelectorContainer,
  ButtonContainer,
};
