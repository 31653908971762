import { Collection, getFirebase } from 'firestorter';

import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';

/**
 * Enum for meal plan types.
 */
const MealPlanType = {
  PREDEFINED: 'PREDEFINED',
  LIVE: 'LIVE',
};

/**
 * Class representing a meal plan template.
 * Meal plan templates are used as a starting point to assign a meal plan to user.
 * These can either be PREDEFINED templates, including a meal times array with predefined recipes,
 * or LIVE templates, which are used to generate a meal plan automatically for a user.
 *
 * @class MealPlan
 * @extends BaseDocument
 */
class MealPlan extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.MEAL_PLAN}/${id}`, opts);
  }

  /**
   * Get meal plan template name.
   * @return {string}
   */
  get name() {
    return this.data.name || '';
  }

  /**
   * @typedef {object} Meal
   * @property {object} recipe - recipe object, same object structure as a Recipe document.
   * @property {number} servings - number of servings.
   */

  /**
   * @typedef {object} MealTime - Meal time or meal plan bucket.
   * @property {string} name - The name of the meal time (e.g., "Breakfast").
   * @property {string} caloricSplit - Percentage of how many calories should be allocated for the meal time.
   * @property {Array<Meal>|void} meals - Array of meals included in the meal time.
   * Only present in PREDEFINED meal plans.
   * @property {number|void} recipesAmount - Number of recipes to generate for the given meal time.
   * Only present in LIVE meal plans.
   */

  /**
   * Get meal plan template meal times. Returns an array of meal time objects {@link MealTime}
   * @return {Array<MealTime>}
   */
  get mealTimes() {
    return this.data.mealTimes || [];
  }

  /**
   * Get meal plan template macro averages.
   * @return {object} meal plan macro averages.
   * @property {object} macros - meal plan macros totals in grams.
   * @property {number} macros.fat - fat grams.
   * @property {number} macros.carbs - carbs grams.
   * @property {number} macros.protein - protein grams.
   * @property {object} percentages - meal plan macros distribution in percentages.
   * @property {number} percentages.fat - fat percentage.
   * @property {number} percentages.carbs - carbs percentage.
   * @property {number} percentages.protein - protein percentage.
   */
  get macroAverages() {
    return this.data.macroAverages;
  }

  /**
   * Get meal plan template allergens.
   * @return {Array<string>}
   */
  get allergenTags() {
    return this.data.allergenTags || [];
  }

  /**
   * Get meal plan template archive status
   * @return {boolean}
   */
  get isArchived() {
    return !!this.data.isArchived;
  }

  /**
   * Get meal plan template archived date.
   * @return {Date|void}
   */
  get archivedAt() {
    return this.data.archivedAt;
  }

  /**
   * Get meal plan template creation date.
   * @return {Date}
   */
  get createdAt() {
    return this.data.createdAt;
  }

  /**
   * Get meal plan template type. The value will be one of {@link MealPlanType}
   * @return {string}
   */
  get type() {
    return this.data.type;
  }

  /**
   * Get recipe ids included in the meal plan template.
   * @return {Array<string>}
   */
  get recipes() {
    return this.data.recipes || [];
  }

  /**
   * Get number of previous meal plans to exclude when generating a live meal plan.
   * @return {number}
   */
  get numberOfMealPlansToExclude() {
    return this.data.numberOfMealPlansToExclude || 0;
  }

  /**
   * Get meal plan template last updated date.
   * @return {Date}
   */
  get lastUpdated() {
    return this.data.lastUpdated;
  }

  // Methods

  /**
   * Update meal plan template doc with given data.
   *
   * @param {object} data - Update data.
   * @return {Promise<void>}
   */
  async updateMealPlan(data) {
    await this.updateFields(data);
  }

  /**
   * Archives a meal plan template.
   * @return {Promise<void>}
   */
  async archive() {
    return this.updateFields({
      isArchived: true,
      archivedAt: new Date(),
    });
  }

  /**
   * Unarchives a meal plan template.
   * @return {Promise<void>}
   */
  async unarchive() {
    return this.updateFields({
      isArchived: false,
      archivedAt: getFirebase().firestore.FieldValue.delete(),
    });
  }

  /**
   * Creates a new meal plan template document with given data.
   *
   * @param {object} data new meal plan template data.
   * @return {Promise<MealPlan>} new meal plan template document.
   */
  static async addDoc(data) {
    const mealPlansCollection = new Collection(Collections.MEAL_PLAN);
    const now = new Date();
    const doc = await mealPlansCollection.add({
      ...data,
      isArchived: false,
      createdAt: now,
      lastUpdated: now,
    });
    const mealPlan = new MealPlan(doc.id);
    await mealPlan.init();
    return mealPlan;
  }

  /**
   * Get meal plan template document with the given id.
   *
   * @param {string} mealPlanId meal plan template id.
   * @returns {Promise<MealPlan|void>} meal plan template document if it exists, or else null.
   */
  static async getMealPlan(mealPlanId) {
    const mealPlanDoc = new MealPlan(mealPlanId);
    await mealPlanDoc.init();
    return mealPlanDoc.exists ? mealPlanDoc : null;
  }
}

export default MealPlan;
export { MealPlanType };
