import styled from 'styled-components';

import colors from '../../styles/colors';
import OnCallActions from '../OnCallActions';

const Container = styled.div`
  margin: 0 5px 15px 0px;
`;

const ContractInfoLine = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContractInfoKey = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.shades.secondary3};
`;

const ContractInfoValue = styled.span`
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
  color: ${colors.base.beta};
`;

const StyledOnCallActions = styled(OnCallActions)`
  padding-top: 15px;
`;

export {
  Container,
  ContractInfoLine,
  ContractInfoKey,
  ContractInfoValue,
  StyledOnCallActions,
};
