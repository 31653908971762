import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment from 'moment/moment';
import ReactHtmlParser from 'react-html-parser';
import format from 'string-template';
import {
  MenuItem,
} from '@mui/material';
import cloneDeep from 'lodash.clonedeep';

import useToast from '../../../../../hooks/useToast';
import { getMacroAverages, getMealTimesStats } from '../../../../../utils/meals';
import {
  addRecipeToMealTimes,
  bulkAddRecipesToMealTimes,
  removeRecipeFromMealTimes,
  moveRecipeInMealPlan,
} from '../../../../../utils/mealPlan';
import { DateFormat } from '../../../../../../utils/date';
import {
  AddButton,
  CancelButton,
  SaveButton,
} from '../../../../../../components/Button/ActionButtons';
import RadioButtonGroup from '../../../../../components/RadioButtonGroup';
import EditItem from '../../../components/EditItem';
import MealPlan, { MealPlanType } from '../../../../../Model/MealPlan';
import UserContext from '../../../../../../context/UserContext';
import { StyledInlineSelect, StyledTextField } from '../../../../../../components/Inputs';
import { CoachingActivity } from '../../../../../../utils/log';
import useLogger from '../../../../../../hooks/useLogger';
import MealPlanContext from '../../../../../context/MealPlanContext';
import { StyledTextBolder } from '../../../../../../styles/text';
import DraggableSortedList from '../EditMealTimesModal/components/DraggableSortedList';
import MealPlanView from '../../../../../components/MealPlanView';
import {
  ErrorTag,
  InfoTag,
  SuccessTag,
  TransparentTag,
} from '../../../../../../components/Tags';
import useMealPlanGeneration from '../../../../../hooks/useMealPlanGeneration';
import AddRecipeModal from '../../../components/AddRecipeModal';
import { HeaderContainer, MealAssignmentsContainer, Title } from '../../styles';
import {
  AlertContainer,
  AlertIcon,
  AlertTypography,
  AnalysisContainer,
} from '../EditMealTimesModal/styles';

import { initialMealPlanValue, initialLiveMealPlanValue } from './utils';
import {
  TitledContainer,
  TitledContainerBody,
  TitledContainerFoot,
  TitledContainerFootEditor,
  TitledContainerHead,
  TitledContainerHeadDescription,
  TitledContainerHeadTitle,
  MealPlanTemplateTypeSelector,
  FootActionButton,
  AnalysisWrapper,
  ErrorsContainer,
  MealPlanTemplateDisclaimer,
  MealPlanTemplateExcluder,
  InlineSelectRow,
  ItemContainer,
  Label,
  ItemList,
} from './styles';
import texts from './texts.json';

const ManageMealTemplate = ({
  onClose,
  selectedMealPlan,
}) => {
  const { showToast } = useToast();
  const { userId } = useContext(UserContext);
  const {
    mealPlanViewsCollection,
    saveDefaultMealPlan,
    defaultMealPlan,
  } = useContext(MealPlanContext);

  const [mealPlanIsLive, setMealPlanIsLive] = useState(false);
  const [mealPlanName, setMealPlanName] = useState(initialMealPlanValue.name);
  const [mealTimes, setMealTimes] = useState(cloneDeep(initialMealPlanValue.mealTimes));
  const [numberOfMealPlansToExclude, setNumberOfMealPlansToExclude] = useState(3);
  const [selectedBucketId, setSelectedBucketId] = useState();
  const [isAddRecipeModalOpen, setIsAddRecipeModalOpen] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);

  const { generateRecipes } = useMealPlanGeneration();
  const { logCoachingActivity } = useLogger();

  /* Setting defaults for edit pages */
  useEffect(() => {
    if (selectedMealPlan) {
      setMealPlanName(selectedMealPlan.name);
      setMealTimes([...selectedMealPlan.mealTimes]);
      setLastUpdated(selectedMealPlan.lastUpdated);
      const isLive = selectedMealPlan.type === MealPlanType.LIVE;
      setMealPlanIsLive(isLive);
      if (isLive) {
        setNumberOfMealPlansToExclude(selectedMealPlan.numberOfMealPlansToExclude);
      }
    }
  },
  [
    selectedMealPlan,
  ]);

  const macroAverages = useMemo(() => getMacroAverages(mealTimes), [mealTimes]);

  /*
    Get total % of calories allocated.
    Also check for errors, if any meal time has 0% calories allocated, or a meal time is missing a name.
    Meal times cannot have duplicated names.
    Used in the meal times editor.
  */
  const {
    caloriesAllocated,
    noAllocationError,
    noNameError,
    duplicatedNamesError,
    numberOfRecipesError,
  } = useMemo(() => getMealTimesStats(mealTimes), [
    mealTimes,
  ]);

  // Check if a meal time is empty. Return false when the template is a LIVE template
  const hasEmptyMealTime = useMemo(() => (
    !mealPlanIsLive && mealTimes.some((mealTime) => (
      mealTime.meals && mealTime.meals.length === 0
    ))
  ), [
    mealPlanIsLive,
    mealTimes,
  ]);

  const caloriesAllocatedError = caloriesAllocated !== 100;

  const hasErrors = caloriesAllocatedError
    || noAllocationError
    || noNameError
    || duplicatedNamesError
    || !mealPlanName
    || (mealPlanIsLive && numberOfRecipesError);

  const recipesList = useMemo(() => {
    const recipes = [];
    if (!mealPlanIsLive && mealTimes) {
      mealTimes.forEach(({ meals }) => meals.forEach(({ recipe }) => {
        recipes.push(recipe.id);
      }));
    }
    return [...new Set(recipes)];
  }, [
    mealPlanIsLive,
    mealTimes,
  ]);

  /* Handlers */
  const handleSave = useCallback(async () => {
    if (hasErrors) {
      return;
    }

    // Calculate allergen tags for the entire meal plan
    const allAllergenTags = [];
    if (!mealPlanIsLive) {
      mealTimes.forEach(({ meals }) => meals.forEach(({ recipe }) => {
        recipe.allergenTags?.forEach((tag) => allAllergenTags.push(tag));
      }));
    }
    const now = new Date();
    const mealPlanData = {
      name: mealPlanName,
      mealTimes,
      lastUpdated: now,
      ...(
        mealPlanIsLive
          ? {
            type: MealPlanType.LIVE,
            numberOfMealPlansToExclude,
          }
          : {
            type: MealPlanType.PREDEFINED,
            allergenTags: [...new Set(allAllergenTags)],
            recipes: recipesList,
            macroAverages,
          }
      ),
    };

    if (selectedMealPlan?.createdAt) {
      await selectedMealPlan.updateMealPlan(mealPlanData);
      showToast(texts.mealPlanTemplateUpdated);
      logCoachingActivity(CoachingActivity.UPDATED_MEAL_PLAN, { mealPlanId: selectedMealPlan.id });
    } else {
      const mealPlanDoc = await MealPlan.addDoc({
        ...mealPlanData,
        createdAt: now,
        coach: userId,
      });
      logCoachingActivity(CoachingActivity.CREATED_MEAL_PLAN, { mealPlanId: mealPlanDoc.id });

      // The first meal plan created is automatically the default one.
      if (!mealPlanViewsCollection.hasDocs && !defaultMealPlan) {
        await saveDefaultMealPlan(mealPlanDoc.id);
      }

      showToast(texts.mealPlanTemplateCreated);
    }

    onClose();
  }, [
    defaultMealPlan,
    saveDefaultMealPlan,
    mealPlanName,
    userId,
    mealTimes,
    showToast,
    hasErrors,
    mealPlanViewsCollection,
    macroAverages,
    onClose,
    numberOfMealPlansToExclude,
    mealPlanIsLive,
    selectedMealPlan,
    recipesList,
    logCoachingActivity,
  ]);

  const handleOpenAddRecipeModal = (bucketId = null) => {
    setSelectedBucketId(bucketId);
    setIsAddRecipeModalOpen(true);
  };

  const handleChangeTemplateType = useCallback((isLive) => {
    if (isLive === mealPlanIsLive) {
      return;
    }
    setMealPlanIsLive(isLive);
    if (isLive && mealPlanName === initialMealPlanValue.name) {
      setMealPlanName(initialLiveMealPlanValue.name);
    }
    if (!isLive && mealPlanName === initialLiveMealPlanValue.name) {
      setMealPlanName(initialMealPlanValue.name);
    }
    setMealTimes(isLive ? cloneDeep(initialLiveMealPlanValue.mealTimes) : cloneDeep(initialMealPlanValue.mealTimes));
  }, [
    mealPlanIsLive,
    mealPlanName,
  ]);

  const handleGenerateMeals = useCallback((mealTimeName) => {
    const recipes = generateRecipes({ mealTimeName, excludeRecipes: recipesList });
    setMealTimes((prev) => bulkAddRecipesToMealTimes(prev, mealTimeName, recipes));
  }, [
    generateRecipes,
    recipesList,
  ]);

  const handleAddRecipe = (bucketId, recipe) => {
    setMealTimes((prev) => addRecipeToMealTimes(prev, bucketId, recipe));
  };

  const handleDeleteRecipe = (recipeId, bucketIndex) => {
    setMealTimes((prev) => removeRecipeFromMealTimes(prev, recipeId, bucketIndex));
  };

  const handleMoveRecipe = ({
    removedIndex,
    addedIndex,
    sourceBucketIndex,
    destinationBucketIndex,
    meal,
  }) => {
    setMealTimes((prevMealTimes) => moveRecipeInMealPlan(
      prevMealTimes,
      removedIndex,
      addedIndex,
      sourceBucketIndex,
      destinationBucketIndex,
      meal,
    ));
  };

  /* Small render chunks */
  const renderError = (errorText) => (
    <AlertContainer>
      <AlertIcon />
      <AlertTypography>{errorText}</AlertTypography>
    </AlertContainer>
  );

  const AnalysisTag = caloriesAllocatedError ? ErrorTag : SuccessTag;
  const analysis = (
    <AnalysisContainer>
      <AnalysisTag>
        {`${caloriesAllocated}${texts.caloriesAllocated}`}
      </AnalysisTag>
      <TransparentTag>{texts.in}</TransparentTag>
      <InfoTag>{`${mealTimes.length} ${texts.mealTimes}`}</InfoTag>
    </AnalysisContainer>
  );

  const actionButtons = (
    <>
      <SaveButton onClick={handleSave} disabled={hasErrors || hasEmptyMealTime}>
        {texts.saveTemplate}
      </SaveButton>
      <CancelButton onClick={onClose}>
        {texts.cancel}
      </CancelButton>
    </>
  );

  const excludeMealsOptions = [1, 2, 3, 4, 5, 6, 7].map((key) => (
    <MenuItem key={key} value={key}>{key}</MenuItem>
  ));

  const mealPlanTypeOptions = [
    {
      label: texts.createPredefinedPlan,
      value: MealPlanType.PREDEFINED,
    },
    {
      label: texts.createLivePlan,
      value: MealPlanType.LIVE,
    },
  ];

  const newMacroAverages = useMemo(() => (
    getMacroAverages(mealTimes || [])
  ), [mealTimes]);

  return (
    <MealAssignmentsContainer>
      <HeaderContainer>
        <Title>{selectedMealPlan ? texts.updateMealPlan : texts.createMealPlan}</Title>
      </HeaderContainer>
      <TitledContainer>
        <TitledContainerHead>
          <TitledContainerHeadTitle>{texts.containerTitle}</TitledContainerHeadTitle>
          {lastUpdated && (
            <TitledContainerHeadDescription>
              {`${texts.lastUpdated} ${moment(lastUpdated.toDate()).format(DateFormat.DATE_FORMAT_COMMA_TH)}`}
            </TitledContainerHeadDescription>
          )}
        </TitledContainerHead>
        <TitledContainerBody>
          <EditItem label={texts.mealPlanName}>
            <StyledTextField
              value={mealPlanName}
              onChange={({ target }) => setMealPlanName(target.value)}
              autoFocus
            />
          </EditItem>
          <DraggableSortedList
            mealTimes={mealTimes}
            setMealTimes={setMealTimes}
            templateType={mealPlanIsLive ? MealPlanType.LIVE : MealPlanType.PREDEFINED}
          />
          <AnalysisWrapper>
            {analysis}
          </AnalysisWrapper>
          {hasErrors && (
            <ErrorsContainer>
              {caloriesAllocatedError && renderError(texts.totalCaloriesAlert)}
              {noAllocationError && renderError(texts.noAllocationAlert)}
              {noNameError && renderError(texts.noNameAlert)}
              {(duplicatedNamesError && !noNameError) && renderError(texts.duplicatedNames)}
              {mealPlanIsLive && numberOfRecipesError && renderError(texts.numberOfRecipesError)}
            </ErrorsContainer>
          )}
        </TitledContainerBody>
        <TitledContainerFoot>
          {!selectedMealPlan && (
            <>
              <MealPlanTemplateTypeSelector>
                <RadioButtonGroup
                  options={mealPlanTypeOptions}
                  selectedOption={mealPlanIsLive ? MealPlanType.LIVE : MealPlanType.PREDEFINED}
                  onOptionChange={(value) => handleChangeTemplateType(value === MealPlanType.LIVE)}
                />
              </MealPlanTemplateTypeSelector>
              <MealPlanTemplateDisclaimer>
                <StyledTextBolder>
                  {mealPlanIsLive ? texts.disclaimerLiveTitle : texts.disclaimerPreTitle}
                </StyledTextBolder>
                {mealPlanIsLive ? texts.disclaimerLiveDescription : texts.disclaimerPreDescription}
              </MealPlanTemplateDisclaimer>
            </>
          )}
          {mealPlanIsLive && (
            <MealPlanTemplateExcluder>
              <EditItem label={texts.recipeVariety} noMarginsOnLabel>
                <InlineSelectRow>
                  {texts.excludeMealsP1}
                  <StyledInlineSelect
                    value={numberOfMealPlansToExclude}
                    onChange={({ target }) => setNumberOfMealPlansToExclude(target.value)}
                  >
                    {excludeMealsOptions}
                  </StyledInlineSelect>
                  {texts.excludeMealsP2}
                </InlineSelectRow>
              </EditItem>
            </MealPlanTemplateExcluder>
          )}
        </TitledContainerFoot>
        <TitledContainerFootEditor>
          {!mealPlanIsLive && (
            <>
              <FootActionButton>
                <AddButton onClick={() => handleOpenAddRecipeModal()}>{texts.addRecipes}</AddButton>
                {!!newMacroAverages?.percentages?.protein && (
                  <ItemContainer>
                    <Label>{texts.macros}</Label>
                    <ItemList>
                      <InfoTag>
                        {ReactHtmlParser(format(texts.protein, { percentage: newMacroAverages.percentages.protein }))}
                      </InfoTag>
                      <InfoTag>
                        {ReactHtmlParser(format(texts.carbs, { percentage: newMacroAverages.percentages.carbs }))}
                      </InfoTag>
                      <InfoTag>
                        {ReactHtmlParser(format(texts.fat, { percentage: newMacroAverages.percentages.fat }))}
                      </InfoTag>
                    </ItemList>
                  </ItemContainer>
                )}
              </FootActionButton>
              <MealPlanView
                alwaysShowAddButton
                mealTimes={mealTimes}
                onAddRecipe={handleOpenAddRecipeModal}
                allowEdit
                moveMeal={handleMoveRecipe}
                handleDelete={handleDeleteRecipe}
                onGenerateMeals={handleGenerateMeals}
              />
            </>
          )}
        </TitledContainerFootEditor>
        <TitledContainerFoot>
          <FootActionButton>
            {actionButtons}
          </FootActionButton>
        </TitledContainerFoot>
      </TitledContainer>
      <AddRecipeModal
        isOpen={isAddRecipeModalOpen}
        onAddRecipe={handleAddRecipe}
        mealTimes={mealTimes}
        onClose={() => setIsAddRecipeModalOpen(false)}
        selectedBucketId={selectedBucketId}
      />
    </MealAssignmentsContainer>
  );
};

ManageMealTemplate.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedMealPlan: PropTypes.object,
};

ManageMealTemplate.defaultProps = {
  selectedMealPlan: null,
};

export default ManageMealTemplate;
