import { AccordionDetails, AccordionSummary } from '@mui/material';
import styled from 'styled-components';

import colors from '../../../../../../styles/colors';

const AccordionTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  margin-right: 36px;
`;

const AccordionMacro = styled.div`
  font-size: 11px;
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 20px;
  padding: 20px 30px;
`;

const NutritionLogMealContainer = styled.li`
  margin-bottom: 20px;

  &::marker {
    color: ${colors.shades.gamma3};
  }
`;

const NutritionLogMealTitle = styled.div`
  font-size: 13px;
  line-height: 24px;
`;

const NutritionLogMealMacro = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${({ highlight }) => (highlight ? colors.shades.primary1 : colors.shades.gamma3)}
`;

const NutritionLogMealMacroContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: ${colors.shades.gamma5};
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  background-color: ${colors.shades.alpha1}
`;

const FoodItemsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 5px;
  font-size: 12px;
  color: ${colors.shades.gamma10};
`;

export {
  AccordionTitle,
  AccordionMacro,
  Container,
  NutritionLogMealContainer,
  NutritionLogMealTitle,
  NutritionLogMealMacro,
  NutritionLogMealMacroContainer,
  StyledAccordionSummary,
  StyledAccordionDetails,
  FoodItemsList,
};
