import styled from 'styled-components';
import {
  Autocomplete,
  Chip,
  FormHelperText,
  Checkbox,
} from '@mui/material';
import colors from '../../../../styles/colors';

import InputLabel from '../../../../components/v2/InputLabel';

const StyledAutocomplete = styled(Autocomplete)`
  margin: 10px 40px;
  background-color: ${colors.base.alpha};
`;

const StyledAutocompleteWrapper = styled.div`
  .MuiAutocomplete-listbox div[aria-disabled="true"] {
    pointer-events: inherit;
    opacity: 0.5;

    &:active {
      pointer-events: none;
    }
  }
`;

const TimeChip = styled(Chip)`
  background-color: ${colors.shades.secondary9};
  height: 20px;
`;

const EquipmentChip = styled(Chip)`
  background-color: ${colors.shades.primary3};
  height: 20px;
`;

const StyledOption = styled.div`
  border: 1px solid ${colors.shades.secondary7};
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const StyledOptionSubContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
const ColumnContainer = styled.div`
  flex-direction: column;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const StyledCheckBoxLabel = styled(FormHelperText)`
  margin-top: 0;
  font-size: 13px;
  line-height: 14px;
  font-weight: 450;
  color: unset;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0;
  margin: -5px 0 15px -2px;

  .MuiCheckbox-root {
    padding-right: 5px;
  }
`;

const StyledCheckBox = styled(Checkbox)`
  padding: 0;
`;

const SettingSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const DaysContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const RepeatDay = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin-bottom: 10px;
  color: ${colors.base.beta};
  background-color: ${({ isSelected }) => (isSelected ? colors.shades.primary5 : colors.base.alpha)};
  border: 1px solid ${({ isSelected }) => (isSelected ? colors.shades.primary6 : colors.shades.gamma4)};
  align-items: center;
  padding: 14px;
  margin-right: 12px;
  font-weight: 400;
  flex: 1;
  font-size: 16px;
  line-height: 16px;
  justify-content: center;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? colors.shades.primary3 : colors.shades.gamma1)};
  }
`;

const StyledInputLabel = styled(InputLabel)`
  font-size: 14px;
  color: ${colors.shades.secondary8};
`;

const RepeatContainer = styled.div`
  margin: 20px 40px;
  background-color: ${colors.base.alpha};
`;

export {
  StyledAutocomplete,
  StyledOption,
  TimeChip,
  EquipmentChip,
  StyledOptionSubContent,
  RowContainer,
  ColumnContainer,
  StyledCheckBoxLabel,
  CheckBoxContainer,
  StyledCheckBox,
  SettingSection,
  DaysContainer,
  RepeatDay,
  StyledInputLabel,
  RepeatContainer,
  StyledAutocompleteWrapper,
};
