import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import PostPaymentFormAnswer from '../../../../../Model/PostPaymentFormAnswer';
import Lead from '../../../../../Model/Lead';
import { getFormAnswersFromData } from '../../../../../utils/formAnswers';

import {
  QuestionContainer,
  Question,
  Answer,
} from './styles';

const FormResponseContainer = ({
  formDoc,
}) => {
  const responsesData = useMemo(() => getFormAnswersFromData(formDoc), [formDoc]);
  return (
    <>
      {!!responsesData && responsesData.map(({ question, answer }) => !!answer
          && (
            <QuestionContainer key={`${question}-${answer}`}>
              <Question>{question}</Question>
              <Answer>{answer}</Answer>
            </QuestionContainer>
          ))}
    </>
  );
};

FormResponseContainer.propTypes = {
  formDoc: PropTypes.oneOfType([
    PropTypes.instanceOf(PostPaymentFormAnswer),
    PropTypes.instanceOf(Lead),
  ]).isRequired,
};

export default FormResponseContainer;
