import { FormHelperText, TextField } from '@mui/material';
import styled from 'styled-components';

import colors from '../../../../../styles/colors';

const Container = styled.div`
  overflow: scroll;
  height: auto;
`;

const DateFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: ${colors.shades.gamma5};
  padding: 20px 30px;
`;

const FilterInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FilterTitle = styled.div`
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
`;

const FilterDates = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FilterDescription = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.shades.gamma3};
`;

const StyledFormHelperText = styled(FormHelperText)`
  margin: 0 12px;
  font-size: 13px;
  line-height: 15px;
  color: unset;
`;

const StyledTextField = styled(TextField)`
  background: ${colors.base.alpha};
  border-radius: 4px;
`;

export {
  Container,
  DateFilterWrapper,
  FilterDates,
  FilterWrapper,
  FilterInformation,
  FilterTitle,
  FilterDescription,
  StyledFormHelperText,
  StyledTextField,
};
