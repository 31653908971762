import { HistoryEventType } from '../../../../../Model/CoachHistory';

const texts = {
  eventText: {
    [HistoryEventType.WHITE_LISTED]: 'An onboarding link was sent to the coach by {userName}.',
    [HistoryEventType.ACCOUNT_CREATED]: 'The coach created an account in the system.',
    [HistoryEventType.PROFILE_SET]: 'Coach profile was configured by {userName}.',
    [HistoryEventType.STRIPE_CONNECTED]: "Coach's stripe account was created and connected.",
    [HistoryEventType.LEAD_FORM_CREATED]: 'Lead form for the coach was created by {userName}.',
    [HistoryEventType.POST_PAYMENT_FORM_LINKED]: 'The post-payment form was linked to the coach by {userName}.',
    [HistoryEventType.FIRST_PRODUCT_CREATED]: 'The first paid product was created by {userName}.',
    [HistoryEventType.LOGO_SUBMITTED]: 'App logo was submitted for approval by {userName}.',
    [HistoryEventType.LOGO_APPROVED]: 'The app logo was marked as “Approved by Coach” by {userName}.',
    [HistoryEventType.WEBSITE_SUBMITTED]: 'Website was submitted for approval by {userName}.',
    [HistoryEventType.WEBSITE_APPROVED]: 'The website was marked as “Approved by Coach” by {userName}.',
    [HistoryEventType.WELCOME_PACK_SUBMITTED]: 'Welcome pack was submitted for approval by {userName}.',
    [HistoryEventType.APP_LOGO_SET]: 'Mobile app logo was set up by {userName}.',
    [HistoryEventType.APP_THEME_SET]: 'Mobile app theme was set up by {userName}.',
    [HistoryEventType.WELCOME_PACK_SET]: 'Welcome pack was set up by {userName}.',
    [HistoryEventType.SLACK_CHANNEL_SET]: 'Slack notification channel was configured by {userName}.',
    [HistoryEventType.COACH_LAUNCHED]: 'Coach was officially marked as launched by {userName}.',
    [HistoryEventType.FIRST_LEAD]: 'First lead for coach was recorded.',
    [HistoryEventType.FIRST_SUBSCRIPTION]: 'First subscription for coach was recorded.',
    [HistoryEventType.STRIPE_DEAUTHORIZED]: 'Stripe account was deauthorized by {userName}.',
    [HistoryEventType.STRIPE_DEAUTHORIZATION_CLEANUP]:
      'Stripe account deauthorization cleanup was performed by {userName}.',
    [HistoryEventType.OFFBOARDED]: 'Coach was offboarded by {userName}.',
    [HistoryEventType.POST_PAYMENT_FORM_DELETED]: 'Post-Payment form deleted by {userName}.',
    [HistoryEventType.COACH_WEBSITE_DELETED]: 'Coach website deleted by {userName}.',
    [HistoryEventType.APP_ICON_DELETED]: 'App icon deleted by {userName}.',
    [HistoryEventType.ZOHO_EMAIL_REMOVED]: 'Zoho email removed by {userName}.',
    [HistoryEventType.LEAD_FORM_ARCHIVED]: 'The lead form was archived by {userName}.',
  },
  emptyText: 'No history events found',
};

export default texts;
