import fieldName from './formFields';

const texts = {
  title: 'Workout Designer',
  addWorkout: 'Add New Workout',
  editWorkout: 'Edit Workout',
  editWorkoutAssignment: 'Edit Workout Assignment',
  assignmentUpdateModalTitle: 'Update Workout Assignments',
  assignmentUpdateOptionLabel: {
    SELECTED_ASSIGNMENT: 'Update only selected "{workoutName}" assignment',
    NOT_COMPLETED_ASSIGNMENTS: 'Update {count} not completed "{workoutName}" assignments',
  },
  workoutCreated: 'The workout “{workoutName}” has been successfully created',
  workoutUpdated: `The workout “{workoutName}” has been successfully updated, 
                    and associated assignments have also been updated`,
  updateFailed: 'Failed to update the workout',
  workoutAssignmentUpdated: 'The workout Assignment “{workoutName}” has been updated successfully',
  workoutAssignmentsUpdated: '{count} “{workoutName}” Workout Assignments have been updated successfully',
  actionConfirmMessage: `This action will update the un-started assignments 
                          related to this workout for all clients. Would you like to proceed?`,
  draftSaveConfirmMsgTitle: '{reason}, would you like to save this workout as a draft?',
  draftSaveConfirmMsgContent: 'Draft workouts are not eligible for assignment',
  draftSavingWarningMsg: 'Cannot update the {type}: {reason}',
  workout: 'workout',
  workoutAssignment: 'workout assignment',
  validation: {
    requiredField: 'Required',
  },
  saveNewButton: 'Save as New Workout',
  submitButton: {
    saveWorkout: 'Save Workout',
    saveWorkoutAssignment: 'Save Workout Assignment',
    updateWorkoutAssignments: 'Update Workout Assignments',
  },

  cancelButton: 'Cancel',
  field: {
    [fieldName.NAME]: {
      label: 'Workout Name',
    },
    [fieldName.NOTE]: {
      label: 'Workout Description',
    },
  },
};

export default texts;
