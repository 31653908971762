import styled from 'styled-components';

const TagsSection = styled.div`
  display:flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 5px;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`;

export {
  TagsSection,
  ChipContainer,
};
